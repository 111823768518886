@import 'src/scss/variables.scss';

.DisplayInstance__addProductModal {
  .Modal__content {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: visible;

    & > p {
      margin: 0;
      &:first-of-type {
        font-size: 1.3rem;
        font-weight: bold;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__buttons {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 1rem;
    }
  }

  &__variables {
    padding: 2rem 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & > p {
      margin: 0;
      font-size: 1.2rem;
    }
  }

  &__variable {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;

    & > p {
      margin: 0;
    }

    &__value {
      flex: 1;
    }

    &__radio {
      display: flex;
      overflow-x: auto;
      gap: 0.5rem;

      &__option {
        flex-shrink: 0;

        & > img {
          width: 8rem;
          border-radius: 3px;
          border: 2px solid rgba(0, 0, 0, 0);
          box-shadow: 0 1px 2px #00000040;
        }

        & > input {
          appearance: none;
          display: none;

          &:checked ~ img {
            border: 2px solid $primary-500;
          }
        }
      }
    }
  }
}
