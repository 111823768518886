@import '~/src/scss/variables';
@import '~/src/scss/mixins';

.CreditModal {
  .Modal__content {
    padding: 0;
    width: 512px;
  }

  &__header {
    background-color: $gray-100;
    padding: 1.5rem;
    text-align: center;
  }

  &__body {
    padding: 2rem;
  }

  &__field {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    &__label {
      color: $gray-500;
    }

    textarea {
      resize: none;
    }
  }

  &__footer {
    border-top: 1px solid $border;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    padding: 1rem 1.5rem;
  }
}
