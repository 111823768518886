@import '~/src/scss/variables';

.OpenOrdersTable {
  &__daysPast {
    &--green {
      color: $success-700;
    }
    &--orange {
      color: $warning-600;
    }
    &--red {
      color: $danger-500;
    }
  }
}
