.CreditMemosList {
  &__filters {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    gap: 1rem;

    &__spacer {
      flex: 1;
    }
  }
}
