.EditTemplateModal {
  .Modal__content {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: visible;

    & > p {
      margin: 0;

      &:first-of-type {
        font-size: 1.3rem;
        font-weight: bold;
      }
    }

    .EditTemplateModal__input {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .EditTemplateModal__buttons {
      width: 100%;
      display: flex;
      justify-content: end;
      gap: 1rem;
      align-items: center;

      &:last-of-type {
        margin-top: 1rem;
      }
    }
  }
}
