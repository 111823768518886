@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.Popover {
  animation: scaleIn 0.4s;
  background-color: white;
  border: 1px solid $border;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  padding: 0.5rem 1rem;
  position: absolute;
  z-index: 101;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
