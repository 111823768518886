@import 'src/scss/variables.scss';

.ProductDetails {
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    color: $gray-700;
    line-height: 1.5;
  }

  &__keyValueSpan {
    display: flex;
    justify-content: space-between;
    & > p:nth-child(2) {
      text-align: right;
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &--bold {
      & > p:first-child {
        font-weight: 500;
      }
    }
    &--bold,
    &--dark {
      & > p:nth-child(2) {
        color: $gray-900;
      }
    }
  }

  &__warningButton {
    background-color: $danger-100 !important;
    color: $danger-800 !important;
  }

  &__warningBanner {
    width: 100%;
    padding: 16px;
    background-color: $warning-50;

    & > span {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      & > svg {
        color: $warning-500;
        margin-right: 1rem;
        width: 16px;
      }

      & > p {
        color: $warning-800;
        font-weight: 500;
      }
    }

    & > p {
      margin-left: 32px;
      color: $warning-700;
    }
  }

  &__cancelledBanner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $warning-50;

    & > button {
      padding: 5px 9px !important;
      margin-right: 25px;
    }
  }

  &__info {
    &__header {
      display: flex;
      justify-content: space-between;
      width: 300px;

      & > p {
        font-weight: 500;
        margin-bottom: 4px;
        font-size: 16px;
      }
    }

    &__content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;

      & > div {
        width: 300px;
      }
    }

    &__productInfo {
      display: flex;
      flex-direction: column;
      &__content {
        display: flex;
        flex-direction: column;
        background-color: $gray-100;
        border-radius: 6px;
        padding: 8px;
      }
    }

    &__notes {
      display: flex;
      flex-direction: column;
      align-self: stretch;

      &__header {
        display: flex;
        justify-content: space-between;

        & > p {
          margin: 4px 8px;
          line-height: 1.5;
          font-weight: 500;
        }
      }

      &__content {
        padding: 12px 16px;
        background-color: $gray-100;
        border-radius: 6px;
        flex-grow: 1;
      }

      &__editing {
        & > span {
          display: flex;
          align-items: center;

          & > * {
            padding: 5px 0px !important;
          }

          & > :last-child {
            margin-left: 16px;
            background-color: $primary-50 !important;
            color: $primary-700 !important;
          }
        }
      }
    }

    margin-bottom: 64px;
  }

  &__art {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;

    &Work {
      // REMOVE
      width: 530px;
      //
      display: flex;
      flex-direction: column;

      &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > p {
          margin: 0;
          line-height: 1.5;
          font-weight: 500;
          font-size: 16px;
        }

        margin-bottom: 16px;
      }

      &__artCard {
        display: flex;
        flex-direction: column;
        padding: 16px;
        background-color: $gray-100;
        border-radius: 4px;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        p {
          color: $gray-800;
          font-size: 14px;
        }

        &__title {
          border-bottom: 1px solid $gray-200;
          & > p:nth-child(2) {
            color: $gray-600;
            font-size: 12px;
          }

          margin-bottom: 10px;
        }

        &__content {
          display: flex;
          & > div {
            display: flex;
            flex-direction: column;
            &:not(:last-child) {
              margin-right: 16px;
            }
            & > :last-child {
              margin-top: 10px;
              background-color: white;
            }
          }

          &__imgContainer {
            width: 240px;
            height: 240px;
            background-color: $gray-200;
            display: flex;
            justify-content: center;
            align-items: center;

            & > img {
              width: auto;
              height: auto;
              max-width: 240px;
              max-height: 240px;
            }
          }
        }
      }

      margin-right: 64px;
    }

    &Info {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__notes {
        &__header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > p {
            font-weight: 500;
            margin-bottom: 6px;
            font-size: 16px;
          }
        }

        &__card {
          padding: 34px 20px;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(2, 1fr);

          &__column {
            align-self: stretch;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          &__valueSpan {
            min-width: 142px;

            &:not(:last-child) {
              margin-bottom: 8px;
            }
            & > p:first-child {
              margin-bottom: 4px;
              color: $gray-500;
            }
            & > p:nth-child(2) {
              color: $gray-900;
            }
          }

          &:not(:last-child) {
            margin-bottom: 16px;
          }

          &:last-child {
            margin-bottom: 40px;
          }
        }

        &__moreInfo {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 40px;

          & > div {
            flex-grow: 1;
            max-width: 50%;

            &:not(:last-child) {
              margin-right: 16px;
            }

            & > p {
              font-weight: 500;
              padding: 4px 8px;
            }
            
            & > div {
              white-space: pre-wrap;
              padding: 12px 16px;
              background-color: $gray-100;
              border-radius: 6px;
            }
          }
        }
      }
    }
  }
}
