@import '~/src/scss/mixins.scss';
@import '~/src/scss/variables.scss';

.PWMyJobs {
  label {
    color: $gray-500;
    font-size: 14px;
  }

  .Tabs {
    margin-bottom: 2rem;
    justify-content: center;
  }

  .TabItem {
    justify-content: center;
    flex: 1;
  }

  .job {
    padding: 1rem;

    &__header {
      align-items: center;
      border-bottom: 1px solid $border;
      display: flex;
      justify-content: space-between;
      margin: 0.5rem 0;
      padding: 0.5rem 0;

      &__title {
        @include semi-bold;
        color: $gray-700;
        font-size: 14px;
      }
    }
    &__body {
      &__row {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;

        &--completed {
          color: $gray-500;
        }
      }
    }
  }
}
