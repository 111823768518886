@import '~/src/scss/variables';

.AccountList {
  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 1.5rem;

    &__status {
      display: flex;
      flex-direction: column;

      select {
        height: 39px;
      }
    }
  }
}
