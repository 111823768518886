.DisplayModel__addSectionModal {
  .Modal__content {
    width: 500px;

    & > p {
      margin: 0;
      font-size: 1.3rem;
      font-weight: bold;
    }

    & > span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:last-of-type {
        margin-top: 1rem;
      }
    }
  }
}
