@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.CreateJob {
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  form {
    padding-bottom: 6rem;
  }

  &__inline {
    align-items: center;
    display: flex;
    margin: 3rem 0;

    &__spacer {
      flex: 1;
    }

    &__group {
      display: flex;
      flex-direction: column;

      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }

  .centered {
    text-align: center;
  }

  &__tasksEmpty {
    border: 2px solid $gray-200;
    border-radius: 4px;
    color: $gray-500;
    margin-bottom: 2rem;
    padding: 1rem 2rem;
  }

  &__task {
    border-bottom: 2px solid $gray-100;
    border-collapse: collapse;
    margin-bottom: 1rem;
    width: 100%;

    &--sm {
      width: 50px;
    }

    &--md {
      width: 100px;
    }

    &--lg {
      width: 200px;
    }

    thead {
      tr {
        th {
          @include normal;
          color: $gray-500;
          font-size: 12px;
          padding: 0 1rem;
          text-align: left;
          text-transform: uppercase;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 0 1rem 1rem 1rem;
        }
      }
    }

    &__badges {
      .Badge {
        display: inline-block;

        &:not(:last-of-type) {
          margin-right: 1rem;
        }
      }
    }
  }

  &__buttonGroup {
    background-color: white;
    bottom: 0;
    padding: 1rem 1.5rem;
    position: fixed;
    width: 100%;

    button {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}
