@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.PieceworkerFooter {
  background-color: $primary-5;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  padding: 0.75rem 1rem;
  width: 100%;

  &__link {
    align-items: center;
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    &__text {
      @include semi-bold;
      font-size: 14px;
    }

    svg,
    img {
      width: 24px;
    }
  }
}
