@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.Spinner {
  &:before {
    animation: ss-spin 2s linear infinite;
    border: 6px solid $gray-200;
    border-bottom-color: $primary;
    border-radius: 50%;
    border-top-color: $primary;
    content: '';
    display: inline-block;
    height: 36px;
    width: 36px;
  }

  &--sm {
    &:before {
      height: 16px;
      width: 16px;
    }
  }

  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__message {
    color: $gray-600;
    margin-top: 0.5rem;
  }
}

@keyframes ss-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
