@import '~/src/scss/breakpoints';
@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.style-guide {
  .example {
    display: flex;

    &-content {
      align-items: center;
      border: 1px solid $border;
      // border-radius: 4px;
      display: flex;
      flex: 1;
      justify-content: center;
      padding: 1rem;
      min-height: 256px;
      min-width: 256px;
    }
    &-options {
      background-color: $gray-50;
      // border-radius: 4px;
      border: 1px solid $border;
      margin: 0 0 0 1rem;
      padding: 1rem;
      width: 256px;

      select {
        margin-bottom: 0.5rem;
      }
    }
  }

  @include breakpoint(768px down) {
    .example {
      flex-direction: column-reverse;

      &-options {
        margin: 0 0 1rem 0;
      }
    }
  }
}
