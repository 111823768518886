@import '~/src/scss/mixins.scss';
@import '~/src/scss/variables.scss';

.PWJobDetail {
  label {
    color: $gray-500;
    font-size: 14px;
  }

  &__total {
    align-items: center;
    background-color: $gray-100;
    display: flex;
    padding: 1rem;
    margin-bottom: 1rem;

    &__icon {
      margin-right: 1rem;
    }

    h4 {
      margin: 0;
      color: $gray-900;
    }
  }

  &__card {
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
  }

  &__dates {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__task {
    &__row {
      display: flex;
      justify-content: space-between;
    }

    &__field {
      display: flex;

      label {
        margin-right: 4px;
      }
    }

    &__badges {
      display: flex;
      margin-top: 4px;
      gap: 8px;
    }
  }

  &__actions {
    display: flex;
    padding: 8px 0px;
  }

  &__modal {
    &__actions {
      align-items: center;
      display: flex;
      justify-content: space-between;

      &__button:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}
