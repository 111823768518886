@import 'src/scss/variables.scss';

.ProductsList {
  &__filters {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    & > :first-child {
      .Input {
        width: 300px;
      }
    }

    margin-bottom: 24px;
  }

  &__table {
    & tr {
      height: 100%;
    }

    &__image {
      & > div {
        background-color: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        img {
          max-width: none;
        }
      }
    }
  }
}
