@import 'src/scss/variables.scss';

.ReportsSplash {
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 24px;
  }

  &__section {
    display: flex;
    padding: 32px 0;
    gap: 32px;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 2px solid $gray-200;
    }

    &__contentContainer {
      display: flex;
      gap: 32px;
      width: 100%;
      
      & > * {
        width: 264px;
      }
    }

    &__col {
      display: flex;
      flex-direction: column;

      h3 {
        font-size: 18px;
        color: $gray-900;
        font-weight: 500;
        margin: 0 0 8px 0;
      }

      & > a:not(:last-child) {
        margin: 0 0 4px 0;
      }

      &--icon {
        width: 216px;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & > h2 {
          font-size: 18px;
          margin: 12px 0 0 0;
        }

        & > span {
          padding: 12px;
          border-radius: 8px;
          background-color: $gray-100;
          width: 48px;
          height: 48px;
          position: relative;

          & > svg {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}
