.Availability {
  &__inlineButtons {
    display: flex;
    margin-bottom: 2.5rem;
  }

  &__viewAll {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }

  &__inlineTableCell {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
