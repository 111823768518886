@import '~/src/scss/variables';

.AccountDetail {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__container {
    flex-grow: 1;
    padding: 24px !important;
    background-color: $gray-100;
  }
}

.accountOverview {
  background-color: white;
  padding: 24px;
  height: 100%;

  .text-primary {
    color: $primary-500;
  }

  .titleAndEditRow {
    .editButton {
      cursor: pointer;
      color: $primary-500;
    }
  }
  &__nav {
    align-content: center;
    border-bottom: 1px solid $gray-200;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding: 0.5rem 0;

    .editButton {
      display: flex;
      height: 40px;
    }
  }

  &__accountTopRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 64px;

    .accountInfo {
      padding: 8px;
      margin: 8px;
      width: 100%;
      max-width: 203px;

      &__accountStatus {
        padding: 0 8px;
      }

      &__accessCode {
        background-color: $gray-100;
        padding: 8px;
        border-radius: 5px;

        &__codeContainer {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          * {
            flex-grow: 1;
          }

          p {
            flex-grow: 1;
            margin: 0;
            width: 100%;
            text-align: right;
          }
        }
      }

      &__editButton {
        margin-top: 12px;
      }
    }
    .accountAddress {
      width: 100%;
      max-width: 280px;
      padding: 16px;
    }
    .accountContacts {
      width: 100%;
      max-width: 296px;

      &__list {
        background-color: $gray-100;
        height: 172px;
        overflow-y: scroll;
        border-radius: 5px;
        &::-webkit-scrollbar-thumb {
          display: none;
        }
        &::-webkit-scrollbar {
          width: 0px;
        }

        .contact {
          padding: 8px 16px;

          .contactName {
            display: flex;
            flex-direction: column;
            .name {
              color: $gray-800;
            }
            .position {
              color: $gray-600;
            }
          }
        }
      }
    }
    .accountBilling {
      min-width: 280px;
      padding: 16px;
    }
  }

  &__accountBottomRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 64px;

    .accountNotes {
      width: 407px;
      .tabNav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        margin-bottom: 4px;
      }
      .tabContent {
        display: flex;
        animation: fadeEffect 1s;
        justify-content: center;
        align-items: center;
        background-color: $gray-100;
        padding: 24px;
        width: 100%;

        &__notes {
          flex-grow: 1;
        }
      }
    }
    .creditAndTerms {
      width: 100%;
      max-width: 298px;
      max-height: 168px;
      padding: 0 8px 8px 16px;

      .howApprovedText {
        text-align: right;
        max-width: 150px;
      }
    }
    .orderApproval {
      width: 100%;
      max-width: 296px;
      max-height: 168px;
      padding: 0 8px 8px 16px;
    }
    .shippingPreference {
      width: 100%;
      max-width: 296px;
      max-height: 168px;
      padding: 0 8px 8px 16px;
    }

    @keyframes fadeEffect {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  &-table {
    border: 1px solid $gray-200;
    border-radius: 8px;
    .headerRow {
      font-size: 12px;
      color: $gray-500;
      height: 50px;
    }
    .tableRow {
      font-size: 14px;
      height: 50px;
    }
    td {
      border-bottom: 1px solid $gray-200;
    }

    tr:last-child {
      td {
        border: none;
      }
    }
  }
}
