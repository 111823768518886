.DisplayModel__confirmationModal {
  &__title {
    margin: 0;
    font-size: 1.3rem;
    font-weight: bold;
  }

  .Modal__content {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & > span {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      align-items: center;
    }
  }
}
