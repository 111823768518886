@import 'src/scss/variables.scss';

.ProductDetails__nav {
  display: flex;
  align-items: center;

  & > h2 {
    margin: 0;
    margin-right: 16px;
    line-height: 1.5;
    font-size: 22px;
    color: $gray-700;
  }

  & > p {
    color: $gray-300;
    font-size: 36px;
    font-weight: 100;
  }

  &__link {
    text-decoration: none !important;
    color: $gray-500 !important;
    margin-left: 32px;

    svg {
      margin-left: 4px;
      width: 16px;
    }

    &--active {
      color: $primary-900 !important;
      font-weight: 500;
    }
  }
}
