@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.ProfileDrawer {
  &__user {
    align-items: center;
    display: flex;

    &__image {
      border-radius: 8px;
      height: 104px;
      margin-right: 1rem;
      width: 104px;
    }

    &__info {
      flex: 1;

      &__name {
        @include bold;
        font-size: 24px;
        margin: 0;
        margin-bottom: 1rem;
      }
    }
  }

  &__tabs {
    display: flex;
  }

  &__general {
    &__label {
      color: $gray-500;
      font-size: 14px;
    }

    &__contact {
      &__row {
        align-items: center;
        display: flex;
        justify-content: space-between;

        label {
          color: $gray-500;
          flex: 1;
          font-size: 14px;
          line-height: 1.5;
        }

        p {
          color: $gray-900;
          flex: 2;
          font-size: 14px;
          line-height: 1.5;
        }
      }
    }

    &__updateImage {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 3rem 0 2rem 0;

      img {
        border-radius: 100%;
        margin-right: 1rem;
        height: 48px;
        width: 48px;
      }
    }

    &__trained {
      &__header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
      }

      &__checkboxes {
        display: flex;

        .Checkbox__container {
          &:not(:last-of-type) {
            margin-right: 3.5rem;
          }
        }
      }
    }
  }

  &__notes {
    &__note {
      &__date {
        color: $gray-500;
        font-size: 12px;
      }
      &__text {
        color: $gray-600;
        margin: 0;
        padding: 0;
      }

      &:not(:last-of-type) {
        border-bottom: solid 1px $border;
      }
    }
  }
}
