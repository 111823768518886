@import '~/src/scss/variables';

.ReorderModal {
  &__body {
    justify-content: space-evenly;
    margin: 24px 0;
  }
  &__column {
    width: 216px;
    gap: 8px;
    p {
      text-align: center;
      margin: 0;
      font-size: 12px;
    }
  }
  &__modalGreyHeader {
    gap: 15px;
    align-items: center;
    border-bottom: 1px solid $gray-200;
    p {
      margin-left: 20px;
    }
  }
  &__inputs {
    align-items: center;
    input {
      width: 38px;
      height: 29px;
      border-radius: 4px;
    }
    label {
      margin: 0 8px;
      font-size: 14px;
    }
  }
  &__inputHolder {
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 8px;
  }
  .modalButtonEnd {
    border-top: 1px solid #e5e7eb;
    button {
      margin: 24px;
    }
  }
}
