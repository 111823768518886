@import 'src/scss/variables.scss';

.RepDetailsComponent__nav {
  &__popover {
    padding: 1.5rem !important;
    width: 600px;

    &__column {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}
