@import '~/src/scss/variables.scss';
@import '~/src/scss/mixins.scss';

.DisplayTable {
  width: 100%;

  &__colgroup {
    &__medium {
      width: 20%;
    }
    &__grow {
      width: 50%;
    }
    &__input {
      min-width: 130px;
      width: fit-content;
    }
  }

  .OrderDetails__orderDetailsTable {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    thead tr th {
      border-top: none;
      z-index: 99;
      min-width: max-content;
    }

    tbody tr {
      background-color: white;

      td {
        border-bottom: none;
      }
    }

    &__inputTd {
      padding: 3.5px 8px !important;
    }

    &__iconButton {
      height: 32px !important;
      min-height: 32px !important;
      max-height: 32px !important;
      width: 32px !important;
      min-width: 32px !important;
      max-width: 32px !important;
      & svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
