* {
  box-sizing: border-box;
}

body {
  background-color: white;
  margin: 0;
  min-width: 320px;
  padding: 0;
}

/* Add button cursor styles */
button,
input[type='submit'],
input[type='button'] {
  cursor: pointer;
}
button:disabled,
input[type='submit']:disabled,
input[type='button']:disabled {
  cursor: not-allowed;
}

/* images, videos, and iframes can't be wider than their containers */
img,
video,
iframe {
  max-width: 100%;
}

/* tables inherit font size (some browsers set a default table font size) */
table {
  font-size: inherit;
}

/* set cursor to pointer for specific elements */
a {
  cursor: pointer;
}
