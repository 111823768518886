@import '~/src/scss/variables.scss';

.ShippingPreferences {
  &__header {
    background-color: $gray-50;
    color: $gray-900;
    font-size: 18px;
    padding: 1.25rem 0;
    text-align: center;
  }

  &__body {
    padding: 1rem 2.5rem;
  }

  &__field {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    label {
      color: $gray-700;
    }

    input,
    select {
      width: 152px !important;
    }
  }

  &__actions {
    display: flex;
    justify-content: end;
    border-top: 1px solid $gray-200;
    padding: 1.25rem 1.5rem;
  }
}
