.AdminLayout {
  display: flex;
  flex-direction: row;
  height: 100vh;

  &__sidebar {
    flex: 0 0 208px;

    &--collapsed {
      flex: 0 0 48px;
    }
  }

  &__routes {
    flex: 1 1 auto;
    overflow: auto;
  }
}
