@import '~/src/scss/mixins.scss';
@import '~/src/scss/variables.scss';

.RepPopover {
  display: flex;

  &__name {
    @include medium;
    margin-bottom: 2rem;
  }

  &__actions {
    margin-top: 1.5rem;

    a {
      display: block;

      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }

    button {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
}

.PaymentsPopover {
  &__header {
    font-size: 16px;
    color: $gray-900;
    line-height: 1.5;
  }

  table thead,
  table tbody tr {
    border: none;
  }

  &__editButton {
    padding: 8px 16px !important;
    font-weight: 400 !important;
  }

  &__addButton {
    margin-top: 16px;
    background-color: $primary-50 !important;
    padding: 8px 16px !important;
  }
}
