@import '~/src/scss/variables.scss';

.EditBillingModal {
  .Modal__content {
    max-width: 512px;
    padding: 0;

    .Modal__close {
      position: absolute;
    }
  }

  &__header {
    text-align: center;
    padding: 18px;
    background-color: $gray-50;
    border-bottom: 1px solid $gray-100;
  }

  &__title {
    color: $gray-900;
    font-size: 18px;
    margin: 0;
  }

  &__form {
    &__body {
      padding: 1rem 1.5rem;
    }

    &__field {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      .Input {
        width: 272px;
      }

      &__doubleInput {
        display: flex;
        gap: 8px;

        #state {
          height: 39px;
          width: 108px;
        }

        #postal {
          width: 156px;
        }
      }
    }

    &__buttons {
      border-top: 1px solid $gray-100;
      display: flex;
      justify-content: flex-end;
      padding: 1.25rem 1.5rem;
      gap: 1rem;
    }
  }
}
