@import '~/src/scss/variables.scss';

.ListPageHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &__date {
    font-size: 16px;
  }
}
