@import '~/src/scss/mixins';
@import '~/src/scss/variables';

@page {
  size: landscape;
}

.PrintSummary {
  margin: 64px 0;
  color: $gray-700;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 24px 0;

    &__overview {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h2 {
        @include semi-bold;
        color: $gray-800;
        font-size: 24px;
        margin: 0 0 40px 0;
        line-height: 1.2;
      }

      &__info {
        @include normal;

        h3 {
          $color: $gray-800;
          margin: 0 0 8px 0;
          font-size: 24px;
          line-height: 1.2;
        }
        p {
          color: $gray-600;
          margin: 0 0 4px 0;
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }

    &__qrInfo {
      @include normal;
      padding: 16px 24px;
      border: 1px solid $gray-300;
      max-width: 300px;
      font-size: 12px;
      line-height: 1.5;

      p {
        margin: 0;
      }
      p:not(:last-child) {
        margin: 0 0 16px 0px;
      }
    }
  }

  &__table {
    flex-grow: 1;
    border-collapse: collapse;
    table-layout: auto;

    thead,
    tr {
      border: none;
    }
    thead {
      border-bottom: 1px solid $gray-300;

      th {
        @include semi-bold;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 1.5;
        padding: 10px 16px;
        text-align: left;
        white-space: nowrap;
      }
      th:first-child {
        min-width: 50px;
      }
      th:not(:first-child, :last-child) {
        min-width: 100px;
      }
    }
    tbody {
      tr:nth-child(2n-1) {
        background-color: $gray-50;
      }
      td {
        @include normal;
        font-size: 12px;
        line-height: 1.5;
        padding: 5px 16px;
        white-space: nowrap;

        label {
          margin: 0;
          span {
            outline: 1px solid $gray-300;
            border-radius: 4px;
            background-color: $white;
          }
        }
      }
      td:last-child {
        width: 100%;
      }
    }
  }

  &__jobTable {
    width: 100%;
    display: flex;
    margin-bottom: 24px;

    &__tasks {
      display: flex;

      & > div:not(:last-child) {
        margin-right: 32px;
      }
    }
  }

  &__materialsTable {
    display: flex;
    justify-content: flex-end;

    &__table {
      flex-grow: 0;
      width: 30%;
      table-layout: auto;

      thead {
        th {
          padding: 2px 16px;
        }
      }

      tbody {
        tr:nth-child(2n) {
          background-color: $gray-50;
        }
        tr:nth-child(2n-1) {
          background-color: $white;
        }
        td {
          white-space: nowrap;
        }
      }
    }
  }

  &__actions {
    bottom: 0;
    display: flex;
    padding: 2rem 0;
    position: fixed;

    button {
      margin-right: 2rem;
    }
  }
}

@media print {
  .AdminLayout__sidebar {
    display: none;
  }
  .Toaster {
    display: none;
  }
  .nav {
    display: none;
  }
  .PrintSummary__actions {
    display: none;
  }
}
