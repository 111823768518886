@import 'src/scss/variables.scss';

.Reps__groupListDrawer {
  &__content {
    &__rep {
      padding: 8px;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 6px;
      }

      &__image {
        width: 40px;
        height: 40px;
        border-radius: 100px;
        background-color: $gray-200;
        margin-right: 16px;
      }

      & a {
        color: $primary-600;
        font-weight: 500;
        margin: 0;
        line-height: 1.5;

        &:hover {
          text-decoration: none;
        }
      }

      & p {
        margin: 0;
        line-height: 1.5;
        color: $gray-500;
      }
    }
  }
}
