@import '~/src/scss/mixins';
@import '~/src/scss/variables';

$colors: (
  'primary': (
    'main': $primary-600,
    'text': white,
    'hover': $primary-50,
    'active': $primary-100,
  ),
  'accent': (
    'main': $success-600,
    'text': white,
    'hover': $success-50,
    'active': $success-100,
  ),
  'warn': (
    'main': $danger-600,
    'text': white,
    'hover': $danger-50,
    'active': $danger-100,
  ),
  'light': (
    'main': $gray-100,
    'text': $gray-600,
    'hover': $gray-50,
    'active': $gray-100,
  ),
  'dark': (
    'main': $gray-700,
    'text': white,
    'hover': $gray-50,
    'active': $gray-100,
  ),
);

@mixin button-base {
  @include medium;
  @include sans;
  appearance: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  color: $gray-900;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 1.5;
  padding: 0.5rem 2rem;
  position: relative;
  text-align: center;
  text-decoration: none !important;
  transition: all 0.1s ease-in-out;

  &:disabled {
    cursor: not-allowed;
  }

  svg {
    display: block;
    width: 24px;
  }

  &.Button--fluid {
    width: 100%;
  }

  &.Button--size--sm {
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
  }

  &.Button--size--lg {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
  }

  &.Button--iconLeading {
    padding-left: 2.5rem !important;

    .iconLeading {
      left: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translate(50%, -50%);

      svg {
        width: 18px;
      }
    }
  }

  &.Button--iconTrailing {
    padding-right: 2.5rem !important;

    .iconTrailing {
      margin: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);

      svg {
        width: 18px;
      }
    }
  }

  &.Button--rounded {
    border-radius: 2rem !important;
  }
}

.Button--variant--default {
  @include button-base;

  &:hover {
    background-color: $gray-50;
  }

  &:active {
    background-color: $gray-100;
  }

  &:disabled {
    color: $gray-500 !important;

    &:hover {
      background-color: transparent !important;
    }

    &:active {
      background-color: transparent !important;
    }
  }

  // colors

  @each $name, $values in $colors {
    &.Button--color--#{$name} {
      color: map-get($values, 'main');

      &:hover {
        background-color: map-get($values, 'hover');
      }

      &:active {
        background-color: map-get($values, 'active');
      }
    }
  }
}

.Button--variant--outlined {
  @include button-base;
  background-color: transparent;
  border: 1px solid $border;
  color: $gray-900;

  &:hover {
    background-color: $gray-50;
  }

  &:active {
    background-color: $gray-100;
  }

  &:disabled {
    color: $gray-500 !important;

    &:hover {
      background-color: transparent !important;
    }

    &:active {
      background-color: transparent !important;
    }
  }

  @each $name, $values in $colors {
    &.Button--color--#{$name} {
      border-color: map-get($values, 'main');
      color: map-get($values, 'main');

      &:hover {
        background-color: map-get($values, 'hover');
      }

      &:active {
        background-color: map-get($values, 'active');
      }
    }
  }
}

.Button--variant--raised {
  @include button-base;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);

  &:active {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    background-color: $gray-200 !important;
    border-color: $gray-400;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    color: $gray-400 !important;
  }

  @each $name, $values in $colors {
    &.Button--color--#{$name} {
      background-color: map-get($values, 'main');
      color: map-get($values, 'text');
    }
  }
}
