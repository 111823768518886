@import '~/src/scss/variables.scss';

.PWPayments {
  &__dateRange {
    align-items: center;
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  &__list {
    margin-top: 1rem;

    &__item {
      align-items: center;
      border-bottom: 1px solid $border;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 1rem;

      &__total {
        text-align: right;
      }
    }
  }

  &__cards {
    align-items: center;
    display: flex;
    gap: 1rem;
  }

  &__card {
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex: 1;
    gap: 1rem;
    padding: 1.25rem 1rem;
  }
}
