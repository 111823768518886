@import 'src/scss/variables.scss';

.DisplayInstance__applyNamelistModal {
  .Modal__content {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & > p {
      margin: 0;
      font-size: 1.3rem;
      font-weight: bold;
    }

    .DisplayInstance__applyNamelistModal {
      &__form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
      &__buttons {
        margin-top: 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 1rem;
      }
    }
  }
}
