@import '~/src/scss/mixins';

.reset-password {
  &-hint {
    margin-bottom: 1.5rem;
    li {
      @include semi-bold;
      font-size: 12px;
    }
  }
}
