@import 'src/scss/variables.scss';

.RepOrdersSummary {
  height: 100%;
  display: flex;
  flex-direction: column;

  p {
    color: $gray-700;
    margin: 0;
    line-height: 1.5;
  }

  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: $gray-100;
  }

  &__body {
    flex-grow: 1;
    background-color: #fff;
    padding: 16px;
  }

  &__backLink {
    &__link {
      display: flex;
      align-items: center;
      width: fit-content;

      &:hover {
        text-decoration: none;
      }
      svg {
        color: $gray-700;
        margin-right: 8px;
        width: 24px;
      }
      & > p {
        color: $gray-700;
        font-weight: 500;
        font-size: 18px;
      }
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    padding: 16px 0;

    &__content {
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding: 0 16px;
      gap: 80px;

      &__title {
        display: flex;
        align-items: center;

        & p:first-child {
          color: $gray-500;
        }

        & p:nth-child(2) {
          color: $gray-900;
          font-size: 24px;
          font-weight: 500;
          line-height: 1.2;
        }

        &__symbol {
          width: 48px;
          height: 48px;
          background-color: $primary-500;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;

          svg {
            color: #fff;
            width: 24px;
          }
        }
      }

      &__column {
        display: flex;
        flex-direction: column;

        &:nth-child(n + 1) {
          width: 200px;
        }

        & > span {
          display: flex;
          justify-content: space-between;

          p:nth-child(2) {
            color: $gray-900;
          }
        }
      }
    }

    &__inputs {
      display: flex;
      gap: 1rem;

      .Input {
        height: 39px !important;
      }

      &__inputGroup {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        &__dateRange {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
  }

  &__table {
    & td {
        padding: 1rem !important;
    }
  }
}
