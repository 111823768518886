@import 'src/scss/variables.scss';

.ProductDetails__searchPopoverNoquery {
  display: flex;
  flex-direction: column;

  .Input__container {
    display: flex;
  }

  &__popoverInput {
    margin: 8px;
    flex-grow: 1;
  }

  &__popoverOptions {
    border-top: 1px solid $gray-200;
    padding: 8px 0;
    flex-grow: 1;
    overflow: auto;

    &__option {
      padding: 8px 16px;
      cursor: pointer;

      &:hover {
        background-color: $gray-100;
      }
    }
  }
}
