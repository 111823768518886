@import '~/src/scss/variables';

.SideNav {
  background-color: $primary-5;
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    flex-grow: 1;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 208px;

    & > svg {
      margin: 0 auto 1rem;
      width: 100%;
      height: 24px;
      color: $gray-500;
      cursor: pointer;
      user-select: none;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    background-color: $primary-4;
    margin: 0 -0.5rem;
    padding: 1rem;
    gap: 0.75rem;

    &__avatar {
      height: 32px;
      width: 32px;
      border-radius: 50%;
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;

      & > p {
        margin: 0;
        font-weight: 500;
      }

      & > a {
        color: $gray-400;
        font-size: 12px;
        text-decoration: none;
        margin: 0;
        line-height: 1;
      }
    }
  }

  &__userCollapsed {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 36px;

    & svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    &__avatar {
      height: 40;
      width: 40;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary-3;
      border-radius: 6px;
    }
  }

  &__logoContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 3rem 0;

    & img {
      max-height: 46px;
    }
  }

  &__logo {
    max-width: 128px;
    user-select: none;
  }

  hr {
    border: 1px solid $gray-900;
    opacity: 0.5;
  }

  &__links {
    margin-top: 1rem;
    flex-grow: 1;
  }

  &__link {
    align-items: center;
    border-radius: 6px;
    color: white !important;
    display: flex;
    padding: 0.5rem;
    gap: 0.75rem;
    text-decoration: none !important;

    &:not(:last-of-type) {
      margin-bottom: 4px;
    }

    &--active {
      background-color: #2f4674;
    }

    svg,
    img {
      width: 24px;
    }
  }

  &__contentCollapsed {
    width: unset;

    .SideNav__link {
      flex-direction: column;

      & > svg {
        margin-right: 0;
      }

      & > div {
        display: none;
      }
    }
  }
}
