@import '~/src/scss/variables';

.VerifyTask {
  &__confirmed {
    color: $success-700;
  }
  &__quantityInput {
    height: 28px;
  }
}
