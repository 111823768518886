@import 'src/scss/variables.scss';

.Products__dropdownMenu {
  &__title {
    align-items: center;
    border-bottom: 1px solid $gray-200;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;

    & > p {
      color: $gray-700;
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      line-height: 1.5;
    }
  }
}
