@import 'src/scss/variables.scss';

.AddMemoDrawer {
  p {
    margin: 0;
    color: $gray-700;
    line-height: 1.5;
  }

  input:disabled {
    background-color: $gray-100;
    border: 1px solid $gray-300;
  }

  &__body {
    &__row {
      display: flex;

      & > :not(:last-child) {
        margin-right: 24px;
      }
    }

    &__labelledInput {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      & > p {
        color: $gray-600;
        font-weight: 500;
        margin-bottom: 4px;
      }

      & > :nth-child(2) {
        width: 100%;
        & > div {
          width: 100%;
        }
      }
    }

    &__divider {
      width: 100%;
      height: 1px;
      background-color: $gray-200;
      margin: 8px 0;
    }

    &__inputSpan {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > p {
        color: $gray-600;
        font-weight: 500;
      }

      & > :nth-child(2) {
        width: 274px;
        & > div,
        & > input,
        & > select,
        & > textarea {
          width: 100%;
        }
      }

      &--textarea {
        & > p {
          align-self: flex-start;
        }
        textarea {
          resize: none;
        }
      }
    }

    &__searchContainer {
      padding: 0 0 16px 0;
      & > :not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &__creditContainer {
      padding: 24px 0;
      & > :not(:last-child) {
        margin-bottom: 16px;
      }
    }

    &__approvalContainer {
      padding: 24px 0 12px 0;
      & > :not(:last-child) {
        margin-bottom: 16px;
      }
    }

    &__labelledToggle {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > :not(:last-child) {
        margin-right: 18px;
      }
    }

    &__amountsContainer {
      padding: 8px 0;
    }

    &__tableContainer {
      padding: 18px 0 6px 0;
      & > :not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &__cancelWarning {
    padding: 16px;
    background-color: $danger-50;
    border-top: 1px solid $gray-200;
    display: flex;
    align-items: flex-start;

    svg {
      color: $danger-500;
      margin-right: 12px;
      width: 24px;
    }

    &__content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    &__header {
      color: $danger-800 !important;
      font-weight: 500;
      margin-bottom: 4px !important;
    }

    &__text {
      color: $danger-700 !important;
      margin-bottom: 12px !important;
    }

    &__options {
      display: flex;
      align-items: center;

      & > a {
        color: $danger-800;
        margin-right: 16px;
        text-decoration: underline;
        text-decoration-color: $danger-800;
      }
    }
  }
}
