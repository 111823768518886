@import '~/src/scss/mixins';
@import '~/src/scss/variables';

@mixin input-base {
  @include sans;
  background: #fff;
  border: 1px solid $gray-300;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  font-size: 14px;
  line-height: 1.5;
  outline: none;
  padding: 8px 12px;
  transition: all 0.1s ease-in-out;

  @include placeholder {
    color: $gray-500;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.Input--fluid {
    width: 100%;
  }

  &.Input--iconLeading {
    padding-left: 2.5rem !important;
  }

  &.Input--iconTrailing {
    padding-right: 2.5rem !important;
  }

  &--textarea {
    max-width: 100%;
    min-height: 8rem;
    padding: 1rem;
  }

  &.Input--size--sm {
    font-size: 0.875rem;
    padding: 0.25rem 0.75rem;
  }

  &.Input--size--lg {
    font-size: 1.125rem;
    padding: 0.75rem 1.25rem;
  }
}

.Input__container {
  display: inline-block;
  position: relative;

  &.Input__container--fluid {
    width: 100%;
  }

  .iconLeading {
    left: 0;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);

    svg {
      display: block;
      width: 18px;
    }
  }

  .iconTrailing {
    margin: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);

    svg {
      display: block;
      width: 18px;
    }
  }

  .iconButtonTrailing {
    align-items: center;
    background-color: $gray-100;
    border: 1px solid $gray-300;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    transition: background-color 0.2s ease-in-out;
    user-select: none;
    width: 2rem;

    svg {
      display: block;
      width: 16px;
    }

    &:hover {
      background-color: $gray-200;
    }
  }
}

.Input {
  @include input-base;

  &:hover {
    border: 1px solid $gray-600;
  }

  &:focus,
  &:active {
    border: 1px solid $primary;
  }

  &:invalid {
    border: 1px solid $error;
  }
}

.Input--filled {
  @include input-base;
  background: $gray-100;

  &:hover {
    background: $gray-300;
    border: 1px solid $gray-600;
  }

  &:focus,
  &:active {
    background: $gray-300;
    border: 1px solid $primary;
  }

  &:invalid {
    border: 1px solid $error;
  }
}
