@import 'src/scss/mixins.scss';
@import 'src/scss/variables.scss';

.InvoiceNotes {
  display: flex;
  justify-content: center;

  .Modal__content {
    height: min-content;
    width: 500px;
    padding: 0;

    .IconButton {
      color: $gray-500 !important;
    }
  }

  &__header {
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-50;
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;

    &__warning {
      width: 100%;
      display: flex;
      background-color: $warning-50;
      padding: 16px;
      margin-bottom: 16px;

      p {
        margin: 0;
      }
      &__logo {
        margin-right: 12px;
        svg {
          color: $warning-400;
          width: 24px;
        }
      }
      &__content {
        &__bold {
          color: $warning-800;
          font-weight: 500;
          line-height: 1.5;
        }
        &__standard {
          color: $warning-700;
          line-height: 1.5;
        }
      }
    }
  }

  &__buttons {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: right;
    border-top: 1px solid $gray-200;

    button {
      padding: 8px 24px !important;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}
