@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.Container {
  margin: 0 auto;
  // max-width: 1440px;
  padding: 0 2rem;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .Container {
    padding: 0 1rem;
  }
}
