@import 'src/scss/variables';
@import 'src/scss/mixins';

.DisplayPanel {
  padding: 2rem;

  &__content {
    &__header {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      &__panelInfo {
        display: flex;
        flex-direction: column;

        & > p {
          margin: 0;
        }
      }

      &__links {
        display: flex;
        gap: 0.75rem;

        & > a {
          text-decoration: none;
          color: $primary-500;

          &:last-child {
            color: $danger-500;
          }
        }
      }
    }
  }

  &__sectionTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem 0;

    &__label {
      margin: 0;
      font-size: 1.3rem;
      font-weight: bold;
    }

    &__buttons {
      display: flex;
      gap: 0.75rem;
    }
  }

  &__sections {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 1rem;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;

      & > p {
        font-size: 1.3rem;
        font-weight: bold;
        margin: 0;
      }

      & > span {
        display: flex;
        gap: 0.75rem;

        & > a:not(:first-child) {
          color: $danger-500;
        }
      }
    }
  }

  &__section {
    background-color: $gray-100;
    padding: 1rem;
    border-radius: 6px;
    box-shadow: 0 1px 2px #00000040;

    &__info {
      margin: 0;

      &:last-of-type {
        margin-bottom: 1rem;
      }
    }
  }

  &__modules {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__title {
      font-size: 1.3rem;
      font-weight: bold;
      margin: 0;
    }

    &__button {
      align-self: flex-start;
    }
  }
}
