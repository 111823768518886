@import 'src/scss/variables.scss';
@import 'src/scss/mixins.scss';

.EverynameList {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__reportsLink {
    @include medium;
    align-items: center;
    color: $gray-500;
    display: flex;
    gap: 4px;
    cursor: pointer;

    svg {
      width: 16px;
    }
  }

  &__reportsPopover {
    display: flex;
    padding: 24px !important;
    gap: 1.5rem;
    width: 300px;

    &__column {
      flex: 1;

      h4 {
        margin: 0;
        font-size: 16px;
        line-height: 1.5;
      }

      p {
        color: $gray-500;
        margin: 0;
      }

      a {
        display: block;
        margin-top: 0.5rem;
      }
    }
  }

  &__filters {
    align-items: flex-end;
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  &__accessCode {
    width: 320px;

    &__content {
      display: flex;
      flex-direction: column;

      & > span {
        display: flex;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        :last-child {
          text-align: right;
        }
      }
      &__address {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        p {
          color: $gray-900;
        }
      }
    }
  }
}
