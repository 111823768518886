@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.Badge {
  @include semi-bold;
  align-items: center;
  background-color: $gray-500;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 10px;
  margin: 0;
  padding: 2px 10px;
  white-space: nowrap;
  width: fit-content;

  &--light {
    background-color: $gray-100;
    color: $gray-700;
  }

  &--primary {
    background-color: $primary-100;
    color: $primary-800;
  }

  &--invertedPrimary {
    background-color: $primary-600;
    color: $primary-100;
  }

  &--purple {
    background-color: $purple-100;
    color: $purple-800;
  }

  &--invertedPurple {
    background-color: $purple-600;
    color: $purple-100;
  }

  &--success {
    background-color: $success-100;
    color: $success-800;
  }

  &--invertedSuccess {
    background-color: $success-600;
    color: $success-100;
  }

  &--danger {
    background-color: $danger-100;
    color: $danger-800;
  }

  &--invertedDanger {
    background-color: $danger-600;
    color: $danger-100;
  }

  &--warning {
    background-color: $warning-100;
    color: $warning-700;
  }
}
