@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.RepDetails__tabs {
  .RepDetails__general {
    padding: 56px 32px 32px 32px;
    max-width: 690px;

    &__personalInfo {
      label {
        @include normal;
        margin-bottom: 4px;
      }
      input,
      select {
        border-radius: 4px;
        margin-bottom: 24px;
      }
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
      }
      span {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      span:not(:last-child) {
        margin-right: 16px;
      }
    }

    &__personalInfo {
      &__photoLabel {
        font-weight: 500 !important;
      }

      &__photoContainer {
        margin-top: 4px;

        &__photo {
          width: 48px;
          height: 48px;
          border-radius: 100px;
          object-fit: cover;
        }

        &__input {
          margin: 0 0 0 20px !important;
          font-family: Roboto, Helvetica, Arial, sans-serif;
          font-size: 14px;
          color: $gray-700;

          &::file-selector-button {
            appearance: none;
            color: #111827;
            cursor: pointer;
            text-align: center;
            background-color: #0000;
            border: 1px solid #e9ecef;
            border-radius: 4px;
            padding: 0.5rem 1.5rem;
            font-family: Roboto, Helvetica, Arial, sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.5;
            transition: all 0.1s ease-in-out;
            display: inline-block;
            position: relative;
            text-decoration: none !important;
            margin-right: 1rem;
          }
        }
      }
    }

    &__paymentInfo {
      display: flex;
      flex-direction: column;

      label {
        @include normal;
      }
      input,
      select {
        border-radius: 4px;
        margin-bottom: 24px;
      }

      &__header {
        font-size: 16px;
        margin: 40px 0 8px 0;
        @include normal;
      }
      &__divider {
        width: 100%;
        height: 1px;
        background-color: $gray-200;
        margin: 0 0 24px 0;
      }
      &__status {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__statusContainer {
          align-items: center;
          display: flex;

          p {
            margin-right: 16px;
          }
        }
      }
    }
    &__save {
      &__divider {
        width: 100%;
        height: 1px;
        background-color: $gray-200;
        margin: 56px 0px 20px 0px;
      }
      &__buttons {
        display: flex;
        justify-content: flex-end;

        button:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
  }
}
