@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.Checkbox__container {
  @include semi-bold;
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 16px;
  line-height: 16px;
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
  user-select: none;

  input {
    display: none;
  }

  &:hover {
    .Checkbox:not(:checked):not(:disabled) ~ .Checkbox__mark {
      background-color: $primary-100;
      border-color: $gray-500;
    }
  }

  &:focus,
  &:active {
    .Checkbox:not(:checked):not(:disabled) ~ .Checkbox__mark {
      background-color: $primary-200;
      border-color: $gray-500;
    }
  }
}

.Checkbox {
  &:checked ~ .Checkbox__mark {
    background-color: $primary;
    border-color: $primary;
  }

  &:checked ~ .Checkbox__mark:after {
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    content: '';
    height: 3px;
    width: 9px;
    position: absolute;
    top: 4px;
    left: 2px;
    transform: rotate(-45deg);
  }

  &:disabled ~ .Checkbox__mark {
    background-color: $gray-300;
    border-color: $gray-300;
    cursor: not-allowed;
  }
}

.Checkbox__mark {
  border: 1px solid $border;
  border-radius: 4px;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s ease-in-out;
}
