@import 'src/scss/variables';
@import 'src/scss/mixins';

.Displays {
  padding: 2rem;

  &__listPage {
    padding: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      &__title {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.2;
        margin: 1rem 0;
      }
    }
    .Pagination {
      bottom: -2rem !important;
    }
  }
}
