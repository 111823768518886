@import '~/src/scss/mixins';
@import '~/src/scss/variables';

$colors: (
  'primary': (
    'main': $primary-600,
    'hover': $primary-50,
    'active': $primary-100,
  ),
  'accent': (
    'main': $success-600,
    'hover': $success-50,
    'active': $success-100,
  ),
  'warn': (
    'main': $danger-600,
    'hover': $danger-50,
    'active': $danger-100,
  ),
  'light': (
    'main': $gray-100,
    'hover': $gray-50,
    'active': $gray-100,
  ),
  'dark': (
    'main': $gray-900,
    'hover': $gray-50,
    'active': $gray-100,
  ),
);

@mixin icon-button-base {
  @include bold;
  @include sans;
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 100%;
  color: $gray-900;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 40px;
  padding: 0;
  position: relative;
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
  text-decoration: none !important;
  transition: all 0.1s ease-in-out;
  width: 40px;

  &:disabled {
    cursor: not-allowed;
  }

  svg {
    width: 24px;
  }
}

.IconButton {
  @include icon-button-base;
}

.IconButton {
  &:hover {
    background-color: $gray-50;
  }

  &:active {
    background-color: $gray-100;
  }

  &:disabled {
    color: $gray-500 !important;

    &:hover {
      background-color: transparent !important;
    }

    &:active {
      background-color: transparent !important;
    }
  }
}

.IconButton {
  @each $name, $values in $colors {
    &.IconButton--color--#{$name} {
      color: map-get($values, 'main');

      &:hover {
        background-color: map-get($values, 'hover');
      }

      &:active {
        background-color: map-get($values, 'active');
      }
    }
  }
}
