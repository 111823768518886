@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.DataTable {
  border-collapse: separate;
  border-spacing: 0;
  position: relative;
  width: 100%;

  thead {
    user-select: none;

    th {
      @include medium;
      background-color: $gray-100;
      border-top: 1px solid $gray-200;
      border-bottom: 1px solid $gray-200;
      color: $gray-700;
      font-size: 12px;
      padding: 0.75rem 1rem;
      text-align: left;
      text-transform: uppercase;

      position: sticky;
      top: 0;
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: $gray-50;
      }
    }
    tr td {
      border-bottom: 1px solid $gray-200;
      font-size: 14px;
      padding: 0.75rem 1rem;
    }
  }

  a {
    color: $primary-600;
  }

  &__header {
    &--sortable {
      cursor: pointer;
    }
    &--sm {
      width: 120px;
    }

    &--md {
      width: 240px;
    }

    &--lg {
      width: 320px;
    }

    &__container {
      align-items: center;
      display: flex;
      gap: 4px;

      svg {
        width: 12px;
      }
    }
  }
}
