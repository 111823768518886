@import '~/src/scss/variables.scss';

.InfoSection {
  padding: 1.5rem 0;
  width: 268px;

  &__field {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    label {
      color: $gray-700;
    }
    p {
      color: $gray-900;
      margin: 0;
      text-align: right;
    }
  }

  &__overview {
    margin-bottom: 2rem;

    &__header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-bottom: 4px;
    }

    &__edit {
      color: $primary-500;
    }

    &__body {
      background-color: $gray-100;
      border-radius: 8px;
      padding: 1rem;
    }
  }

  &__status {
    padding: 0.5rem;
    margin-bottom: 2rem;
  }

  &__primaryContact {
    margin-bottom: 2rem;

    &__header {
      padding-bottom: 4px;
    }

    &__body {
      padding: 0.5rem;
    }
  }

  &__notes {
    &__header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-bottom: 8px;
    }

    &__edit {
      color: $primary-500;
    }

    &__body {
      background-color: $gray-100;
      border-radius: 8px;
      padding: 1rem;
      white-space: pre-wrap;

      & p {
        margin: 0;
      }
    }

    &__buttons {
      align-items: center;
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      margin-top: 1rem;
    }
  }
}
