@import 'src/scss/variables.scss';

.ProductDetails__artNotesModal {
  &__header {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-50;
    border-radius: 8px 8px 0 0;

    & > p {
      color: $gray-900;
      font-size: 18px;
    }
  }

  &__body {
    padding: 24px;

    & > p {
      color: $gray-900;
      font-size: 18px;
      font-weight: 500;
    }

    &__datesContainer {
      margin-bottom: 24px;
    }

    &__row {
      display: flex;
      align-items: flex-end;
      padding: 24px 0;
      border-bottom: 1px solid $gray-200;

      & > :not(:last-child) {
        margin-right: 24px;
      }
    }

    &__inputField {
      flex: 1 1 1px;
      display: flex;
      flex-direction: column;

      & > p {
        font-weight: 500;
        margin-bottom: 4px;
      }

      & > :last-child {
        display: flex;
      }
      .Input__container {
        flex-grow: 1;
        display: flex;
        .Input {
          flex-grow: 1;
        }
      }
    }

    &__textAreaNotes {
      .Radio__container {
        font-weight: 400;
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      .Input:disabled {
        background-color: $gray-100;
        border: 1px solid $gray-300;
      }
    }
  }

  &__buttons {
    padding: 16px 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > :first-child {
      margin-right: 16px;
    }
  }
}
