@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.EditApprovalDate {
  &__content {
    padding: 2rem;
  }

  &__field {
    margin-bottom: 1.5rem;

    label {
      @include semi-bold;
      font-size: 14px;
      margin-bottom: 4px;
    }
  }

  &__actions {
    border-top: 1px solid $border;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    padding: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
