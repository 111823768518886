@import 'src/scss/variables.scss';
@import 'src/scss/mixins.scss';

.DisplayHistoryDrawer {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__content {
    table {
      thead {
        border: none;
      }
      tr {
        border: none;
      }
    }
  }
}
