// colors

$white: #ffffff;
$black: #000000;

$gray-50: #f9fafb;
$gray-100: #f3f4f6;
$gray-150: #e9ecef;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-400: #9ca3af;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-700: #374151;
$gray-800: #1f2937;
$gray-900: #111827;

$primary-25: #f4f8fe;
$primary-50: #f0f6fe;
$primary-100: #ddebfc;
$primary-200: #c3dcfa;
$primary-300: #99c8f7;
$primary-400: #69aaf1;
$primary-500: #3981ea;
$primary-600: #306ce0;
$primary-700: #2857cd;
$primary-800: #2647a7;
$primary-900: #244084;

$azure-50: #f5f9fa;
$azure-100: #ddf1fb;
$azure-200: #b7def8;
$azure-300: #87beed;
$azure-400: #5699de;
$azure-500: #3981ea;
$azure-600: #375abc;
$azure-700: #2c4499;
$azure-800: #1f2e6f;
$azure-900: #121c47;

$success-50: #f0fdf4;
$success-100: #dcfce7;
$success-200: #bbf7d0;
$success-300: #86efac;
$success-400: #4ade80;
$success-700: #15803d;
$success-600: #16a34a;
$success-500: #22c55e;
$success-800: #166534;
$success-900: #14532d;

$danger-50: #fdfcfa;
$danger-100: #fbf0ea;
$danger-150: #fee2e2;
$danger-200: #f7d1d4;
$danger-300: #eca4ac;
$danger-400: #e77580;
$danger-500: #d9515e;
$danger-600: #c13741;
$danger-700: #992930;
$danger-800: #6d1d20;
$danger-900: #431212;

$warning-50: #fefce8;
$warning-100: #fef9c3;
$warning-200: #fef08a;
$warning-300: #fde047;
$warning-400: #facc15;
$warning-500: #eab308;
$warning-600: #ca8a04;
$warning-700: #a16207;
$warning-800: #854d0e;
$warning-900: #713f12;

$cocoa-50: #fcfbf7;
$cocoa-100: #faf0d6;
$cocoa-200: #f5d8aa;
$cocoa-300: #e5b077;
$cocoa-400: #d68249;
$cocoa-500: #bf602b;
$cocoa-600: #a2461c;
$cocoa-700: #7c3417;
$cocoa-800: #562411;
$cocoa-900: #36160b;

$purple-50: #fafafb;
$purple-100: #f1effa;
$purple-200: #e2d3f6;
$purple-300: #c7acea;
$purple-400: #b681db;
$purple-500: #9e5ccf;
$purple-600: #8441b9;
$purple-700: #633094;
$purple-800: #442267;
$purple-900: #27163c;

$warning-50: #fefce8;
$warning-100: #fef9ce;
$warning-200: #fef08a;
$warning-300: #fde047;
$warning-400: #facc15;
$warning-500: #eab308;
$warning-600: #ca8a04;
$warning-700: #a16207;
$warning-800: #854d0e;
$warning-900: #713f12;

// named colors

$border: $gray-150;

$primary: $primary-500;
$primary-5: #2b2d42;
$primary-4: #353857;
$primary-3: #3d4167;

$info: $azure-500;
$success: $success-500;
$warn: $cocoa-500;
$error: $danger-500;
