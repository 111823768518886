@import 'src/scss/variables.scss';

.SalesHistory {
  height: 100%;
  display: flex;
  flex-direction: column;

  p {
    color: $gray-700;
    margin: 0;
    line-height: 1.5;
  }

  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: $gray-100;
  }

  &__body {
    flex-grow: 1;
    background-color: #fff;
    padding: 16px;
  }

  &__backLink {
    &__link {
      display: flex;
      align-items: center;
      width: fit-content;

      &:hover {
        text-decoration: none;
      }
      svg {
        color: $gray-700;
        margin-right: 8px;
        width: 24px;
      }
      & > p {
        color: $gray-700;
        font-weight: 500;
        font-size: 18px;
      }
    }
  }

  &__navigation {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 16px;

    &__inputs {
      display: flex;
      align-items: flex-end;

      & > :not(:last-child) {
        margin-right: 16px;
      }
    }

    &__inputGroup {
      & > p {
        margin-bottom: 4px;
      }

      &__dateRange {
        display: flex;

        & > :first-child {
          margin-right: 16px;
        }
      }
    }

    &__format {
      list-style-type: none;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      border-radius: 6px;
      border: 1px solid $gray-300;

      & > li {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 9px;
        cursor: pointer;

        svg {
          color: $gray-700;
          width: 20px;
        }

        &:not(:last-child) {
          border-right: 1px solid $gray-300;
        }

        &.SalesHistory__navigation__activeFormatButton {
          background-color: $gray-200;
          box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  &__dataHeader {
    display: flex;
    align-items: center;
    padding: 24px 16px;
    margin-bottom: 16px;

    & > :not(:last-child) {
      margin-right: 80px;
    }

    &__title {
      display: flex;
      align-items: center;

      & p:first-child {
        color: $gray-500;
      }

      & p:nth-child(2) {
        color: $gray-900;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.2;
      }

      &__symbol {
        width: 48px;
        height: 48px;
        background-color: $primary-500;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;

        svg {
          color: #fff;
          width: 24px;
        }
      }
    }

    &__column {
      display: flex;
      flex-direction: column;

      &:nth-child(2) {
        width: 200px;
      }

      &:nth-child(3) {
        width: 130px;
      }

      & > span {
        display: flex;
        justify-content: space-between;

        p:nth-child(2) {
          color: $gray-900;
        }
      }
    }
  }

  table {
    thead {
      border-top: none;
    }
    tbody {
      tr {
        td {
          img {
            max-width: 48px;
            max-height: 48px;
          }
        }
      }
    }
    &.SalesHistory__nonDisplayTable {
      th:nth-child(n + 7),
      td:nth-child(n + 7) {
        text-align: right;
      }
    }
  }
}
