@import '~/src/scss/variables.scss';

.InvoiceDetailsModal {
  hr {
    margin: 32px 0;
    border: 1px solid $gray-200;
  }

  .Modal__close {
    display: none;
  }

  .Modal__content {
    height: auto;
    max-width: 820px;
    padding: unset;
  }

  .modalHeader {
    padding: 0 32px;
    background-color: $gray-50;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .font-size-18 {
      padding: 19px 0;
    }
  }

  .modalBody {
    padding: 32px 20px 48px;

    .inputForm {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__incompleteRow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-bottom: 24px;
  }

  &__buttons {
    & button {
      padding: 4px 16px !important;
    }
  }

  &__payments {
    .inputForm input {
      text-align: right;
    }
  }

  &__cancelledFields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    align-items: last baseline;
  }
}
