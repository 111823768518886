* {
  box-sizing: border-box;
}

body {
  min-width: 320px;
  background-color: #fff;
  margin: 0;
  padding: 0;
}

button, input[type="submit"], input[type="button"] {
  cursor: pointer;
}

button:disabled, input[type="submit"]:disabled, input[type="button"]:disabled {
  cursor: not-allowed;
}

img, video, iframe {
  max-width: 100%;
}

table {
  font-size: inherit;
}

a {
  cursor: pointer;
}

html, body {
  color: #1f2937;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

h1 {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.2;
}

.h1-mobile {
  color: #374151;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.4;
}

h2 {
  font-size: 32px;
  font-weight: 400;
  line-height: 1.2;
}

h3 {
  font-size: 28px;
  font-weight: 400;
  line-height: 1.2;
}

h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}

h5 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
}

h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}

.error {
  color: #d9515e;
  font-size: 14px;
  font-weight: 700;
}

.hint {
  margin-top: 8px;
  font-weight: 400;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

.flex-7 {
  flex: 7;
}

.flex-8 {
  flex: 8;
}

.flex-9 {
  flex: 9;
}

.flex-10 {
  flex: 10;
}

.flex-11 {
  flex: 11;
}

.flex-12 {
  flex: 12;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-9 {
  gap: 2.25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-11 {
  gap: 2.75rem;
}

.gap-12 {
  gap: 3rem;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.p-0 {
  padding: 0;
}

.pt-1 {
  padding-top: .25rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pr-1 {
  padding-right: .25rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.p-1 {
  padding: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.p-2 {
  padding: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pr-3 {
  padding-right: .75rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.p-3 {
  padding: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-4 {
  padding: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.p-5 {
  padding: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.p-6 {
  padding: 1.5rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.p-7 {
  padding: 1.75rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-8 {
  padding-right: 2rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.p-8 {
  padding: 2rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.p-9 {
  padding: 2.25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.p-10 {
  padding: 2.5rem;
}

.pt-11 {
  padding-top: 2.75rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.pl-11 {
  padding-left: 2.75rem;
}

.pr-11 {
  padding-right: 2.75rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.p-11 {
  padding: 2.75rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pr-12 {
  padding-right: 3rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.p-12 {
  padding: 3rem;
}

.pt-13 {
  padding-top: 3.25rem;
}

.pb-13 {
  padding-bottom: 3.25rem;
}

.pl-13 {
  padding-left: 3.25rem;
}

.pr-13 {
  padding-right: 3.25rem;
}

.px-13 {
  padding-left: 3.25rem;
  padding-right: 3.25rem;
}

.py-13 {
  padding-top: 3.25rem;
  padding-bottom: 3.25rem;
}

.p-13 {
  padding: 3.25rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.p-14 {
  padding: 3.5rem;
}

.pt-15 {
  padding-top: 3.75rem;
}

.pb-15 {
  padding-bottom: 3.75rem;
}

.pl-15 {
  padding-left: 3.75rem;
}

.pr-15 {
  padding-right: 3.75rem;
}

.px-15 {
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}

.py-15 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

.p-15 {
  padding: 3.75rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pr-16 {
  padding-right: 4rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.p-16 {
  padding: 4rem;
}

.pt-17 {
  padding-top: 4.25rem;
}

.pb-17 {
  padding-bottom: 4.25rem;
}

.pl-17 {
  padding-left: 4.25rem;
}

.pr-17 {
  padding-right: 4.25rem;
}

.px-17 {
  padding-left: 4.25rem;
  padding-right: 4.25rem;
}

.py-17 {
  padding-top: 4.25rem;
  padding-bottom: 4.25rem;
}

.p-17 {
  padding: 4.25rem;
}

.pt-18 {
  padding-top: 4.5rem;
}

.pb-18 {
  padding-bottom: 4.5rem;
}

.pl-18 {
  padding-left: 4.5rem;
}

.pr-18 {
  padding-right: 4.5rem;
}

.px-18 {
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}

.py-18 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.p-18 {
  padding: 4.5rem;
}

.pt-19 {
  padding-top: 4.75rem;
}

.pb-19 {
  padding-bottom: 4.75rem;
}

.pl-19 {
  padding-left: 4.75rem;
}

.pr-19 {
  padding-right: 4.75rem;
}

.px-19 {
  padding-left: 4.75rem;
  padding-right: 4.75rem;
}

.py-19 {
  padding-top: 4.75rem;
  padding-bottom: 4.75rem;
}

.p-19 {
  padding: 4.75rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pr-20 {
  padding-right: 5rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.p-20 {
  padding: 5rem;
}

.pt-21 {
  padding-top: 5.25rem;
}

.pb-21 {
  padding-bottom: 5.25rem;
}

.pl-21 {
  padding-left: 5.25rem;
}

.pr-21 {
  padding-right: 5.25rem;
}

.px-21 {
  padding-left: 5.25rem;
  padding-right: 5.25rem;
}

.py-21 {
  padding-top: 5.25rem;
  padding-bottom: 5.25rem;
}

.p-21 {
  padding: 5.25rem;
}

.pt-22 {
  padding-top: 5.5rem;
}

.pb-22 {
  padding-bottom: 5.5rem;
}

.pl-22 {
  padding-left: 5.5rem;
}

.pr-22 {
  padding-right: 5.5rem;
}

.px-22 {
  padding-left: 5.5rem;
  padding-right: 5.5rem;
}

.py-22 {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
}

.p-22 {
  padding: 5.5rem;
}

.pt-23 {
  padding-top: 5.75rem;
}

.pb-23 {
  padding-bottom: 5.75rem;
}

.pl-23 {
  padding-left: 5.75rem;
}

.pr-23 {
  padding-right: 5.75rem;
}

.px-23 {
  padding-left: 5.75rem;
  padding-right: 5.75rem;
}

.py-23 {
  padding-top: 5.75rem;
  padding-bottom: 5.75rem;
}

.p-23 {
  padding: 5.75rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pl-24 {
  padding-left: 6rem;
}

.pr-24 {
  padding-right: 6rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.p-24 {
  padding: 6rem;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m-0 {
  margin: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.ml-1 {
  margin-left: .25rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.m-1 {
  margin: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.m-2 {
  margin: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.m-3 {
  margin: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.m-4 {
  margin: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.m-5 {
  margin: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.m-6 {
  margin: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.m-7 {
  margin: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.m-8 {
  margin: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.mr-9 {
  margin-right: 2.25rem;
}

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.m-9 {
  margin: 2.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.m-10 {
  margin: 2.5rem;
}

.mt-11 {
  margin-top: 2.75rem;
}

.mb-11 {
  margin-bottom: 2.75rem;
}

.ml-11 {
  margin-left: 2.75rem;
}

.mr-11 {
  margin-right: 2.75rem;
}

.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem;
}

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}

.m-11 {
  margin: 2.75rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.ml-12 {
  margin-left: 3rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.m-12 {
  margin: 3rem;
}

.mt-13 {
  margin-top: 3.25rem;
}

.mb-13 {
  margin-bottom: 3.25rem;
}

.ml-13 {
  margin-left: 3.25rem;
}

.mr-13 {
  margin-right: 3.25rem;
}

.mx-13 {
  margin-left: 3.25rem;
  margin-right: 3.25rem;
}

.my-13 {
  margin-top: 3.25rem;
  margin-bottom: 3.25rem;
}

.m-13 {
  margin: 3.25rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.ml-14 {
  margin-left: 3.5rem;
}

.mr-14 {
  margin-right: 3.5rem;
}

.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.m-14 {
  margin: 3.5rem;
}

.mt-15 {
  margin-top: 3.75rem;
}

.mb-15 {
  margin-bottom: 3.75rem;
}

.ml-15 {
  margin-left: 3.75rem;
}

.mr-15 {
  margin-right: 3.75rem;
}

.mx-15 {
  margin-left: 3.75rem;
  margin-right: 3.75rem;
}

.my-15 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

.m-15 {
  margin: 3.75rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.ml-16 {
  margin-left: 4rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.m-16 {
  margin: 4rem;
}

.mt-17 {
  margin-top: 4.25rem;
}

.mb-17 {
  margin-bottom: 4.25rem;
}

.ml-17 {
  margin-left: 4.25rem;
}

.mr-17 {
  margin-right: 4.25rem;
}

.mx-17 {
  margin-left: 4.25rem;
  margin-right: 4.25rem;
}

.my-17 {
  margin-top: 4.25rem;
  margin-bottom: 4.25rem;
}

.m-17 {
  margin: 4.25rem;
}

.mt-18 {
  margin-top: 4.5rem;
}

.mb-18 {
  margin-bottom: 4.5rem;
}

.ml-18 {
  margin-left: 4.5rem;
}

.mr-18 {
  margin-right: 4.5rem;
}

.mx-18 {
  margin-left: 4.5rem;
  margin-right: 4.5rem;
}

.my-18 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}

.m-18 {
  margin: 4.5rem;
}

.mt-19 {
  margin-top: 4.75rem;
}

.mb-19 {
  margin-bottom: 4.75rem;
}

.ml-19 {
  margin-left: 4.75rem;
}

.mr-19 {
  margin-right: 4.75rem;
}

.mx-19 {
  margin-left: 4.75rem;
  margin-right: 4.75rem;
}

.my-19 {
  margin-top: 4.75rem;
  margin-bottom: 4.75rem;
}

.m-19 {
  margin: 4.75rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.ml-20 {
  margin-left: 5rem;
}

.mr-20 {
  margin-right: 5rem;
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.m-20 {
  margin: 5rem;
}

.mt-21 {
  margin-top: 5.25rem;
}

.mb-21 {
  margin-bottom: 5.25rem;
}

.ml-21 {
  margin-left: 5.25rem;
}

.mr-21 {
  margin-right: 5.25rem;
}

.mx-21 {
  margin-left: 5.25rem;
  margin-right: 5.25rem;
}

.my-21 {
  margin-top: 5.25rem;
  margin-bottom: 5.25rem;
}

.m-21 {
  margin: 5.25rem;
}

.mt-22 {
  margin-top: 5.5rem;
}

.mb-22 {
  margin-bottom: 5.5rem;
}

.ml-22 {
  margin-left: 5.5rem;
}

.mr-22 {
  margin-right: 5.5rem;
}

.mx-22 {
  margin-left: 5.5rem;
  margin-right: 5.5rem;
}

.my-22 {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
}

.m-22 {
  margin: 5.5rem;
}

.mt-23 {
  margin-top: 5.75rem;
}

.mb-23 {
  margin-bottom: 5.75rem;
}

.ml-23 {
  margin-left: 5.75rem;
}

.mr-23 {
  margin-right: 5.75rem;
}

.mx-23 {
  margin-left: 5.75rem;
  margin-right: 5.75rem;
}

.my-23 {
  margin-top: 5.75rem;
  margin-bottom: 5.75rem;
}

.m-23 {
  margin: 5.75rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.ml-24 {
  margin-left: 6rem;
}

.mr-24 {
  margin-right: 6rem;
}

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.m-24 {
  margin: 6rem;
}

.color-gray-500 {
  color: #6b7280;
}

.color-gray-600 {
  color: #4b5563;
}

.color-gray-700 {
  color: #374151;
}

.color-gray-900 {
  color: #111827;
}

.color-success-700 {
  color: #15803d;
}

.color-primary {
  color: #3981ea;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semi-bold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-italic {
  font-style: italic;
}

.font-size-8 {
  font-size: 8px;
}

.font-size-9 {
  font-size: 9px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-19 {
  font-size: 19px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-21 {
  font-size: 21px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-23 {
  font-size: 23px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-25 {
  font-size: 25px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-27 {
  font-size: 27px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-29 {
  font-size: 29px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-31 {
  font-size: 31px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-33 {
  font-size: 33px;
}

.font-size-34 {
  font-size: 34px;
}

.font-size-35 {
  font-size: 35px;
}

.font-size-36 {
  font-size: 36px;
}

.font-size-37 {
  font-size: 37px;
}

.font-size-38 {
  font-size: 38px;
}

.font-size-39 {
  font-size: 39px;
}

.font-size-40 {
  font-size: 40px;
}

.font-size-41 {
  font-size: 41px;
}

.font-size-42 {
  font-size: 42px;
}

.font-size-43 {
  font-size: 43px;
}

.font-size-44 {
  font-size: 44px;
}

.font-size-45 {
  font-size: 45px;
}

.font-size-46 {
  font-size: 46px;
}

.font-size-47 {
  font-size: 47px;
}

.font-size-48 {
  font-size: 48px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-nowrap {
  white-space: nowrap;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.w-full {
  width: 100%;
}

a {
  color: #3981ea;
  text-decoration: none;
  transition: color .25s ease-in-out;
}

a:link, a:visited {
  color: #3981ea;
}

a:hover, a:active {
  text-decoration: underline;
}

.divider {
  border: none;
  border-bottom: 1px solid #e9ecef;
  margin: 1rem 0;
}

.icon-wrapper {
  color: #375abc;
  height: 48px;
  width: 48px;
  background-color: #f5f9fa;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.icon-wrapper--green {
  color: #fff;
  background-color: #22c55e;
}

.icon-wrapper svg {
  width: 24px;
}

.headspace {
  margin-top: 8rem;
}

.center-page {
  max-width: 360px;
  margin: 6.5rem auto 1rem;
}

.center-page-img {
  margin: 0 auto 5rem;
  display: block;
}

.center-page-title {
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 600;
}

.center-page-sub {
  color: #1f2937;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 16px;
}

.center-page-link {
  margin-top: 1.5rem;
}

.data-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  position: relative;
}

.data-table thead {
  -webkit-user-select: none;
  user-select: none;
}

.data-table thead th {
  color: #374151;
  text-align: left;
  text-transform: uppercase;
  background-color: #f3f4f6;
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
  padding: .75rem 1rem;
  font-size: 12px;
  font-weight: 500;
  position: sticky;
  top: 0;
}

.data-table tbody tr:nth-child(2n) {
  background-color: #f9fafb;
}

.data-table tbody tr td {
  border-bottom: 1px solid #e5e7eb;
  padding: .75rem 1rem;
  font-size: 14px;
}

.data-table a {
  color: #306ce0;
}

.PieceworkerLayout {
  height: 100vh;
  flex-direction: column;
  margin: 0 auto;
  display: flex;
  overflow: hidden;
}

.PieceworkerLayout__routes {
  flex: 1;
  padding: 1rem;
  overflow: auto;
}

.AdminLayout {
  height: 100vh;
  flex-direction: row;
  display: flex;
}

.AdminLayout__sidebar {
  flex: 0 0 208px;
}

.AdminLayout__sidebar--collapsed {
  flex: 0 0 48px;
}

.AdminLayout__routes {
  flex: auto;
  overflow: auto;
}

.PWProfile {
  width: calc(100% + 2rem);
  margin: -1rem;
}

.PWProfile__imageHeader {
  width: 100%;
  height: 92px;
  background-color: #f3f4f6;
}

.PWProfile__imageHeader > img {
  height: 92px;
  border-radius: 6px;
  position: relative;
  top: 20px;
  left: 1rem;
  box-shadow: 10px 10px 80px #00000012;
}

.PWProfile__body {
  width: 100%;
  padding: 0 16px;
}

.PWProfile__body p {
  color: #374151;
  margin: 0;
}

.PWProfile__info {
  width: 100%;
  border-radius: 8px;
  flex-direction: column;
  gap: 8px;
  margin-top: 2rem;
  padding: 16px;
  display: flex;
  box-shadow: 0 1px 2px #0000000f, 0 1px 3px #0000001a;
}

.PWProfile__info__name {
  font-size: 18px;
  font-weight: 500;
  color: #111827 !important;
}

.PWProfile__picture {
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding: 18px 0;
  display: flex;
}

.PWProfile__picture > span {
  align-items: center;
  gap: 16px;
  display: flex;
}

.PWProfile__picture > span img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.PWProfile__picture > span p {
  font-weight: 500;
}

.PWProfile__picture__fileInput {
  align-items: center;
  display: flex;
  position: relative;
}

.PWProfile__picture__fileInput > label {
  cursor: pointer;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  font-weight: 500;
  display: flex;
}

.PWProfile__picture__fileInput > input {
  opacity: 0;
  width: 0;
}

.PWProfile__paymentMethod {
  margin-top: 32px;
}

.PWProfile__paymentMethod > p {
  font-size: 16px;
  color: #111827 !important;
}

.PWProfile__paymentMethod__banner {
  width: 100%;
  background-color: #f3f4f6;
  border-radius: 6px;
  align-items: center;
  gap: 12px;
  margin-top: 16px;
  padding: 8px 24px;
  display: flex;
}

.PWProfile__paymentMethod__banner__icon {
  width: 32px;
  height: 32px;
  background-color: #2857cd;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  display: flex;
}

.PWProfile__paymentMethod__banner__icon > svg {
  color: #fff;
}

.PWProfile__paymentMethod__banner > p {
  font-weight: 500;
}

.PWPayments__dateRange {
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.PWPayments__list {
  margin-top: 1rem;
}

.PWPayments__list__item {
  border-bottom: 1px solid #e9ecef;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
}

.PWPayments__list__item__total {
  text-align: right;
}

.PWPayments__cards {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.PWPayments__card {
  border-radius: 8px;
  flex: 1;
  align-items: center;
  gap: 1rem;
  padding: 1.25rem 1rem;
  display: flex;
  box-shadow: 0 1px 2px #0000000f, 0 1px 3px #0000001a;
}

.PWMyJobs label {
  color: #6b7280;
  font-size: 14px;
}

.PWMyJobs .Tabs {
  justify-content: center;
  margin-bottom: 2rem;
}

.PWMyJobs .TabItem {
  flex: 1;
  justify-content: center;
}

.PWMyJobs .job {
  padding: 1rem;
}

.PWMyJobs .job__header {
  border-bottom: 1px solid #e9ecef;
  justify-content: space-between;
  align-items: center;
  margin: .5rem 0;
  padding: .5rem 0;
  display: flex;
}

.PWMyJobs .job__header__title {
  color: #374151;
  font-size: 14px;
  font-weight: 600;
}

.PWMyJobs .job__body__row {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  display: flex;
}

.PWMyJobs .job__body__row--completed {
  color: #6b7280;
}

.PWJobDetail label {
  color: #6b7280;
  font-size: 14px;
}

.PWJobDetail__total {
  background-color: #f3f4f6;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
}

.PWJobDetail__total__icon {
  margin-right: 1rem;
}

.PWJobDetail__total h4 {
  color: #111827;
  margin: 0;
}

.PWJobDetail__card {
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: .75rem 1rem;
  box-shadow: 0 1px 2px #0000000f, 0 1px 3px #0000001a;
}

.PWJobDetail__dates {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.PWJobDetail__task__row {
  justify-content: space-between;
  display: flex;
}

.PWJobDetail__task__field {
  display: flex;
}

.PWJobDetail__task__field label {
  margin-right: 4px;
}

.PWJobDetail__task__badges {
  gap: 8px;
  margin-top: 4px;
  display: flex;
}

.PWJobDetail__actions {
  padding: 8px 0;
  display: flex;
}

.PWJobDetail__modal__actions {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.PWJobDetail__modal__actions__button:not(:last-child) {
  margin-right: 1rem;
}

.AvailabilityEdit .icon-wrapper {
  margin: 32px 0;
}

.AvailabilityEdit__availability {
  justify-content: space-between;
  gap: 1rem;
  display: flex;
}

.AvailabilityEdit__actions {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
  display: flex;
}

.AvailabilityEdit__actions__spacer {
  width: 100%;
  height: 1px;
  background-color: #e5e7eb;
  margin: 1rem 0;
}

.AvailabilityEdit__actions__warnButton {
  color: #6d1d20 !important;
  background-color: #fbf0ea !important;
}

.PWAvailability .availability {
  border-top: 1px solid #e9ecef;
  margin-bottom: 1rem;
  padding: 1rem;
}

.PWAvailability .availability:last-of-type {
  border-bottom: 1px solid #e9ecef;
}

.PWAvailability .availability__date {
  justify-content: space-between;
  display: flex;
}

.PieceworkerNav {
  width: 100%;
  padding: .75rem 1rem;
  display: flex;
}

.PieceworkerNav__logo {
  cursor: pointer;
  width: 40px;
}

.PieceworkerNav__spacer {
  flex: 1;
}

.PieceworkerFooter {
  width: 100%;
  background-color: #2b2d42;
  justify-content: space-around;
  margin: 0 auto;
  padding: .75rem 1rem;
  display: flex;
}

.PieceworkerFooter__link {
  color: #fff;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.PieceworkerFooter__link__text {
  font-size: 14px;
  font-weight: 600;
}

.PieceworkerFooter__link svg, .PieceworkerFooter__link img {
  width: 24px;
}

.SalesHistory {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.SalesHistory p {
  color: #374151;
  margin: 0;
  line-height: 1.5;
}

.SalesHistory__container {
  background-color: #f3f4f6;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.SalesHistory__body {
  background-color: #fff;
  flex-grow: 1;
  padding: 16px;
}

.SalesHistory__backLink__link {
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  display: flex;
}

.SalesHistory__backLink__link:hover {
  text-decoration: none;
}

.SalesHistory__backLink__link svg {
  color: #374151;
  width: 24px;
  margin-right: 8px;
}

.SalesHistory__backLink__link > p {
  color: #374151;
  font-size: 18px;
  font-weight: 500;
}

.SalesHistory__navigation {
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
  display: flex;
}

.SalesHistory__navigation__inputs {
  align-items: flex-end;
  display: flex;
}

.SalesHistory__navigation__inputs > :not(:last-child) {
  margin-right: 16px;
}

.SalesHistory__navigation__inputGroup > p {
  margin-bottom: 4px;
}

.SalesHistory__navigation__inputGroup__dateRange {
  display: flex;
}

.SalesHistory__navigation__inputGroup__dateRange > :first-child {
  margin-right: 16px;
}

.SalesHistory__navigation__format {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.SalesHistory__navigation__format > li {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 9px;
  display: flex;
}

.SalesHistory__navigation__format > li svg {
  color: #374151;
  width: 20px;
}

.SalesHistory__navigation__format > li:not(:last-child) {
  border-right: 1px solid #d1d5db;
}

.SalesHistory__navigation__format > li.SalesHistory__navigation__activeFormatButton {
  background-color: #e5e7eb;
  box-shadow: inset 0 2px 2px #0000001a;
}

.SalesHistory__dataHeader {
  align-items: center;
  margin-bottom: 16px;
  padding: 24px 16px;
  display: flex;
}

.SalesHistory__dataHeader > :not(:last-child) {
  margin-right: 80px;
}

.SalesHistory__dataHeader__title {
  align-items: center;
  display: flex;
}

.SalesHistory__dataHeader__title p:first-child {
  color: #6b7280;
}

.SalesHistory__dataHeader__title p:nth-child(2) {
  color: #111827;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
}

.SalesHistory__dataHeader__title__symbol {
  width: 48px;
  height: 48px;
  background-color: #3981ea;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  display: flex;
}

.SalesHistory__dataHeader__title__symbol svg {
  color: #fff;
  width: 24px;
}

.SalesHistory__dataHeader__column {
  flex-direction: column;
  display: flex;
}

.SalesHistory__dataHeader__column:nth-child(2) {
  width: 200px;
}

.SalesHistory__dataHeader__column:nth-child(3) {
  width: 130px;
}

.SalesHistory__dataHeader__column > span {
  justify-content: space-between;
  display: flex;
}

.SalesHistory__dataHeader__column > span p:nth-child(2) {
  color: #111827;
}

.SalesHistory table thead {
  border-top: none;
}

.SalesHistory table tbody tr td img {
  max-width: 48px;
  max-height: 48px;
}

.SalesHistory table.SalesHistory__nonDisplayTable th:nth-child(n+7), .SalesHistory table.SalesHistory__nonDisplayTable td:nth-child(n+7) {
  text-align: right;
}

.RepOrdersSummary {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.RepOrdersSummary p {
  color: #374151;
  margin: 0;
  line-height: 1.5;
}

.RepOrdersSummary__container {
  background-color: #f3f4f6;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.RepOrdersSummary__body {
  background-color: #fff;
  flex-grow: 1;
  padding: 16px;
}

.RepOrdersSummary__backLink__link {
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  display: flex;
}

.RepOrdersSummary__backLink__link:hover {
  text-decoration: none;
}

.RepOrdersSummary__backLink__link svg {
  color: #374151;
  width: 24px;
  margin-right: 8px;
}

.RepOrdersSummary__backLink__link > p {
  color: #374151;
  font-size: 18px;
  font-weight: 500;
}

.RepOrdersSummary__navigation {
  align-items: center;
  padding: 16px 0;
  display: flex;
}

.RepOrdersSummary__navigation__content {
  flex-grow: 1;
  align-items: center;
  gap: 80px;
  padding: 0 16px;
  display: flex;
}

.RepOrdersSummary__navigation__content__title {
  align-items: center;
  display: flex;
}

.RepOrdersSummary__navigation__content__title p:first-child {
  color: #6b7280;
}

.RepOrdersSummary__navigation__content__title p:nth-child(2) {
  color: #111827;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
}

.RepOrdersSummary__navigation__content__title__symbol {
  width: 48px;
  height: 48px;
  background-color: #3981ea;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  display: flex;
}

.RepOrdersSummary__navigation__content__title__symbol svg {
  color: #fff;
  width: 24px;
}

.RepOrdersSummary__navigation__content__column {
  flex-direction: column;
  display: flex;
}

.RepOrdersSummary__navigation__content__column:nth-child(n+1) {
  width: 200px;
}

.RepOrdersSummary__navigation__content__column > span {
  justify-content: space-between;
  display: flex;
}

.RepOrdersSummary__navigation__content__column > span p:nth-child(2) {
  color: #111827;
}

.RepOrdersSummary__navigation__inputs {
  gap: 1rem;
  display: flex;
}

.RepOrdersSummary__navigation__inputs .Input {
  height: 39px !important;
}

.RepOrdersSummary__navigation__inputs__inputGroup {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.RepOrdersSummary__navigation__inputs__inputGroup__dateRange {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.RepOrdersSummary__table td {
  padding: 1rem !important;
}

.RepsList__filters {
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
}

.RepsList__filters label {
  margin-bottom: 4px;
  display: block;
}

.RepsList__filters select {
  min-width: 140px;
}

.RepsList__profile {
  align-items: center;
  display: flex;
}

.RepsList__profile__image {
  border-radius: 50%;
  margin: 2px;
}

.RepsList__profile__image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.RepsList__profile__info {
  margin-left: 16px;
}

.RepsList__profile__info__name {
  margin: 0;
  font-weight: 500;
  line-height: 1.5;
}

.RepsList__phoneList__table {
  max-width: 100%;
}

.RepsList__phoneList__table table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

.RepsList__phoneList__table table th:nth-child(2), .RepsList__phoneList__table table td:nth-child(2), .RepsList__phoneList__table table th:nth-child(3), .RepsList__phoneList__table table td:nth-child(3) {
  width: 140px;
}

.RepsList__phoneList__table table th:nth-child(5), .RepsList__phoneList__table table td:nth-child(5) {
  width: 70px;
}

.RepsList__phoneList__table table th:last-child, .RepsList__phoneList__table table td:last-child {
  width: max-content;
}

.RepsList__phoneList__table .data-table thead, .RepsList__phoneList__table .data-table tbody tr {
  border: none;
}

.RepsList__phoneList__table__name {
  min-width: 150px;
}

.RepsList__phoneList__table__cell, .RepsList__phoneList__table__companyNumber {
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.RepsList__phoneList__table__company {
  max-width: 224px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.RepsList__phoneList__table__area {
  max-width: 330px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media print {
  .RepsList .Drawer {
    height: 100%;
    box-shadow: none;
    position: absolute;
    width: 100% !important;
  }

  .RepsList .Drawer__overlay--bg {
    background-color: #0000;
  }

  .RepsList .RepsList__phoneList {
    margin: 1rem 0;
  }

  .RepsList .RepsList__phoneList__table {
    overflow: visible;
  }

  .RepsList .RepsList__phoneList__table tbody tr td {
    padding: .5rem 1rem;
  }

  .RepsList .RepsList__phoneList .Drawer__actions, .RepsList .RepsList__phoneList .Drawer__header > .IconButton {
    display: none;
  }
}

.RepDetailsComponent__nav__popover {
  width: 600px;
  padding: 1.5rem !important;
}

.RepDetailsComponent__nav__popover__column {
  width: 100%;
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.RepDetails__tabs .RepDetails__reorders {
  flex-grow: 1;
  padding: 16px 16px 0;
}

.RepDetails__tabs .RepDetails__reorders__lastOrder {
  padding: 16px 16px 12px;
}

.RepDetails__tabs .RepDetails__reorders__table:not(:last-child) {
  margin-bottom: 16px;
}

.RepDetails__tabs .RepDetails__reorders__table {
  width: 100%;
}

.RepDetails__tabs .RepDetails__reorders__table__header {
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  display: flex;
}

.RepDetails__tabs .RepDetails__reorders__table__header p {
  margin: 4px 0;
  line-height: 1.5;
}

.RepDetails__tabs .RepDetails__reorders__table__header--storeInfo {
  color: #6b7280;
}

.RepDetails__tabs .RepDetails__reorders__table__header--storeInfo a {
  margin-left: 8px;
}

.RepDetails__tabs .RepDetails__reorders__table__header--storeInfo span {
  color: #374151;
  margin-right: 8px;
  font-weight: 500;
}

.RepDetails__tabs .RepDetails__reorders__table__header--orderInfo {
  color: #6b7280;
  text-align: right;
}

.RepDetails__tabs .RepDetails__reorders__table__header--orderInfo span {
  color: #111827;
  margin-left: 8px;
}

.RepDetails__tabs .RepDetails__reorders__table table thead {
  border-top: none;
}

.RepDetails__tabs .RepDetails__reorders__table table tr:not(:first-child) {
  background-color: #fff;
}

.RepDetails__tabs .RepDetails__reorders__table table tr:last-child {
  border-bottom: none;
}

.RepDetails__tabs .RepDetails__reorders__table table tr td:not(:first-child), .RepDetails__tabs .RepDetails__reorders__table table tr th:not(:first-child) {
  text-align: right;
}

.RepDetails__tabs .RepDetails__general {
  max-width: 690px;
  padding: 56px 32px 32px;
}

.RepDetails__tabs .RepDetails__general__personalInfo label {
  margin-bottom: 4px;
  font-weight: 400;
}

.RepDetails__tabs .RepDetails__general__personalInfo input, .RepDetails__tabs .RepDetails__general__personalInfo select {
  border-radius: 4px;
  margin-bottom: 24px;
}

.RepDetails__tabs .RepDetails__general__personalInfo div {
  width: 100%;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.RepDetails__tabs .RepDetails__general__personalInfo span {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.RepDetails__tabs .RepDetails__general__personalInfo span:not(:last-child) {
  margin-right: 16px;
}

.RepDetails__tabs .RepDetails__general__personalInfo__photoLabel {
  font-weight: 500 !important;
}

.RepDetails__tabs .RepDetails__general__personalInfo__photoContainer {
  margin-top: 4px;
}

.RepDetails__tabs .RepDetails__general__personalInfo__photoContainer__photo {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 100px;
}

.RepDetails__tabs .RepDetails__general__personalInfo__photoContainer__input {
  color: #374151;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin: 0 0 0 20px !important;
}

.RepDetails__tabs .RepDetails__general__personalInfo__photoContainer__input::file-selector-button {
  -webkit-appearance: none;
  appearance: none;
  color: #111827;
  cursor: pointer;
  text-align: center;
  background-color: #0000;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  margin-right: 1rem;
  padding: .5rem 1.5rem;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  transition: all .1s ease-in-out;
  display: inline-block;
  position: relative;
  text-decoration: none !important;
}

.RepDetails__tabs .RepDetails__general__paymentInfo {
  flex-direction: column;
  display: flex;
}

.RepDetails__tabs .RepDetails__general__paymentInfo label {
  font-weight: 400;
}

.RepDetails__tabs .RepDetails__general__paymentInfo input, .RepDetails__tabs .RepDetails__general__paymentInfo select {
  border-radius: 4px;
  margin-bottom: 24px;
}

.RepDetails__tabs .RepDetails__general__paymentInfo__header {
  margin: 40px 0 8px;
  font-size: 16px;
  font-weight: 400;
}

.RepDetails__tabs .RepDetails__general__paymentInfo__divider {
  width: 100%;
  height: 1px;
  background-color: #e5e7eb;
  margin: 0 0 24px;
}

.RepDetails__tabs .RepDetails__general__paymentInfo__status {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.RepDetails__tabs .RepDetails__general__paymentInfo__status__statusContainer {
  align-items: center;
  display: flex;
}

.RepDetails__tabs .RepDetails__general__paymentInfo__status__statusContainer p {
  margin-right: 16px;
}

.RepDetails__tabs .RepDetails__general__save__divider {
  width: 100%;
  height: 1px;
  background-color: #e5e7eb;
  margin: 56px 0 20px;
}

.RepDetails__tabs .RepDetails__general__save__buttons {
  justify-content: flex-end;
  display: flex;
}

.RepDetails__tabs .RepDetails__general__save__buttons button:not(:last-child) {
  margin-right: 12px;
}

.RepDetailsComponent {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.RepDetailsContainer {
  background-color: #f3f4f6;
  flex-grow: 1;
  padding: 24px;
}

.RepDetails {
  width: 100%;
  height: 100%;
  color: #374151;
  background-color: #fff;
  padding: 16px;
  display: flex;
}

.RepDetails__info {
  width: 300px;
}

.RepDetails__info p {
  margin: 0;
}

.RepDetails__info__profile {
  align-items: center;
  padding: 16px 8px;
  display: flex;
}

.RepDetails__info__profile__img {
  width: 104px;
  height: 104px;
  object-fit: cover;
  border-radius: 8px;
}

.RepDetails__info__profile__titles {
  padding: 0 16px;
}

.RepDetails__info__profile__titles h3 {
  color: #111827;
  margin: 0;
  font-size: 20px;
  line-height: 1.5;
}

.RepDetails__info__profile__titles p {
  color: #6b7280;
  margin: 0;
}

.RepDetails__info__contact {
  background-color: #f3f4f6;
  border-radius: 8px;
  margin-top: 16px;
  padding: 8px;
}

.RepDetails__info__contact > div {
  justify-content: space-between;
  align-items: flex-start;
  margin: 8px;
  display: flex;
}

.RepDetails__info__contact > div p:nth-child(2) {
  color: #111827;
}

.RepDetails__info__contact__address span {
  text-align: right;
  color: #111827;
}

.RepDetails__info__repArea {
  white-space: pre-wrap;
  margin-top: 28px;
}

.RepDetails__info__repArea__header {
  justify-content: space-between;
  margin: 4px 8px;
  display: flex;
}

.RepDetails__info__repArea__header p {
  font-weight: 500;
}

.RepDetails__info__repArea__text {
  background-color: #f3f4f6;
  border-radius: 6px;
  padding: 12px 16px;
}

.RepDetails__info__repArea__editing--buttons {
  width: 100%;
  gap: .5rem;
  display: flex;
}

.RepDetails__info__reports {
  margin-top: 28px;
}

.RepDetails__info__reports__header {
  align-items: center;
  margin: 4px 8px;
  display: flex;
}

.RepDetails__info__reports__header p {
  margin-left: 4px;
  font-weight: 500;
}

.RepDetails__info__reports__header svg {
  width: 20px;
}

.RepDetails__info__reports__divider {
  width: 100%;
  height: 1px;
  background-color: #e5e7eb;
  margin: 4px 8px;
}

.RepDetails__info__reports__dates {
  width: 100%;
  gap: .5rem;
  margin: 16px 0 20px;
  display: flex;
}

.RepDetails__info__reports__dates > * {
  flex-grow: 1;
}

.RepDetails__info__reports__dates .DatePicker__container .Input__container {
  width: 100%;
}

.RepDetails__info__reports__list > div {
  justify-content: space-between;
  display: flex;
}

.RepDetails__info__reports__list > div:not(:last-child) {
  margin-bottom: 8px;
}

.RepDetails__info__reports__list > div p {
  color: #6b7280;
}

.RepDetails__tabs {
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.RepDetails__tabs .RepDetails__tab {
  padding: 0 76px 14px;
}

.RepDetails__tabs__container {
  width: 100%;
  border-bottom: 1px solid #e5e7eb;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.DisplayFollowupDrawer {
  display: flex;
  width: 896px !important;
}

.DisplayFollowupDrawer__header {
  background-color: #f3f4f6;
  border-bottom: 1px solid #e5e7eb;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  display: flex;
}

.DisplayFollowupDrawer__header > p {
  color: #111827;
  margin: 0;
  font-size: 18px;
}

.DisplayFollowupDrawer__header > svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #6b7280;
}

.DisplayFollowupDrawer__content {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.DisplayFollowupDrawer__content__left {
  width: 480px;
  height: 100%;
  border-right: 1px solid #e5e7eb;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
  display: flex;
}

.DisplayFollowupDrawer__content__left__action {
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 4px;
}

.DisplayFollowupDrawer__content__left__action > p {
  margin: 0;
  font-weight: 500;
}

.DisplayFollowupDrawer__content__left__span {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.DisplayFollowupDrawer__content__left__span > p {
  margin: 0;
}

.DisplayFollowupDrawer__content__left__span p:has( + .Input__container > textarea) {
  align-self: flex-start;
}

.DisplayFollowupDrawer__content__left__span .Input__container {
  width: 248px !important;
}

.DisplayFollowupDrawer__content__left__button {
  align-self: flex-end;
  margin-top: 8px;
}

.DisplayFollowupDrawer__content__right {
  flex-grow: 1;
  padding: 16px;
  overflow: auto;
}

.DisplayFollowupDrawer__content__right__note {
  width: 100%;
  border-bottom: 1px solid #e5e7eb;
  flex-direction: column;
  padding: 20px 16px;
  display: flex;
}

.DisplayFollowupDrawer__content__right__note__span {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  display: flex;
}

.DisplayFollowupDrawer__content__right__note__span__title {
  color: #111827;
  margin: 0;
  font-weight: 500;
}

.DisplayFollowupDrawer__content__right__note__span__time {
  color: #6b7280;
  margin: 0;
}

.DisplayFollowupDrawer__content__right__note > p {
  color: #4b5563;
  margin: 0 0 8px;
}

.DisplayFollowupDrawer__content__right__note > .Badge {
  align-self: flex-end;
}

.DisplayFollowupDrawer__content__right__info {
  color: #6b7280;
  font-style: italic;
}

.DisplayFollowupDrawer__buttons {
  border-top: 1px solid #e5e7eb;
  justify-content: flex-end;
  padding: 20px 24px;
  display: flex;
}

.RepDetails__tabs .RepDetails__list {
  width: 100%;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  display: flex;
}

.RepDetails__tabs .RepDetails__list__table {
  color: #111827;
}

.RepDetails__tabs .RepDetails__list__table__header {
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  display: flex;
}

.RepDetails__tabs .RepDetails__list__table__header p {
  margin: 0;
}

.RepDetails__tabs .RepDetails__list__table__header p:nth-child(1) {
  font-weight: 500;
}

.RepDetails__tabs .RepDetails__list__table__header p:last-child {
  color: #6b7280;
}

.RepDetails__tabs .RepDetails__list__table table {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 4px #d1d5db;
}

.RepDetails__tabs .RepDetails__list__table button {
  color: #374151;
}

.Reps__groupListDrawer__content__rep {
  align-items: center;
  padding: 8px;
  display: flex;
}

.Reps__groupListDrawer__content__rep:not(:last-child) {
  margin-bottom: 6px;
}

.Reps__groupListDrawer__content__rep__image {
  width: 40px;
  height: 40px;
  background-color: #e5e7eb;
  border-radius: 100px;
  margin-right: 16px;
}

.Reps__groupListDrawer__content__rep a {
  color: #306ce0;
  margin: 0;
  font-weight: 500;
  line-height: 1.5;
}

.Reps__groupListDrawer__content__rep a:hover {
  text-decoration: none;
}

.Reps__groupListDrawer__content__rep p {
  color: #6b7280;
  margin: 0;
  line-height: 1.5;
}

.SalesReports {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.SalesReports p {
  color: #374151;
  margin: 0;
  line-height: 1.5;
}

.SalesReports .nav {
  margin-bottom: 16px;
}

.SalesReports__body {
  flex-grow: 1;
  padding: 24px;
}

.SalesReports__body__header {
  justify-content: space-between;
  margin-bottom: 16px;
  display: flex;
}

.SalesReports__body__header__title h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
}

.SalesReports__body__header__title p {
  color: #6b7280;
  margin-top: 4px;
}

.SalesReports__body__header__filters {
  align-items: center;
  display: flex;
}

.SalesReports__body__header__filters > :not(:last-child) {
  margin-right: 16px;
}

.SalesReports__body__header__filters > div > p {
  margin-bottom: 4px;
}

.SalesReports__body__header__filters > div .Input {
  height: 38px;
}

.SalesReports__body__table {
  flex-direction: column;
  display: flex;
}

.SalesReports__body__table .DataTable th {
  border-top: none;
}

.SalesReports__body__table .DataTable tr:last-child td {
  border-bottom: none;
}

.SalesReports__body__table .DataTable td {
  color: #111827;
  padding: 16px;
}

.SalesReports__body__table .DataTable th:nth-child(n+5), .SalesReports__body__table .DataTable td:nth-child(n+5) {
  text-align: right;
}

.ReportsSplash__content {
  width: 100%;
  flex-direction: column;
  padding: 0 24px;
  display: flex;
}

.ReportsSplash__section {
  align-items: center;
  gap: 32px;
  padding: 32px 0;
  display: flex;
}

.ReportsSplash__section:not(:last-child) {
  border-bottom: 2px solid #e5e7eb;
}

.ReportsSplash__section__contentContainer {
  width: 100%;
  gap: 32px;
  display: flex;
}

.ReportsSplash__section__contentContainer > * {
  width: 264px;
}

.ReportsSplash__section__col {
  flex-direction: column;
  display: flex;
}

.ReportsSplash__section__col h3 {
  color: #111827;
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: 500;
}

.ReportsSplash__section__col > a:not(:last-child) {
  margin: 0 0 4px;
}

.ReportsSplash__section__col--icon {
  width: 216px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  display: flex;
}

.ReportsSplash__section__col--icon > h2 {
  margin: 12px 0 0;
  font-size: 18px;
}

.ReportsSplash__section__col--icon > span {
  width: 48px;
  height: 48px;
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 12px;
  position: relative;
}

.ReportsSplash__section__col--icon > span > svg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ProductsReports {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.ProductsReports p {
  color: #374151;
  margin: 0;
  line-height: 1.5;
}

.ProductsReports .nav {
  margin-bottom: 16px;
}

.ProductsReports__body {
  flex-grow: 1;
  padding: 24px;
}

.ProductsReports__body__header {
  justify-content: space-between;
  margin-bottom: 16px;
  display: flex;
}

.ProductsReports__body__header__title h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
}

.ProductsReports__body__header__title p {
  color: #6b7280;
  margin-top: 4px;
}

.ProductsReports__body__header__filters {
  align-items: center;
  display: flex;
}

.ProductsReports__body__header__filters > :not(:last-child) {
  margin-right: 16px;
}

.ProductsReports__body__header__filters > div > p {
  margin-bottom: 4px;
}

.ProductsReports__body__header__filters > div .Input {
  height: 38px;
}

.ProductsReports__body__table {
  flex-direction: column;
  display: flex;
}

.ProductsReports__body__table .DataTable th {
  border-top: none;
}

.ProductsReports__body__table .DataTable tr:last-child td {
  border-bottom: none;
}

.ProductsReports__body__table .DataTable td {
  color: #111827;
  padding: 8px 16px;
}

.ProductsReports__body__table .ProductsReports__body__infoBanner {
  background-color: #f0f6fe;
  border-radius: 6px;
  align-self: stretch;
  align-items: center;
  padding: 16px;
  display: flex;
}

.ProductsReports__body__table .ProductsReports__body__infoBanner svg {
  width: 20px;
  height: 20px;
  color: #69aaf1;
  margin-right: 12px;
}

.ProductsReports__body__table .ProductsReports__body__infoBanner p {
  color: #2857cd;
}

.ProductionReports {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.ProductionReports p {
  color: #374151;
  margin: 0;
  line-height: 1.5;
}

.ProductionReports .nav {
  margin-bottom: 16px;
}

.ProductionReports__body {
  flex-direction: column;
  flex-grow: 1;
  padding: 24px;
  display: flex;
}

.ProductionReports__body__header {
  justify-content: space-between;
  margin-bottom: 16px;
  display: flex;
}

.ProductionReports__body__header__title h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
}

.ProductionReports__body__header__title p {
  color: #6b7280;
  margin-top: 4px;
}

.ProductionReports__body__header__filters {
  align-items: center;
  display: flex;
}

.ProductionReports__body__header__filters > :not(:last-child) {
  margin-right: 16px;
}

.ProductionReports__body__header__filters .Input {
  height: 38px;
}

.ProductionReports__body__table {
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  display: flex;
}

.ProductionReports__body__table .DataTable th {
  border-top: none;
}

.ProductionReports__body__table .DataTable tr:last-child td {
  border-bottom: none;
}

.ProductionReports__body__table .DataTable td {
  color: #111827;
  padding: 16px;
  font-size: 14px;
}

.ProductionReports__body__table__light {
  color: #374151 !important;
}

.ProductionReports__body__table__compact > td {
  padding: 8px 16px !important;
}

.ProductionReports__productionSummaryTotals {
  background-color: #fff;
  padding: 8px 24px 24px;
  position: sticky;
  bottom: 0;
}

.ProductionReports__productionSummaryTotals__content {
  border-top: 1px solid #e5e7eb;
  justify-content: space-between;
  display: flex;
  background-color: #f9fafb !important;
}

.ProductionReports__productionSummaryTotals__content > span {
  width: 0;
  color: #111827;
  flex-grow: 1;
  padding: 16px;
  font-size: 16px;
}

.ProductionReports__columnEqualizer .DataTable th, .ProductionReports__columnEqualizer .DataTable td {
  width: 16.6667% !important;
}

.ReportsNav {
  align-items: center;
  display: flex;
}

.ReportsNav > p {
  color: #d1d5db;
  margin: 0 0 0 16px;
  font-size: 36px;
  font-weight: 100;
}

.ReportsNav > a, .ReportsNav > div > a {
  color: #6b7280;
  align-items: center;
  font-weight: 500;
  display: flex;
}

.ReportsNav > a:hover, .ReportsNav > div > a:hover {
  text-decoration: none;
}

.ReportsNav > a svg, .ReportsNav > div > a svg {
  width: 12px;
  height: 12px;
  margin: 0 0 0 8px;
}

.ReportsNav__popover {
  gap: 1rem;
  display: flex;
  padding: 24px !important;
}

.ReportsNav__popover__col {
  width: 260px;
  flex-direction: column;
  display: flex;
}

.ReportsNav__popover__col__title {
  color: #111827;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.ReportsNav__popover__col__subtitle {
  color: #6b7280;
  margin: 0;
  font-size: 14px;
  display: none;
}

.ReportsNav__popover__col > :not(:last-child) {
  margin: 0 0 8px;
}

.ReportsNav > :not(:first-child) {
  margin-left: 28px;
}

.ReportsNav__activeLink, .ReportsNav__activeLink > svg {
  color: #244084 !important;
}

.ProductHistory {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
}

.ProductHistory p {
  color: #374151;
  margin: 0;
  line-height: 1.5;
}

.ProductHistory__header {
  width: 100%;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 16px;
}

.ProductHistory__header > p {
  padding-bottom: 13px;
  font-size: 18px;
  font-weight: 500;
}

.ProductHistory__table {
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  overflow: hidden;
}

.ProductHistory__table thead {
  border-top: none !important;
}

.ProductHistory__table tbody tr {
  background-color: #fff !important;
}

.ProductHistory__table tbody tr:last-child {
  border-bottom: none !important;
}

.ProductDetails {
  width: 100%;
  height: 100vh;
  flex-direction: column;
  display: flex;
  overflow: auto;
}

.ProductDetails p {
  color: #374151;
  margin: 0;
  line-height: 1.5;
}

.ProductDetails__keyValueSpan {
  justify-content: space-between;
  display: flex;
}

.ProductDetails__keyValueSpan > p:nth-child(2) {
  text-align: right;
}

.ProductDetails__keyValueSpan:not(:last-child) {
  margin-bottom: 8px;
}

.ProductDetails__keyValueSpan--bold > p:first-child {
  font-weight: 500;
}

.ProductDetails__keyValueSpan--bold > p:nth-child(2), .ProductDetails__keyValueSpan--dark > p:nth-child(2) {
  color: #111827;
}

.ProductDetails__warningButton {
  color: #6d1d20 !important;
  background-color: #fbf0ea !important;
}

.ProductDetails__warningBanner {
  width: 100%;
  background-color: #fefce8;
  padding: 16px;
}

.ProductDetails__warningBanner > span {
  align-items: center;
  margin-bottom: 4px;
  display: flex;
}

.ProductDetails__warningBanner > span > svg {
  color: #eab308;
  width: 16px;
  margin-right: 1rem;
}

.ProductDetails__warningBanner > span > p {
  color: #854d0e;
  font-weight: 500;
}

.ProductDetails__warningBanner > p {
  color: #a16207;
  margin-left: 32px;
}

.ProductDetails__cancelledBanner {
  background-color: #fefce8;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.ProductDetails__cancelledBanner > button {
  margin-right: 25px;
  padding: 5px 9px !important;
}

.ProductDetails__info {
  margin-bottom: 64px;
}

.ProductDetails__info__header {
  width: 300px;
  justify-content: space-between;
  display: flex;
}

.ProductDetails__info__header > p {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 500;
}

.ProductDetails__info__content {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.ProductDetails__info__content > div {
  width: 300px;
}

.ProductDetails__info__productInfo {
  flex-direction: column;
  display: flex;
}

.ProductDetails__info__productInfo__content {
  background-color: #f3f4f6;
  border-radius: 6px;
  flex-direction: column;
  padding: 8px;
  display: flex;
}

.ProductDetails__info__notes {
  flex-direction: column;
  align-self: stretch;
  display: flex;
}

.ProductDetails__info__notes__header {
  justify-content: space-between;
  display: flex;
}

.ProductDetails__info__notes__header > p {
  margin: 4px 8px;
  font-weight: 500;
  line-height: 1.5;
}

.ProductDetails__info__notes__content {
  background-color: #f3f4f6;
  border-radius: 6px;
  flex-grow: 1;
  padding: 12px 16px;
}

.ProductDetails__info__notes__editing > span {
  align-items: center;
  display: flex;
}

.ProductDetails__info__notes__editing > span > * {
  padding: 5px 0 !important;
}

.ProductDetails__info__notes__editing > span > :last-child {
  margin-left: 16px;
  color: #2857cd !important;
  background-color: #f0f6fe !important;
}

.ProductDetails__art {
  flex-grow: 1;
  align-items: flex-start;
  display: flex;
}

.ProductDetails__artWork {
  width: 530px;
  flex-direction: column;
  margin-right: 64px;
  display: flex;
}

.ProductDetails__artWork__header {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.ProductDetails__artWork__header > p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.ProductDetails__artWork__artCard {
  background-color: #f3f4f6;
  border-radius: 4px;
  flex-direction: column;
  padding: 16px;
  display: flex;
}

.ProductDetails__artWork__artCard:not(:last-child) {
  margin-bottom: 16px;
}

.ProductDetails__artWork__artCard p {
  color: #1f2937;
  font-size: 14px;
}

.ProductDetails__artWork__artCard__title {
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 10px;
}

.ProductDetails__artWork__artCard__title > p:nth-child(2) {
  color: #4b5563;
  font-size: 12px;
}

.ProductDetails__artWork__artCard__content {
  display: flex;
}

.ProductDetails__artWork__artCard__content > div {
  flex-direction: column;
  display: flex;
}

.ProductDetails__artWork__artCard__content > div:not(:last-child) {
  margin-right: 16px;
}

.ProductDetails__artWork__artCard__content > div > :last-child {
  background-color: #fff;
  margin-top: 10px;
}

.ProductDetails__artWork__artCard__content__imgContainer {
  width: 240px;
  height: 240px;
  background-color: #e5e7eb;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ProductDetails__artWork__artCard__content__imgContainer > img {
  width: auto;
  height: auto;
  max-width: 240px;
  max-height: 240px;
}

.ProductDetails__artInfo {
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.ProductDetails__artInfo__notes__header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.ProductDetails__artInfo__notes__header > p {
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 500;
}

.ProductDetails__artInfo__notes__card {
  border-radius: 8px;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  padding: 34px 20px;
  display: grid;
  box-shadow: 0 1px 2px #0000000f, 0 1px 3px #0000001a;
}

.ProductDetails__artInfo__notes__card__column {
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  display: flex;
}

.ProductDetails__artInfo__notes__card__valueSpan {
  min-width: 142px;
}

.ProductDetails__artInfo__notes__card__valueSpan:not(:last-child) {
  margin-bottom: 8px;
}

.ProductDetails__artInfo__notes__card__valueSpan > p:first-child {
  color: #6b7280;
  margin-bottom: 4px;
}

.ProductDetails__artInfo__notes__card__valueSpan > p:nth-child(2) {
  color: #111827;
}

.ProductDetails__artInfo__notes__card:not(:last-child) {
  margin-bottom: 16px;
}

.ProductDetails__artInfo__notes__card:last-child {
  margin-bottom: 40px;
}

.ProductDetails__artInfo__notes__moreInfo {
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
  display: flex;
}

.ProductDetails__artInfo__notes__moreInfo > div {
  max-width: 50%;
  flex-grow: 1;
}

.ProductDetails__artInfo__notes__moreInfo > div:not(:last-child) {
  margin-right: 16px;
}

.ProductDetails__artInfo__notes__moreInfo > div > p {
  padding: 4px 8px;
  font-weight: 500;
}

.ProductDetails__artInfo__notes__moreInfo > div > div {
  white-space: pre-wrap;
  background-color: #f3f4f6;
  border-radius: 6px;
  padding: 12px 16px;
}

.ProductsList__filters {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.ProductsList__filters > :first-child .Input {
  width: 300px;
}

.ProductsList__table tr {
  height: 100%;
}

.ProductsList__table__image > div {
  background-color: none;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.ProductsList__table__image > div img {
  max-width: none;
}

.PayrollSummary__filters {
  gap: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
}

.PayrollSummary__filters__spacer {
  flex: 1;
}

.PayrollSummary__filter label {
  margin-bottom: 4px;
  font-weight: 600;
  display: block;
}

@page {
  size: landscape;
}

.PrintSummary {
  color: #374151;
  margin: 64px 0;
}

.PrintSummary__header {
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 24px;
  display: flex;
}

.PrintSummary__header__overview {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.PrintSummary__header__overview h2 {
  color: #1f2937;
  margin: 0 0 40px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}

.PrintSummary__header__overview__info {
  font-weight: 400;
}

.PrintSummary__header__overview__info h3 {
  margin: 0 0 8px;
  font-size: 24px;
  line-height: 1.2;
}

.PrintSummary__header__overview__info p {
  color: #4b5563;
  margin: 0 0 4px;
  font-size: 16px;
  line-height: 1.5;
}

.PrintSummary__header__qrInfo {
  max-width: 300px;
  border: 1px solid #d1d5db;
  padding: 16px 24px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
}

.PrintSummary__header__qrInfo p {
  margin: 0;
}

.PrintSummary__header__qrInfo p:not(:last-child) {
  margin: 0 0 16px;
}

.PrintSummary__table {
  border-collapse: collapse;
  table-layout: auto;
  flex-grow: 1;
}

.PrintSummary__table thead, .PrintSummary__table tr {
  border: none;
}

.PrintSummary__table thead {
  border-bottom: 1px solid #d1d5db;
}

.PrintSummary__table thead th {
  text-transform: uppercase;
  text-align: left;
  white-space: nowrap;
  padding: 10px 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
}

.PrintSummary__table thead th:first-child {
  min-width: 50px;
}

.PrintSummary__table thead th:not(:first-child, :last-child) {
  min-width: 100px;
}

.PrintSummary__table tbody tr:nth-child(2n-1) {
  background-color: #f9fafb;
}

.PrintSummary__table tbody td {
  white-space: nowrap;
  padding: 5px 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
}

.PrintSummary__table tbody td label {
  margin: 0;
}

.PrintSummary__table tbody td label span {
  background-color: #fff;
  border-radius: 4px;
  outline: 1px solid #d1d5db;
}

.PrintSummary__table tbody td:last-child {
  width: 100%;
}

.PrintSummary__jobTable {
  width: 100%;
  margin-bottom: 24px;
  display: flex;
}

.PrintSummary__jobTable__tasks {
  display: flex;
}

.PrintSummary__jobTable__tasks > div:not(:last-child) {
  margin-right: 32px;
}

.PrintSummary__materialsTable {
  justify-content: flex-end;
  display: flex;
}

.PrintSummary__materialsTable__table {
  width: 30%;
  table-layout: auto;
  flex-grow: 0;
}

.PrintSummary__materialsTable__table thead th {
  padding: 2px 16px;
}

.PrintSummary__materialsTable__table tbody tr:nth-child(2n) {
  background-color: #f9fafb;
}

.PrintSummary__materialsTable__table tbody tr:nth-child(2n-1) {
  background-color: #fff;
}

.PrintSummary__materialsTable__table tbody td {
  white-space: nowrap;
}

.PrintSummary__actions {
  padding: 2rem 0;
  display: flex;
  position: fixed;
  bottom: 0;
}

.PrintSummary__actions button {
  margin-right: 2rem;
}

@media print {
  .AdminLayout__sidebar, .Toaster, .nav, .PrintSummary__actions {
    display: none;
  }
}

.Jobs__inlineButtons {
  margin-bottom: 2.5rem;
  display: flex;
}

.JobDetail__header {
  align-items: center;
  margin: 3rem 0 2rem;
  display: flex;
}

.JobDetail__header__logo {
  margin-right: 1.5rem;
}

.JobDetail__header__title {
  margin: 0;
}

.JobDetail__header__assignee {
  color: #4b5563;
  margin: 8px 0 0;
  font-size: 18px;
}

.JobDetail__status {
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
}

.JobDetail__status__column {
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 1.5rem;
  display: flex;
}

.JobDetail__status__column:not(:last-of-type) {
  border-right: 1px solid #f3f4f6;
}

.JobDetail__status__field {
  margin-bottom: 4px;
}

.JobDetail__status__field label {
  color: #111827;
  font-size: 16px;
}

.JobDetail__status__field span {
  color: #4b5563;
  font-size: 16px;
}

.JobDetail__table {
  border-collapse: collapse;
  width: 100%;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 1.5rem;
}

.JobDetail__table--sm {
  width: 80px;
}

.JobDetail__table--md {
  width: 125px;
}

.JobDetail__table--lg {
  width: 200px;
}

.JobDetail__table thead th {
  color: #6b7280;
  text-align: left;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 500;
}

.JobDetail__table tbody .alignCenter {
  text-align: center;
}

.JobDetail__table tbody input {
  width: 100px;
}

.JobDetail__table tbody .Checkbox__container {
  display: initial;
  text-align: center;
  margin-bottom: 0;
}

.JobDetail__table tbody tr td {
  color: #111827;
  padding: 8px 12px 16px;
  font-size: 14px;
}

.JobDetail__table__badges .Badge {
  display: inline-block;
}

.JobDetail__table__badges .Badge:not(:last-of-type) {
  margin-right: 1rem;
}

.JobDetail__notificationSent {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.JobDetail-buttonHolder {
  padding: 2rem 4rem;
  position: fixed;
  bottom: 0;
}

.JobDetail-buttonBottom {
  margin-right: 2rem;
}

.JobDetail__deleteModal h3 {
  margin: 0;
}

.JobDetail__deleteModal p {
  margin: 2rem 0;
}

.CreateJob__header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.CreateJob form {
  padding-bottom: 6rem;
}

.CreateJob__inline {
  align-items: center;
  margin: 3rem 0;
  display: flex;
}

.CreateJob__inline__spacer {
  flex: 1;
}

.CreateJob__inline__group {
  flex-direction: column;
  display: flex;
}

.CreateJob__inline__group:not(:last-of-type) {
  margin-right: 1rem;
}

.CreateJob .centered {
  text-align: center;
}

.CreateJob__tasksEmpty {
  color: #6b7280;
  border: 2px solid #e5e7eb;
  border-radius: 4px;
  margin-bottom: 2rem;
  padding: 1rem 2rem;
}

.CreateJob__task {
  border-collapse: collapse;
  width: 100%;
  border-bottom: 2px solid #f3f4f6;
  margin-bottom: 1rem;
}

.CreateJob__task--sm {
  width: 50px;
}

.CreateJob__task--md {
  width: 100px;
}

.CreateJob__task--lg {
  width: 200px;
}

.CreateJob__task thead tr th {
  color: #6b7280;
  text-align: left;
  text-transform: uppercase;
  padding: 0 1rem;
  font-size: 12px;
  font-weight: 400;
}

.CreateJob__task tbody tr td {
  padding: 0 1rem 1rem;
}

.CreateJob__task__badges .Badge {
  display: inline-block;
}

.CreateJob__task__badges .Badge:not(:last-of-type) {
  margin-right: 1rem;
}

.CreateJob__buttonGroup {
  width: 100%;
  background-color: #fff;
  padding: 1rem 1.5rem;
  position: fixed;
  bottom: 0;
}

.CreateJob__buttonGroup button:not(:last-child) {
  margin-right: 1rem;
}

.Availability__inlineButtons {
  margin-bottom: 2.5rem;
  display: flex;
}

.Availability__viewAll {
  justify-content: flex-end;
  margin-bottom: 1rem;
  display: flex;
}

.Availability__inlineTableCell {
  align-items: center;
  gap: 8px;
  display: flex;
}

.VerifyTask__confirmed {
  color: #15803d;
}

.VerifyTask__quantityInput {
  height: 28px;
}

.ProfileDrawer__user {
  align-items: center;
  display: flex;
}

.ProfileDrawer__user__image {
  height: 104px;
  width: 104px;
  border-radius: 8px;
  margin-right: 1rem;
}

.ProfileDrawer__user__info {
  flex: 1;
}

.ProfileDrawer__user__info__name {
  margin: 0 0 1rem;
  font-size: 24px;
  font-weight: 700;
}

.ProfileDrawer__tabs {
  display: flex;
}

.ProfileDrawer__general__label {
  color: #6b7280;
  font-size: 14px;
}

.ProfileDrawer__general__contact__row {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.ProfileDrawer__general__contact__row label {
  color: #6b7280;
  flex: 1;
  font-size: 14px;
  line-height: 1.5;
}

.ProfileDrawer__general__contact__row p {
  color: #111827;
  flex: 2;
  font-size: 14px;
  line-height: 1.5;
}

.ProfileDrawer__general__updateImage {
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0 2rem;
  display: flex;
}

.ProfileDrawer__general__updateImage img {
  height: 48px;
  width: 48px;
  border-radius: 100%;
  margin-right: 1rem;
}

.ProfileDrawer__general__trained__header {
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  display: flex;
}

.ProfileDrawer__general__trained__checkboxes {
  display: flex;
}

.ProfileDrawer__general__trained__checkboxes .Checkbox__container:not(:last-of-type) {
  margin-right: 3.5rem;
}

.ProfileDrawer__notes__note__date {
  color: #6b7280;
  font-size: 12px;
}

.ProfileDrawer__notes__note__text {
  color: #4b5563;
  margin: 0;
  padding: 0;
}

.ProfileDrawer__notes__note:not(:last-of-type) {
  border-bottom: 1px solid #e9ecef;
}

.JobTaskDrawer label {
  display: block;
}

.JobTaskDrawer .divider {
  background-color: #f3f4f6;
  margin: 1.5rem 0;
}

.JobTaskDrawer__inputField {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.JobTaskDrawer__inputField label {
  color: #374151;
}

.JobTaskDrawer__toggleField {
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.JobTaskDrawer__toggleField label {
  color: #374151;
  margin-right: 1rem;
}

.JobTaskDrawer__toggleField .Checkbox__container {
  margin-bottom: 0;
}

.JobTaskDrawer__prices {
  border-top: 1px solid #e5e7eb;
  padding: 0 1.5rem 0 3rem;
  position: relative;
}

.JobTaskDrawer__prices__expanded {
  flex-direction: column;
  align-items: flex-end;
  padding: .5rem 0;
  display: flex;
}

.JobTaskDrawer__prices__expand {
  visibility: hidden;
  top: 8px;
  left: 8px;
  width: 24px !important;
  max-width: 24px !important;
  min-width: 24px !important;
  height: 24px !important;
  max-height: 24px !important;
  min-height: 24px !important;
  position: absolute !important;
}

.JobTaskDrawer__prices__expand svg {
  width: 16px !important;
}

.JobTaskDrawer__prices:hover .JobTaskDrawer__prices__expand {
  visibility: visible;
}

.JobTaskDrawer__prices__row {
  width: 224px;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0;
  display: flex;
}

.JobTaskDrawer__prices__row label {
  color: #6b7280;
  font-size: 12px;
}

.JobTaskDrawer__prices__row .value {
  color: #374151;
  margin: 0;
}

.JobTaskDrawer__prices__subtotal {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.JobTaskDrawer__prices__subtotal label {
  color: #374151;
  font-weight: 500;
}

.JobTaskDrawer__prices__subtotal .value {
  color: #111827;
  margin: .5rem 0;
  font-size: 16px;
}

.PaymentConfirmation .buttonGroup {
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
  display: flex;
}

.PaymentApproval__subHeader {
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
}

.PaymentApproval__subHeader button {
  margin-right: 1rem;
}

.PaymentApproval__subHeader__text {
  font-size: 14px;
  font-weight: 600;
}

.PaymentApproval__jobsList {
  border-top: 1px solid #e9ecef;
}

.PaymentApproval__job {
  border-bottom: 1px solid #e9ecef;
  padding: 1rem;
}

.PaymentApproval__job__header {
  color: #3981ea;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  display: flex;
}

.PaymentApproval__job__row {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  font-size: 14px;
  display: flex;
}

.PaymentApproval__job__row label {
  color: #6b7280;
}

.PaymentApproval__jobDetail h1 {
  font-size: 32px;
  font-weight: 600;
}

.PaymentApproval__jobDetail__total {
  background-color: #f3f4f6;
  border-radius: 6px;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
}

.PaymentApproval__jobDetail__total__icon {
  margin-right: 1rem;
}

.PaymentApproval__jobDetail__total h4 {
  margin: 0;
}

.PaymentApproval__jobDetail__card {
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: .75rem 1rem;
  box-shadow: 0 1px 2px #0000000f, 0 1px 3px #0000001a;
}

.PaymentApproval__jobDetail__task label {
  color: #6b7280;
  margin-right: 4px;
  font-size: 14px;
}

.PaymentApproval__jobDetail__task__row {
  justify-content: space-between;
  display: flex;
}

.PaymentApproval__jobDetail__task__field {
  display: flex;
}

.PaymentApproval__jobDetail__task__badges {
  gap: 8px;
  margin-top: 4px;
  display: flex;
}

.EditApprovalDate__content {
  padding: 2rem;
}

.EditApprovalDate__field {
  margin-bottom: 1.5rem;
}

.EditApprovalDate__field label {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
}

.EditApprovalDate__actions {
  border-top: 1px solid #e9ecef;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.Orders__filters {
  align-items: flex-end;
  gap: 16px;
  margin-bottom: 1.5rem;
  display: flex;
}

.Orders__filters label {
  margin-bottom: 4px;
  display: block;
}

.Orders__filterOrViewPopover {
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.Orders__filterOrViewPopover__options {
  z-index: 100;
  background-color: #fff;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  align-items: center;
  gap: 1rem;
  padding: 12px 16px;
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.Orders__filterOrViewPopover__options a {
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-size: 20px;
  display: block;
}

.Orders__filterOrViewPopover:hover > .Orders__filterOrViewPopover__options {
  display: flex;
}

.OrderDetailComponentWrapper {
  height: 100vh;
  flex-direction: column;
  display: flex;
}

.OrderDetailGrayBackground {
  background-color: #f3f4f6;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px;
  display: flex;
}

.OrderDetail {
  flex-grow: 1;
  gap: 1rem;
  display: flex;
  padding: 0 !important;
}

.OrderDetail__card {
  background-color: #fff;
  border-radius: 8px;
  align-self: flex-start;
  display: flex;
  padding: 16px !important;
}

.OrderDetail__card--info {
  flex: 0 0 352px;
}

.OrderDetail__card--main {
  flex: 1;
}

.OrderDetail__info {
  width: 100%;
}

.OrderDetail__account {
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 1rem;
}

.OrderDetail__account__header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: .75rem;
  display: flex;
}

.OrderDetail__account__field {
  justify-content: space-between;
  display: flex;
}

.OrderDetail__account__field__label {
  color: #374151;
}

.OrderDetail__account__field__value {
  color: #111827;
  text-align: right;
}

.OrderDetail__account__field:not(:last-of-type) {
  margin-bottom: .5rem;
}

.OrderDetail__viewNotes {
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  gap: .5rem;
  margin: .5rem 0 1.5rem;
  padding: .5rem;
  display: flex;
}

.OrderDetail__viewNotes__label {
  color: #306ce0;
  margin-right: 4px;
}

.OrderDetail__additional {
  margin-bottom: 1.5rem;
  padding: .5rem;
  position: relative;
}

.OrderDetail__additional__field {
  justify-content: space-between;
  display: flex;
}

.OrderDetail__additional__field__label {
  color: #6b7280;
}

.OrderDetail__additional__field__value {
  color: #111827;
}

.OrderDetail__additional__field:not(:last-of-type) {
  margin-bottom: 4px;
}

.OrderDetail__additional__modalButton {
  width: 30px;
  height: 30px;
  color: #69aaf1;
  opacity: 0;
  background-color: #f0f6fe;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 6px;
  right: 6px;
}

.OrderDetail__additional__modalButton a {
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.OrderDetail__additional__modalButton a:hover {
  text-decoration: none;
}

.OrderDetail__additional__modalButton svg {
  width: 16px;
}

.OrderDetail__additional:hover .OrderDetail__additional__modalButton {
  opacity: 1;
}

.OrderDetail__invoiceDetails {
  width: 100%;
  border-radius: 4px;
  padding: .5rem;
  box-shadow: 0 1px 2px #0000000f, 0 1px 3px #0000001a;
}

.OrderDetail__invoiceDetails__header {
  justify-content: space-between;
  display: flex;
}

.OrderDetail__invoiceDetails label {
  color: #6b7280;
}

.OrderDetail__invoiceDetails__dates {
  gap: 2rem;
  margin: 1.5rem 0;
  display: flex;
}

.OrderDetail__invoiceDetails__dates__green {
  color: #15803d;
  margin: 0;
  line-height: 1.5;
}

.OrderDetail__invoiceDetails__totals {
  gap: 2rem;
  margin: 1.5rem 0;
  display: flex;
}

.OrderDetail__main {
  flex-direction: column;
  flex-grow: 1;
  gap: 2rem;
  display: flex;
}

.OrderDetail__main__header {
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.OrderDetail__main__content {
  flex-direction: column;
  flex-grow: 1;
  gap: 2rem;
  display: flex;
}

.OrderDetail__main__buttons {
  border-top: 1px solid #e5e7eb;
  justify-content: space-between;
  gap: 1rem;
  padding: .5rem 0;
  display: flex;
}

.OrderDetail__filters {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.OrderDetail__filters__search {
  position: relative;
}

.OrderDetail__filters__spacer {
  flex: 1;
}

.ReorderModal__body {
  justify-content: space-evenly;
  margin: 24px 0;
}

.ReorderModal__column {
  width: 216px;
  gap: 8px;
}

.ReorderModal__column p {
  text-align: center;
  margin: 0;
  font-size: 12px;
}

.ReorderModal__modalGreyHeader {
  border-bottom: 1px solid #e5e7eb;
  align-items: center;
  gap: 15px;
}

.ReorderModal__modalGreyHeader p {
  margin-left: 20px;
}

.ReorderModal__inputs {
  align-items: center;
}

.ReorderModal__inputs input {
  width: 38px;
  height: 29px;
  border-radius: 4px;
}

.ReorderModal__inputs label {
  margin: 0 8px;
  font-size: 14px;
}

.ReorderModal__inputHolder {
  flex-wrap: wrap;
  gap: 8px 24px;
}

.ReorderModal .modalButtonEnd {
  border-top: 1px solid #e5e7eb;
}

.ReorderModal .modalButtonEnd button {
  margin: 24px;
}

.OrderNotesDrawer {
  height: 100%;
  max-height: 100vh;
  flex-direction: column;
  display: flex;
}

.OrderNotesDrawer__header {
  background-color: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
  flex: none;
  align-items: center;
  padding: 16px 24px;
  display: flex;
}

.OrderNotesDrawer__header__title p {
  color: #111827;
  flex-direction: column;
  margin: 0 0 4px;
  font-size: 18px;
  display: flex;
}

.OrderNotesDrawer__header__title label {
  color: #6b7280;
  font-size: 14px;
  font-weight: normal;
}

.OrderNotesDrawer__header button span {
  color: #6b7280;
  font-size: 20px;
}

.OrderNotesDrawer__body {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.OrderNotesDrawer__body__left {
  width: 50%;
  padding: 1rem;
}

.OrderNotesDrawer__body__right {
  width: 50%;
  border-left: 1px solid #e5e7eb;
  padding: 1rem;
  overflow-y: auto;
}

.OrderNotesDrawer__body__noteBox {
  padding: 1rem;
}

.OrderNotesDrawer__body__noteBox:not(:last-child) {
  border-bottom: 1px solid #e5e7eb;
}

.OrderNotesDrawer__body__noteBox__header {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.OrderNotesDrawer__body__noteBox__header button {
  padding: 4px !important;
}

.OrderNotesDrawer__body__noteBox label {
  color: #442267;
  height: 22px;
  background-color: #f1effa;
  border-radius: 2px;
  align-self: center;
  margin-right: 16px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 500;
}

.OrderNotesDrawer__body__noteBox label:first-child {
  margin-bottom: 8px;
}

.OrderNotesDrawer__body__noteBox p {
  color: #111827;
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}

.OrderNotesDrawer__body__noteBox p:not(:last-child) {
  margin-bottom: 8px;
}

.OrderNotesDrawer__body__noteBox span {
  color: #6b7280;
  font-size: 14px;
}

.OrderNotesDrawer__body__noteBox h5 {
  margin: 0 0 8px;
  font-size: 14px;
  font-weight: 500;
}

.OrderNotesDrawer__body__noteBox__inputButtons {
  justify-content: right;
  align-items: center;
  display: flex;
}

.OrderNotesDrawer__body__noteBox__inputButtons :not(:last-child) {
  margin-right: 16px;
}

.OrderNotesDrawer__body__input {
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.OrderNotesDrawer__body__input .orderDrawerTextArea {
  height: 80px;
  width: 336px;
}

.OrderNotesDrawer__body__input p {
  color: #374151;
  margin: 0 0 8px;
  font-size: 14px;
  line-height: 1.5;
}

.OrderNotesDrawer__body__input button {
  align-self: flex-end;
}

.OrderNotesDrawer__buttons {
  border-top: 1px solid #e9ecef;
  flex: none;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
  display: flex;
}

.OrderNotesDrawer__buttons button:not(:last-of-type) {
  margin-right: 1rem;
}

.orderInfoModal {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.orderInfoModal__modal .Modal__close {
  display: none;
}

.orderInfoModal .modalGreyHeader {
  height: 64px;
  background-color: #f3f4f6;
  border-radius: 8px 8px 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.orderInfoModal .modalGreyHeader p {
  margin: 0;
  font-size: 18px;
}

.orderInfoModal__body {
  flex-direction: column;
  gap: 16px;
  margin: 16px 24px 24px;
  display: flex;
}

.orderInfoModal__body select, .orderInfoModal__body input {
  width: 180px;
}

.orderInfoModal__body label {
  color: #374151;
  align-self: center;
  font-size: 14px;
}

.orderInfoModal__input {
  text-align: right;
}

.modalButtonEnd {
  border-top: 1px solid #e5e7eb;
  justify-content: end;
  display: flex;
}

.modalButtonEnd button {
  margin: 20px 20px 20px 0;
  padding: 8px 24px !important;
}

.modalButtonEnd button:not(:last-child) {
  margin-right: 16px;
}

.RepPopover {
  display: flex;
}

.RepPopover__name {
  margin-bottom: 2rem;
  font-weight: 500;
}

.RepPopover__actions {
  margin-top: 1.5rem;
}

.RepPopover__actions a {
  display: block;
}

.RepPopover__actions a:not(:last-of-type) {
  margin-bottom: 1rem;
}

.RepPopover__actions button {
  justify-content: center;
  align-items: center;
  display: flex !important;
}

.PaymentsPopover__header {
  color: #111827;
  font-size: 16px;
  line-height: 1.5;
}

.PaymentsPopover table thead, .PaymentsPopover table tbody tr {
  border: none;
}

.PaymentsPopover__editButton {
  padding: 8px 16px !important;
  font-weight: 400 !important;
}

.PaymentsPopover__addButton {
  margin-top: 16px;
  background-color: #f0f6fe !important;
  padding: 8px 16px !important;
}

.ShippingPreferences__header {
  color: #111827;
  text-align: center;
  background-color: #f9fafb;
  padding: 1.25rem 0;
  font-size: 18px;
}

.ShippingPreferences__body {
  padding: 1rem 2.5rem;
}

.ShippingPreferences__field {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.ShippingPreferences__field label {
  color: #374151;
}

.ShippingPreferences__field input, .ShippingPreferences__field select {
  width: 152px !important;
}

.ShippingPreferences__actions {
  border-top: 1px solid #e5e7eb;
  justify-content: end;
  padding: 1.25rem 1.5rem;
  display: flex;
}

.NonDisplayTable {
  width: 100%;
}

.NonDisplayTable__colgroup__medium {
  width: 20%;
}

.NonDisplayTable__colgroup__grow {
  width: 50%;
}

.NonDisplayTable__colgroup__input {
  min-width: 130px;
  width: -moz-fit-content;
  width: fit-content;
}

.NonDisplayTable .OrderDetails__orderDetailsTable thead tr th {
  z-index: 99;
  min-width: max-content;
  background-color: #fff;
  border-top: none;
}

.NonDisplayTable .OrderDetails__orderDetailsTable tbody tr {
  background-color: #fff;
}

.NonDisplayTable .OrderDetails__orderDetailsTable tbody tr td {
  border-bottom: none;
}

.NonDisplayTable .OrderDetails__orderDetailsTable__inputTd {
  padding: 3.5px 8px !important;
}

.NonDisplayTable .OrderDetails__orderDetailsTable__iconButton {
  height: 32px !important;
  min-height: 32px !important;
  max-height: 32px !important;
  width: 32px !important;
  min-width: 32px !important;
  max-width: 32px !important;
}

.NonDisplayTable .OrderDetails__orderDetailsTable__iconButton svg {
  height: 20px;
  width: 20px;
}

.ProductPreview {
  align-items: center;
  gap: 1.5rem;
  display: flex;
}

.InvoiceNotes {
  justify-content: center;
  display: flex;
}

.InvoiceNotes .Modal__content {
  height: min-content;
  width: 500px;
  padding: 0;
}

.InvoiceNotes .Modal__content .IconButton {
  color: #6b7280 !important;
}

.InvoiceNotes__header {
  height: 64px;
  width: 100%;
  background-color: #f9fafb;
  justify-content: center;
  align-items: center;
  display: flex;
}

.InvoiceNotes__body {
  flex-direction: column;
  padding: 16px 24px;
  display: flex;
}

.InvoiceNotes__body__warning {
  width: 100%;
  background-color: #fefce8;
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
}

.InvoiceNotes__body__warning p {
  margin: 0;
}

.InvoiceNotes__body__warning__logo {
  margin-right: 12px;
}

.InvoiceNotes__body__warning__logo svg {
  color: #facc15;
  width: 24px;
}

.InvoiceNotes__body__warning__content__bold {
  color: #854d0e;
  font-weight: 500;
  line-height: 1.5;
}

.InvoiceNotes__body__warning__content__standard {
  color: #a16207;
  line-height: 1.5;
}

.InvoiceNotes__buttons {
  border-top: 1px solid #e5e7eb;
  justify-content: right;
  align-items: center;
  padding: 20px 24px;
  display: flex;
}

.InvoiceNotes__buttons button {
  padding: 8px 24px !important;
}

.InvoiceNotes__buttons button:not(:last-child) {
  margin-right: 16px;
}

.InvoiceDetailsModal hr {
  border: 1px solid #e5e7eb;
  margin: 32px 0;
}

.InvoiceDetailsModal .Modal__close {
  display: none;
}

.InvoiceDetailsModal .Modal__content {
  height: auto;
  max-width: 820px;
  padding: unset;
}

.InvoiceDetailsModal .modalHeader {
  background-color: #f9fafb;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  display: flex;
}

.InvoiceDetailsModal .modalHeader .font-size-18 {
  padding: 19px 0;
}

.InvoiceDetailsModal .modalBody {
  padding: 32px 20px 48px;
}

.InvoiceDetailsModal .modalBody .inputForm {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.InvoiceDetailsModal__incompleteRow {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 24px;
  display: grid;
}

.InvoiceDetailsModal__buttons button {
  padding: 4px 16px !important;
}

.InvoiceDetailsModal__payments .inputForm input {
  text-align: right;
}

.InvoiceDetailsModal__cancelledFields {
  grid-template-columns: repeat(3, 1fr);
  align-items: last baseline;
  gap: 24px;
  display: grid;
}

.DisplayTable {
  width: 100%;
}

.DisplayTable__colgroup__medium {
  width: 20%;
}

.DisplayTable__colgroup__grow {
  width: 50%;
}

.DisplayTable__colgroup__input {
  min-width: 130px;
  width: -moz-fit-content;
  width: fit-content;
}

.DisplayTable .OrderDetails__orderDetailsTable:not(:last-child) {
  margin-bottom: .5rem;
}

.DisplayTable .OrderDetails__orderDetailsTable thead tr th {
  z-index: 99;
  min-width: max-content;
  border-top: none;
}

.DisplayTable .OrderDetails__orderDetailsTable tbody tr {
  background-color: #fff;
}

.DisplayTable .OrderDetails__orderDetailsTable tbody tr td {
  border-bottom: none;
}

.DisplayTable .OrderDetails__orderDetailsTable__inputTd {
  padding: 3.5px 8px !important;
}

.DisplayTable .OrderDetails__orderDetailsTable__iconButton {
  height: 32px !important;
  min-height: 32px !important;
  max-height: 32px !important;
  width: 32px !important;
  min-width: 32px !important;
  max-width: 32px !important;
}

.DisplayTable .OrderDetails__orderDetailsTable__iconButton svg {
  height: 20px;
  width: 20px;
}

.CreateOrderDrawer label {
  display: block;
}

.CreateOrderDrawer__searchAccounts {
  margin-bottom: 1.5rem;
}

.CreateOrderDrawer__searchAccounts label {
  margin-bottom: 4px;
}

.CreateOrderDrawer__address {
  justify-content: space-between;
  display: flex;
}

.CreateOrderDrawer__address label {
  color: #374151;
  font-weight: 500;
}

.CreateOrderDrawer__address p {
  color: #111827;
  margin-top: 4px;
}

.CreateOrderDrawer__tableLabel {
  color: #374151;
  margin-bottom: 4px;
}

.CreateOrderDrawer__tableContent {
  max-height: 300px;
  overflow: auto;
}

.CreateOrderDrawer__inputs {
  justify-content: space-between;
  gap: 2rem;
  margin: 1rem 0 2rem;
  display: flex;
}

.CreateOrderDrawer__inputs label {
  color: #374151;
}

.CreateOrderDrawer__inputs .Input__container {
  margin-bottom: 1rem;
}

.CreateOrderDrawer__invoice {
  color: #374151;
  margin: 2rem 0 0;
}

.CreateOrderDrawer__invoice .orderLabels {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.CreateOrderDrawer__invoice .orderLabels .Input__container {
  width: 296px;
}

.CreateOrderDrawer__callout {
  width: 100%;
  background-color: #fefce8;
  border-radius: 6px;
  align-items: flex-start;
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
}

.CreateOrderDrawer__callout__icon {
  color: #facc15;
  width: 24px;
  margin-right: 1rem;
}

.CreateOrderDrawer__callout__title {
  color: #854d0e;
  font-weight: 500;
}

.CreateOrderDrawer__callout__message {
  color: #a16207;
}

.ApplyCredits {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.ApplyCredits p {
  color: #374151;
  margin: 0;
  line-height: 1.5;
}

.ApplyCredits__row {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.ApplyCredits__keyValueSpan {
  display: flex;
}

.ApplyCredits__keyValueSpan > p:first-child {
  color: #6b7280;
}

.ApplyCredits__keyValueSpan > p:not(:last-child) {
  margin-right: 4px;
}

.ApplyCredits__keyValueSpan > p:nth-child(2) {
  color: #111827;
}

.ApplyCredits__header {
  height: 64px;
  background-color: #f9fafb;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ApplyCredits__header > p {
  color: #111827;
  font-size: 18px;
}

.ApplyCredits__body {
  flex-grow: 1;
  padding: 16px 20px 32px;
}

.ApplyCredits__body > :not(:last-child) {
  margin-bottom: 16px;
}

.ApplyCredits__body__amountContainer {
  align-items: center;
  display: flex;
}

.ApplyCredits__body__amountContainer__image {
  width: 48px;
  height: 48px;
  color: #166534;
  background-color: #dcfce7;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  display: flex;
}

.ApplyCredits__body__amountContainer__image svg {
  width: 18px;
}

.ApplyCredits__body__amountContainer__amount > p:first-child {
  color: #6b7280;
  font-size: 12px;
}

.ApplyCredits__body__amountContainer__amount > p:nth-child(2) {
  color: #111827;
  font-size: 18px;
  font-weight: 500;
}

.ApplyCredits__body__table {
  width: 100%;
  flex-direction: column;
  align-self: stretch;
  display: flex;
}

.ApplyCredits__body__table:not(:last-child) {
  margin-right: 40px;
}

.ApplyCredits__body__table > :not(:last-child) {
  padding: 4px 8px;
}

.ApplyCredits__body__table > p:first-child {
  font-weight: 500;
}

.ApplyCredits__body__table__tableContainer {
  min-height: 315px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  flex-grow: 1;
}

.ApplyCredits__body__table table thead {
  border-top: none;
  border-bottom: none;
}

.ApplyCredits__body__table table thead tr th {
  padding: 12px 16px !important;
}

.ApplyCredits__body__table table tbody tr {
  border: none;
}

.ApplyCredits__body__table table tbody tr td {
  padding: 16px !important;
}

.AddPaymentModal .Modal .Modal__content {
  padding: 0;
}

.AddPaymentModal .Modal .modal-header {
  color: #111827;
  background-color: #f9fafb;
  border-radius: .5rem .5rem 0 0;
  margin-bottom: 1rem;
  padding: 18px 0;
  font-size: 18px;
}

.AddPaymentModal .Modal .modal-body {
  padding: .5rem 1.5rem;
}

.AddPaymentModal .Modal .modal-body .Input {
  width: 256px;
}

.AddPaymentModal .Modal .modal-body .DatePicker__container .Input__container {
  width: unset;
}

.AddPaymentModal .Modal .modal-body .moneyInput {
  position: relative;
}

.AddPaymentModal .Modal .modal-body .moneyInput .moneyIcon {
  color: #9ca3af;
  font-size: 20px;
  position: absolute;
  left: 200px;
}

.AddPaymentModal .Modal .modal-footer {
  border-top: 1px solid #e9ecef;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  padding: 1.25rem 1.5rem;
  display: flex;
}

.AddPaymentModal__amount__input {
  text-align: right;
}

.AddPaymentModal .Modal__content {
  max-width: 505px;
  overflow: visible;
}

.AddCreditMemoModal__header {
  width: 100%;
  background-color: #f3f4f6;
  justify-content: center;
  align-items: center;
  padding: 18px 0;
  display: flex;
}

.AddCreditMemoModal__header > p {
  color: #111827;
  margin: 0;
  font-size: 18px;
  line-height: 1.5;
}

.AddCreditMemoModal__body {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin: 18px;
  overflow: hidden;
}

.AddCreditMemoModal__body .data-table th {
  border-top: none;
}

.AddCreditMemoModal__body .data-table tr:last-child td {
  border-bottom: none;
}

.AddCreditMemoModal__body .data-table th:last-child, .AddCreditMemoModal__body .data-table td:last-child {
  width: 0;
}

.AddCreditMemoModal__body .data-table th:last-child .Button--variant--raised, .AddCreditMemoModal__body .data-table td:last-child .Button--variant--raised {
  min-width: 0;
  padding: .25rem .75rem;
}

.style-guide .example {
  display: flex;
}

.style-guide .example-content {
  min-height: 256px;
  min-width: 256px;
  border: 1px solid #e9ecef;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.style-guide .example-options {
  width: 256px;
  background-color: #f9fafb;
  border: 1px solid #e9ecef;
  margin: 0 0 0 1rem;
  padding: 1rem;
}

.style-guide .example-options select {
  margin-bottom: .5rem;
}

@media only screen and (max-width: 54.7946em) {
  .style-guide .example {
    flex-direction: column-reverse;
  }

  .style-guide .example-options {
    margin: 0 0 1rem;
  }
}

.EverynameList {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.EverynameList__reportsLink {
  color: #6b7280;
  cursor: pointer;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  display: flex;
}

.EverynameList__reportsLink svg {
  width: 16px;
}

.EverynameList__reportsPopover {
  width: 300px;
  gap: 1.5rem;
  display: flex;
  padding: 24px !important;
}

.EverynameList__reportsPopover__column {
  flex: 1;
}

.EverynameList__reportsPopover__column h4 {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
}

.EverynameList__reportsPopover__column p {
  color: #6b7280;
  margin: 0;
}

.EverynameList__reportsPopover__column a {
  margin-top: .5rem;
  display: block;
}

.EverynameList__filters {
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
}

.EverynameList__accessCode {
  width: 320px;
}

.EverynameList__accessCode__content {
  flex-direction: column;
  display: flex;
}

.EverynameList__accessCode__content > span {
  justify-content: space-between;
  display: flex;
}

.EverynameList__accessCode__content > span:not(:last-child) {
  margin-bottom: 8px;
}

.EverynameList__accessCode__content > span :last-child {
  text-align: right;
}

.EverynameList__accessCode__content__address {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.EverynameList__accessCode__content__address p {
  color: #111827;
}

.EverynameDetails {
  height: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
}

.EverynameDetails p {
  color: #374151;
  margin: 0;
  line-height: 1.5;
}

.EverynameDetails strong {
  font-weight: 500;
}

.EverynameDetails__cancelledBanner {
  width: 100%;
  z-index: 100;
  background-color: #fefce8;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  display: flex;
  position: absolute;
  top: 0;
}

.EverynameDetails__cancelledBanner__left {
  align-items: center;
  display: flex;
}

.EverynameDetails__cancelledBanner__icon {
  width: 20px;
  height: 20px;
  color: #eab308;
  margin-right: 12px;
}

.EverynameDetails__cancelledBanner__title {
  font-weight: 500;
}

.EverynameDetails__cancelledBanner__title, .EverynameDetails__cancelledBanner__subtitle {
  color: #854d0e !important;
}

.EverynameDetails__cancelledBanner__button {
  font-size: 12px !important;
}

.EverynameDetails__nav {
  align-items: center;
  display: flex;
}

.EverynameDetails__nav h4 {
  margin: 0 30px 0 0;
  font-size: 22px;
  font-weight: 400;
}

.EverynameDetails__nav a {
  color: #6b7280;
  align-items: center;
  gap: 4px;
  display: flex;
}

.EverynameDetails__nav a:hover {
  text-decoration: none;
}

.EverynameDetails__nav a svg {
  width: 16px;
}

.EverynameDetails__container {
  background-color: #f3f4f6;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  padding: 24px !important;
}

.EverynameDetails__container__content {
  background-color: #fff;
  flex-grow: 1;
  padding: 16px 24px;
  display: flex;
}

.EverynameDetails__container__cancelButtons {
  width: 100%;
  background-color: #fff;
  justify-content: space-between;
  padding: 12px 24px;
  display: flex;
}

.EverynameDetails__sideInfo {
  width: 256px;
  flex-direction: column;
  padding: 0 8px;
  display: flex;
}

.EverynameDetails__sideInfo__grayBackground {
  background-color: #f3f4f6;
  border-radius: 8px;
  margin: 0 -8px;
  padding: 12px 16px;
}

.EverynameDetails__sideInfo__grayBackground--indent {
  padding: 16px 8px;
}

.EverynameDetails__sideInfo__editOrderNotes {
  margin: 0 -8px;
}

.EverynameDetails__sideInfo__editOrderNotes__buttons {
  padding: 8px 0;
  display: flex;
}

.EverynameDetails__sideInfo__editOrderNotes__buttons :first-child {
  margin-right: 8px;
  padding: 5px 0 !important;
}

.EverynameDetails__sideInfo__editOrderNotes__buttons :last-child {
  color: #2857cd;
  background-color: #f0f6fe;
  margin-left: 8px;
  padding: 5px 0 !important;
}

.EverynameDetails__sideInfo__valueSpan {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.EverynameDetails__sideInfo__valueSpan:not(:last-child) {
  margin-bottom: 8px;
}

.EverynameDetails__sideInfo__valueSpan a:nth-child(2), .EverynameDetails__sideInfo__valueSpan p:nth-child(2) {
  word-break: break-all;
  text-align: right;
  flex-grow: 1;
}

.EverynameDetails__sideInfo__valueSpan p:nth-child(2) {
  color: #111827;
}

.EverynameDetails__sideInfo__valueSpan span:nth-child(2) {
  flex-flow: column wrap;
  align-items: flex-end;
  display: flex;
}

.EverynameDetails__sideInfo__valueSpan span:nth-child(2) p {
  text-align: right;
  color: #111827;
}

.EverynameDetails__sideInfo__valueSpan--smallMargin:not(:last-child) {
  margin-bottom: 4px;
}

.EverynameDetails__sideInfo__card:not(:last-child) {
  margin-bottom: 16px;
}

.EverynameDetails__sideInfo__card__header {
  margin: 4px 0 !important;
}

.EverynameDetails__sideInfo__card--paddingVertical {
  padding: 8px 0;
}

.EverynameDetails__sideInfo__card--padding {
  padding: 8px;
}

.EverynameDetails__sideInfo__total p {
  font-size: 16px;
}

.EverynameDetails__tabs {
  flex-direction: column;
  flex-grow: 1;
  margin-left: 32px;
  padding: 0 16px;
  display: flex;
}

.EverynameDetails__tabs__tabContainer {
  width: 100%;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 16px;
}

.EverynameDetails__tabs__tab {
  width: 200px;
  justify-content: center;
  display: flex;
  padding-top: 4px !important;
  padding-bottom: 14px !important;
  font-weight: 400 !important;
}

.EverynameDetails__tabs__tab .Badge {
  margin-left: .5rem;
}

.EverynameDetails__tabs__tableContainer:not(:first-child) {
  margin-top: 24px;
}

.EverynameDetails__tabs__tableHeader {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  padding: 8px 16px;
  display: flex;
}

.EverynameDetails__tabs__tableHeader span {
  display: flex;
}

.EverynameDetails__tabs__tableHeader span p {
  color: #6b7280;
  font-size: 14px;
}

.EverynameDetails__tabs__tableHeader span p:not(:last-child) {
  margin-right: 60px;
}

.EverynameDetails__tabs__tableHeader span p strong {
  color: #111827;
  font-weight: 400;
}

.EverynameDetails__modal {
  flex-direction: column;
  display: flex;
}

.EverynameDetails__modal .Modal__content {
  width: 500px !important;
  padding: 0 !important;
}

.EverynameDetails__modal__header {
  height: 64px;
  width: 100%;
  background-color: #f9fafb;
  justify-content: center;
  align-items: center;
  display: flex;
}

.EverynameDetails__modal__header p {
  color: #111827;
  font-size: 18px;
}

.EverynameDetails__modal__header .IconButton {
  color: #6b7280;
}

.EverynameDetails__modal__content__row {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 24px;
  display: flex;
}

.EverynameDetails__modal__content__row:first-child {
  margin-top: 16px;
}

.EverynameDetails__modal__content__row__inputs {
  width: 275px;
  display: flex;
}

.EverynameDetails__modal__content__row__inputs .Input__container {
  flex-grow: 1;
}

.EverynameDetails__modal__content__row__inputs .Input__container:not(:first-child) {
  margin-left: 8px;
}

.EverynameDetails__modal__content__row__inputs .Input__container input {
  width: 100%;
}

.EverynameDetails__modal__content__divider {
  height: 1px;
  width: 100%;
  background-color: #e5e7eb;
  margin-bottom: 16px;
}

.EverynameDetails__modal__buttons {
  border-top: 1px solid #e5e7eb;
  justify-content: flex-end;
  padding: 20px 24px;
  display: flex;
}

.EverynameDetails__modal__buttons button:last-child {
  margin-left: 16px;
}

.EverynameDetails__cancelOrderModal__content {
  padding: 16px 24px;
}

.EverynameDetails__deleteWarning {
  gap: 1.5rem;
  display: flex;
}

.EverynameDetails__deleteWarning__iconContainer {
  background-color: #fee2e2;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  display: flex;
}

.EverynameDetails__deleteWarning__iconContainer > svg {
  width: 24px;
  height: 24px;
  color: #c13741;
}

.EverynameDetails__deleteWarning__content {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.EverynameDetails__deleteWarning__content h1, .EverynameDetails__deleteWarning__content p {
  margin: 0;
}

.EverynameDetails__deleteWarning__content h1 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
}

.EverynameDetails__deleteWarning__content__buttons {
  gap: 1rem;
  display: flex;
}

.EverynameDetails__tabs__table {
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  overflow: hidden;
}

.EverynameDetails__tabs__table thead {
  border-top: none;
}

.EverynameDetails__tabs__table tr:last-child {
  border-bottom: none;
}

.EverynameDetails__tabs__table td, .EverynameDetails__tabs__table th {
  color: #374151;
}

.EverynameDetails__tabs__table td:last-child, .EverynameDetails__tabs__table th:last-child {
  color: #6b7280;
}

.EverynameDetails__tabs__table td img, .EverynameDetails__tabs__table th img {
  height: 48px;
  min-width: max-content;
}

.VariableFields__layer {
  padding: 16px 24px;
  overflow: auto;
}

.VariableFields__layer__break {
  padding-bottom: 24px;
}

.VariableFields__layer__colorContainer {
  width: 177px;
  align-items: center;
  display: flex;
}

.VariableFields__layer__colorContainer > :not(:last-child) {
  margin-right: 8px;
}

.VariableFields__layer__colorContainer > :not(:first-child) {
  align-self: stretch;
}

.VariableFields__layer__colorContainer input[type="color"] {
  height: 100%;
  padding: 0 3px;
}

.VariableFields__layer__required {
  border: 1px solid red !important;
}

.VariableFields {
  height: 100%;
  max-height: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
}

.VariableFields__keyValueSpan {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.VariableFields__keyValueSpan > p:nth-child(2) {
  text-align: right;
}

.VariableFields__keyValueSpan:not(:last-child) {
  margin-bottom: 8px;
}

.VariableFields__keyValueSpan--bold > p:first-child {
  font-weight: 500;
}

.VariableFields__keyValueSpan--bold > p:nth-child(2), .VariableFields__keyValueSpan--dark > p:nth-child(2) {
  color: #111827;
}

.VariableFields__closeButton {
  cursor: pointer;
  top: 10px;
  right: 10px;
  position: absolute !important;
}

.VariableFields__content {
  flex: 1 1 0;
  display: flex;
  overflow: hidden;
}

.VariableFields__fields {
  width: 416px;
  border-right: 1px solid #e5e7eb;
  flex-direction: column;
  display: flex;
}

.VariableFields__fields__header {
  padding: 12px 8px;
}

.VariableFields__fields__header > .VariableFields__keyValueSpan {
  margin: 4px 16px;
}

.VariableFields__fields__header__tabs {
  margin: 16px 8px;
  position: relative;
}

.VariableFields__fields__header__tabs > .Tabs {
  -webkit-user-select: none;
  user-select: none;
  align-items: flex-end;
  box-shadow: inset 0 -1px #e5e7eb;
}

.VariableFields__fields__header__tabs > .Tabs::-webkit-scrollbar {
  display: none;
}

.VariableFields__fields__header__tabs > .Tabs .TabItem {
  min-width: max-content;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.VariableFields__fields__header__tabs__buttonOverlay {
  pointer-events: none;
  width: 100%;
  z-index: 99;
  -webkit-user-select: none;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.VariableFields__fields__header__tabs__buttonOverlay > div {
  pointer-events: all;
  cursor: pointer;
  width: 40px;
  height: 100%;
  align-items: center;
  display: flex;
  position: absolute;
}

.VariableFields__fields__header__tabs__buttonOverlay > div > svg {
  height: 18px;
}

.VariableFields__fields__header__tabs__buttonOverlay__prev {
  background: linear-gradient(to right, #fff 0% 50%, #fff0);
  justify-content: flex-start;
  left: 0;
}

.VariableFields__fields__header__tabs__buttonOverlay__next {
  background: linear-gradient(to left, #fff 0% 50%, #fff0);
  justify-content: flex-end;
  right: 0;
}

.VariableFields__image {
  min-width: 416px;
  flex-direction: column;
  padding: 8px;
  display: flex;
}

.VariableFields__image__header {
  width: 100%;
  justify-content: space-around;
  margin-top: 24px;
  margin-bottom: 8px;
  display: flex;
}

.VariableFields__image__header > p {
  cursor: pointer;
}

.VariableFields__image__header__activeTab {
  color: #3981ea !important;
}

.VariableFields__image__container {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.VariableFields__image__container > img {
  max-height: 500px;
  max-width: 700px;
}

.VariableFields__buttons {
  border-top: 1px solid #e5e7eb;
  justify-content: space-between;
  padding: 20px 24px;
  display: flex;
}

.VariableFields__buttons button:not(:last-child) {
  margin-right: 16px;
}

.ProductDetails__tagCard > p {
  font-size: 16px;
  font-weight: 500;
}

.ProductDetails__tagCard__card {
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 1px 2px #0000000f, 0 1px 3px #0000001a;
}

.ProductDetails__tagCard__card__search {
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 16px;
  display: flex;
}

.ProductDetails__tagCard__card__search > svg {
  width: 24px;
  margin-bottom: .5rem;
}

.ProductDetails__tagCard__card__inputGroup {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.ProductDetails__tagCard__card__inputGroup > span {
  margin-bottom: 16px;
  display: flex;
}

.ProductDetails__tagCard__card__inputGroup > span > p {
  color: #111827;
}

.ProductDetails__tagCard__card__inputGroup > span > svg {
  width: 20px;
  margin-left: 8px;
}

.ProductDetails__tagCard__card__inputGroup .Input:disabled {
  color: #6b7280;
  background-color: #f3f4f6;
}

.ProductDetails__tagCard__card__inputGroup .Input:disabled::placeholder {
  color: #9ca3af !important;
}

.ProductDetails__tagCard__card__content {
  flex-wrap: wrap;
  padding: 5px 0 13px;
  display: flex;
}

.ProductDetails__tagCard__card__content > .Products__tag {
  margin-top: 8px;
}

.ProductDetails__tagCard__card__content > .Products__tag:not(:last-child) {
  margin-right: 8px;
}

.ProductDetails__tagCard__card__buttons {
  justify-content: flex-end;
  padding-top: 16px;
  display: flex;
}

.ProductDetails__tagCard__card__buttons > button:disabled {
  border: 1px solid #0000;
}

.ProductDetails__tagCard__card__buttons > button:not(:last-child) {
  margin-right: 16px;
}

.ProductDetails__removeTagModal__header {
  width: 100%;
  height: 64px;
  background-color: #f9fafb;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ProductDetails__removeTagModal__header > p {
  color: #111827;
  font-size: 18px;
}

.ProductDetails__removeTagModal__body {
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  display: flex;
}

.ProductDetails__removeTagModal__body .ProductDetails__warningBanner {
  width: 575px;
}

.ProductDetails__removeTagModal__buttons {
  width: 100%;
  border-top: 1px solid #e5e7eb;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  display: flex;
}

.ProductDetails__removeTagModal__buttons > :first-child {
  margin-right: 16px;
}

.ProductDetails__nav {
  align-items: center;
  display: flex;
}

.ProductDetails__nav > h2 {
  color: #374151;
  margin: 0 16px 0 0;
  font-size: 22px;
  line-height: 1.5;
}

.ProductDetails__nav > p {
  color: #d1d5db;
  font-size: 36px;
  font-weight: 100;
}

.ProductDetails__nav__link {
  margin-left: 32px;
  color: #6b7280 !important;
  text-decoration: none !important;
}

.ProductDetails__nav__link svg {
  width: 16px;
  margin-left: 4px;
}

.ProductDetails__nav__link--active {
  font-weight: 500;
  color: #244084 !important;
}

.ProductsList__productInfoModal {
  align-items: center;
  display: flex;
}

.ProductsList__productInfoModal img {
  width: 100px;
}

.ProductsList__productInfoModal__text {
  width: 316px;
  flex-direction: column;
  margin-left: 16px;
  padding: 24px;
  display: flex;
}

.ProductsList__productInfoModal__text p {
  color: #6b7280;
  margin: 0;
  line-height: 1.5;
}

.ProductsList__productInfoModal__text > :nth-child(1) {
  color: #1f2937;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 500;
}

.ProductsList__productInfoModal__text > :nth-child(2) {
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 21px;
  padding-bottom: 4px;
}

.ProductsList__productInfoModal__text > span {
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
}

.ProductsList__productInfoModal__text > span > p:nth-child(2) {
  color: #111827;
}

.ProductsList__productInfoModal__text > a:last-child {
  align-items: center;
  margin-top: 16px;
  text-decoration: none;
  display: flex;
}

.ProductsList__productInfoModal__text > a:last-child > svg {
  width: 20px;
  margin-right: 4px;
}

.ProductDetails__detailsModal .Button--variant--raised {
  transition: all !important;
}

.ProductDetails__detailsModal__header {
  width: 100%;
  height: 64px;
  background-color: #f9fafb;
  border-radius: 8px 8px 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ProductDetails__detailsModal__header > p {
  color: #111827;
  font-size: 18px;
}

.ProductDetails__detailsModal__body__productInfo {
  flex-direction: column;
  padding: 8px 24px;
  display: flex;
}

.ProductDetails__detailsModal__body__productInfo > p {
  margin-bottom: 32px;
  font-size: 18px;
}

.ProductDetails__detailsModal__body__productInfo > .ProductDetails__keyValueSpan {
  align-items: center;
  margin-bottom: 24px;
}

.ProductDetails__detailsModal__body__productInfo > .ProductDetails__keyValueSpan:last-child {
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 0;
  padding-bottom: 24px;
}

.ProductDetails__detailsModal__body__productCheckboxes {
  flex-direction: column;
  margin-bottom: -12px;
  padding: 8px 24px;
  display: flex;
}

.ProductDetails__detailsModal__body__productCheckboxes > p {
  color: #6b7280;
  margin: 24px 0;
}

.ProductDetails__detailsModal__body__productCheckboxes > div {
  flex-wrap: wrap;
  margin: 0 -12px 0 0;
  display: flex;
}

.ProductDetails__detailsModal__body__productCheckboxes__checkboxContainer {
  width: calc(50% - 12px);
  margin-bottom: 12px;
  margin-right: 12px;
}

.ProductDetails__detailsModal__body__productCheckboxes__checkboxContainer .Checkbox__container {
  margin: 0;
  padding-left: 24px;
  font-weight: 400;
}

.ProductDetails__detailsModal__body__productCheckboxes__checkboxContainer > p {
  color: #6b7280;
  margin-left: 24px;
}

.ProductDetails__detailsModal__body__cancelProduct {
  padding: 8px 24px;
}

.ProductDetails__detailsModal__body__cancelProduct > p {
  margin-bottom: 20px;
  font-size: 18px;
}

.ProductDetails__detailsModal__body__cancelProduct > .ProductDetails__warningBanner {
  margin-bottom: 12px;
}

.ProductDetails__detailsModal__buttons {
  width: 100%;
  border-top: 1px solid #e5e7eb;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  display: flex;
}

.ProductDetails__detailsModal__buttons > span > :first-child {
  margin-right: 16px;
}

.ProductsList__filtersDrawer__content__viewAllToggle {
  border-bottom: 1px solid #e5e7eb;
  padding: 8px;
}

.ProductsList__filtersDrawer__content__viewAllToggle .Toggle__container {
  margin: 0 !important;
}

.ProductsList__filtersDrawer__content .Checkbox__container {
  margin: 16px 0;
  font-weight: 400;
}

.ProductsList__filtersDrawer__content .Toggle__container {
  justify-content: flex-end;
  margin: 16px 0;
}

.ProductDetails__artNotesModal__header {
  width: 100%;
  height: 64px;
  background-color: #f9fafb;
  border-radius: 8px 8px 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ProductDetails__artNotesModal__header > p {
  color: #111827;
  font-size: 18px;
}

.ProductDetails__artNotesModal__body {
  padding: 24px;
}

.ProductDetails__artNotesModal__body > p {
  color: #111827;
  font-size: 18px;
  font-weight: 500;
}

.ProductDetails__artNotesModal__body__datesContainer {
  margin-bottom: 24px;
}

.ProductDetails__artNotesModal__body__row {
  border-bottom: 1px solid #e5e7eb;
  align-items: flex-end;
  padding: 24px 0;
  display: flex;
}

.ProductDetails__artNotesModal__body__row > :not(:last-child) {
  margin-right: 24px;
}

.ProductDetails__artNotesModal__body__inputField {
  flex-direction: column;
  flex: 1px;
  display: flex;
}

.ProductDetails__artNotesModal__body__inputField > p {
  margin-bottom: 4px;
  font-weight: 500;
}

.ProductDetails__artNotesModal__body__inputField > :last-child {
  display: flex;
}

.ProductDetails__artNotesModal__body__inputField .Input__container {
  flex-grow: 1;
  display: flex;
}

.ProductDetails__artNotesModal__body__inputField .Input__container .Input {
  flex-grow: 1;
}

.ProductDetails__artNotesModal__body__textAreaNotes .Radio__container {
  font-weight: 400;
}

.ProductDetails__artNotesModal__body__textAreaNotes:not(:last-child) {
  margin-bottom: 24px;
}

.ProductDetails__artNotesModal__body__textAreaNotes .Input:disabled {
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
}

.ProductDetails__artNotesModal__buttons {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  display: flex;
}

.ProductDetails__artNotesModal__buttons > :first-child {
  margin-right: 16px;
}

.ProductDetails__searchPopoverNoquery {
  flex-direction: column;
  display: flex;
}

.ProductDetails__searchPopoverNoquery .Input__container {
  display: flex;
}

.ProductDetails__searchPopoverNoquery__popoverInput {
  flex-grow: 1;
  margin: 8px;
}

.ProductDetails__searchPopoverNoquery__popoverOptions {
  border-top: 1px solid #e5e7eb;
  flex-grow: 1;
  padding: 8px 0;
  overflow: auto;
}

.ProductDetails__searchPopoverNoquery__popoverOptions__option {
  cursor: pointer;
  padding: 8px 16px;
}

.ProductDetails__searchPopoverNoquery__popoverOptions__option:hover {
  background-color: #f3f4f6;
}

.Products__tag {
  width: -moz-fit-content;
  width: fit-content;
  background-color: #f3f4f6;
  align-items: center;
  padding: 3px 9px;
  display: flex;
}

.Products__tag p {
  color: #374151;
  font-size: 14px;
}

.Products__tag p > span:first-child {
  font-weight: 500;
}

.Products__tag svg {
  color: #6b7280;
  cursor: pointer;
  width: 14px;
  margin-left: 8px;
}

.Products__dropdownMenu__title {
  cursor: pointer;
  border-bottom: 1px solid #e5e7eb;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  display: flex;
}

.Products__dropdownMenu__title > p {
  color: #374151;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.CreditMemosList__filters {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
}

.CreditMemosList__filters__spacer {
  flex: 1;
}

.AddMemoDrawer p {
  color: #374151;
  margin: 0;
  line-height: 1.5;
}

.AddMemoDrawer input:disabled {
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
}

.AddMemoDrawer__body__row {
  display: flex;
}

.AddMemoDrawer__body__row > :not(:last-child) {
  margin-right: 24px;
}

.AddMemoDrawer__body__labelledInput {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.AddMemoDrawer__body__labelledInput > p {
  color: #4b5563;
  margin-bottom: 4px;
  font-weight: 500;
}

.AddMemoDrawer__body__labelledInput > :nth-child(2), .AddMemoDrawer__body__labelledInput > :nth-child(2) > div {
  width: 100%;
}

.AddMemoDrawer__body__divider {
  width: 100%;
  height: 1px;
  background-color: #e5e7eb;
  margin: 8px 0;
}

.AddMemoDrawer__body__inputSpan {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.AddMemoDrawer__body__inputSpan > p {
  color: #4b5563;
  font-weight: 500;
}

.AddMemoDrawer__body__inputSpan > :nth-child(2) {
  width: 274px;
}

.AddMemoDrawer__body__inputSpan > :nth-child(2) > div, .AddMemoDrawer__body__inputSpan > :nth-child(2) > input, .AddMemoDrawer__body__inputSpan > :nth-child(2) > select, .AddMemoDrawer__body__inputSpan > :nth-child(2) > textarea {
  width: 100%;
}

.AddMemoDrawer__body__inputSpan--textarea > p {
  align-self: flex-start;
}

.AddMemoDrawer__body__inputSpan--textarea textarea {
  resize: none;
}

.AddMemoDrawer__body__searchContainer {
  padding: 0 0 16px;
}

.AddMemoDrawer__body__searchContainer > :not(:last-child) {
  margin-bottom: 20px;
}

.AddMemoDrawer__body__creditContainer {
  padding: 24px 0;
}

.AddMemoDrawer__body__creditContainer > :not(:last-child) {
  margin-bottom: 16px;
}

.AddMemoDrawer__body__approvalContainer {
  padding: 24px 0 12px;
}

.AddMemoDrawer__body__approvalContainer > :not(:last-child) {
  margin-bottom: 16px;
}

.AddMemoDrawer__body__labelledToggle {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.AddMemoDrawer__body__labelledToggle > :not(:last-child) {
  margin-right: 18px;
}

.AddMemoDrawer__body__amountsContainer {
  padding: 8px 0;
}

.AddMemoDrawer__body__tableContainer {
  padding: 18px 0 6px;
}

.AddMemoDrawer__body__tableContainer > :not(:last-child) {
  margin-bottom: 16px;
}

.AddMemoDrawer__cancelWarning {
  background-color: #fdfcfa;
  border-top: 1px solid #e5e7eb;
  align-items: flex-start;
  padding: 16px;
  display: flex;
}

.AddMemoDrawer__cancelWarning svg {
  color: #d9515e;
  width: 24px;
  margin-right: 12px;
}

.AddMemoDrawer__cancelWarning__content {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.AddMemoDrawer__cancelWarning__header {
  font-weight: 500;
  color: #6d1d20 !important;
  margin-bottom: 4px !important;
}

.AddMemoDrawer__cancelWarning__text {
  color: #992930 !important;
  margin-bottom: 12px !important;
}

.AddMemoDrawer__cancelWarning__options {
  align-items: center;
  display: flex;
}

.AddMemoDrawer__cancelWarning__options > a {
  color: #6d1d20;
  margin-right: 16px;
  -webkit-text-decoration: underline #6d1d20;
  text-decoration: underline #6d1d20;
}

.BillingDetails {
  height: 100%;
  background-color: #f3f4f6;
  flex-direction: column;
  display: flex;
}

.BillingDetails__container {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.BillingDetails__nav__slash {
  width: 24px;
  height: 36px;
  background: linear-gradient(-80deg, #fff, #fff 50%, #d1d5db 50% calc(50% + 2px), #fff calc(50% + 2px));
  margin: 0 10px 0 16px;
}

.BillingDetails__nav__contactsLink {
  margin-right: -15px;
  font-weight: 500;
  color: #6b7280 !important;
}

.BillingDetails__nav__contactsDropdown {
  width: max-content;
  flex-direction: column;
  display: flex;
  padding: 24px 36px !important;
}

.BillingDetails__nav__contactsDropdown * {
  margin: 0;
}

.BillingDetails__nav__contactsDropdown > p:first-child {
  color: #111827;
  font-weight: 500;
}

.BillingDetails__nav__contactsDropdown__container {
  width: max-content;
  display: flex;
}

.BillingDetails__nav__contactsDropdown__container > :not(:last-child) {
  margin-right: 32px;
}

.BillingDetails__nav__contactsDropdown__column {
  flex-direction: column;
  flex: 1 1 0;
  display: flex;
}

.BillingDetails__nav__contactsDropdown__contact {
  width: max-content;
  flex-direction: column;
  margin-top: 16px;
  display: flex;
}

.BillingDetails__nav__contactsDropdown__contact * {
  width: 100%;
}

.BillingDetails__nav__contactsDropdown__contact :hover {
  text-decoration: none;
}

.BillingDetails__nav__ordersPopover {
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column;
  gap: 1rem;
  display: flex;
  padding: 12px 16px !important;
}

.BillingDetails__nav__ordersPopover > p {
  white-space: nowrap;
  margin: 0;
  font-weight: 500;
}

.BillingDetails__body {
  background-color: #fff;
  flex-grow: 1;
  gap: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 24px;
  padding: 1rem 1.5rem;
  display: flex;
}

.BillingDetails__openOrders {
  flex: 1;
  position: relative;
}

.BillingDetails__openOrders__header {
  margin-top: 20px;
}

.BillingDetails__openOrders__title {
  color: #374151;
  margin: 0;
  font-weight: 500;
}

.BillingDetails__totals {
  gap: 1rem;
  display: flex;
  position: absolute;
  right: 0;
}

.BillingDetails__totals__label {
  color: #6b7280;
  display: block;
}

.BillingDetails__totals__value {
  color: #374151;
  font-weight: 500;
  display: block;
}

.BillingList__filters {
  align-items: end;
  gap: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
}

.BillingList__filters__status {
  flex-direction: column;
  display: flex;
}

.BillingList__filters__status select {
  height: 39px;
}

.OpenOrdersTable__daysPast--green {
  color: #15803d;
}

.OpenOrdersTable__daysPast--orange {
  color: #ca8a04;
}

.OpenOrdersTable__daysPast--red {
  color: #d9515e;
}

.InfoSection {
  width: 268px;
  padding: 1.5rem 0;
}

.InfoSection__field {
  justify-content: space-between;
  margin-bottom: .5rem;
  display: flex;
}

.InfoSection__field label {
  color: #374151;
}

.InfoSection__field p {
  color: #111827;
  text-align: right;
  margin: 0;
}

.InfoSection__overview {
  margin-bottom: 2rem;
}

.InfoSection__overview__header {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  display: flex;
}

.InfoSection__overview__edit {
  color: #3981ea;
}

.InfoSection__overview__body {
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 1rem;
}

.InfoSection__status {
  margin-bottom: 2rem;
  padding: .5rem;
}

.InfoSection__primaryContact {
  margin-bottom: 2rem;
}

.InfoSection__primaryContact__header {
  padding-bottom: 4px;
}

.InfoSection__primaryContact__body {
  padding: .5rem;
}

.InfoSection__notes__header {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  display: flex;
}

.InfoSection__notes__edit {
  color: #3981ea;
}

.InfoSection__notes__body {
  white-space: pre-wrap;
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 1rem;
}

.InfoSection__notes__body p {
  margin: 0;
}

.InfoSection__notes__buttons {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  display: flex;
}

.EditBillingModal .Modal__content {
  max-width: 512px;
  padding: 0;
}

.EditBillingModal .Modal__content .Modal__close {
  position: absolute;
}

.EditBillingModal__header {
  text-align: center;
  background-color: #f9fafb;
  border-bottom: 1px solid #f3f4f6;
  padding: 18px;
}

.EditBillingModal__title {
  color: #111827;
  margin: 0;
  font-size: 18px;
}

.EditBillingModal__form__body {
  padding: 1rem 1.5rem;
}

.EditBillingModal__form__field {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.EditBillingModal__form__field .Input {
  width: 272px;
}

.EditBillingModal__form__field__doubleInput {
  gap: 8px;
  display: flex;
}

.EditBillingModal__form__field__doubleInput #state {
  height: 39px;
  width: 108px;
}

.EditBillingModal__form__field__doubleInput #postal {
  width: 156px;
}

.EditBillingModal__form__buttons {
  border-top: 1px solid #f3f4f6;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1.25rem 1.5rem;
  display: flex;
}

.BillingCollectionDrawer__content {
  display: flex;
  padding: 0 !important;
}

.BillingCollectionDrawer__left {
  flex: 1;
  padding: 1rem;
}

.BillingCollectionDrawer__left input, .BillingCollectionDrawer__left select, .BillingCollectionDrawer__left textarea {
  width: 248px;
}

.BillingCollectionDrawer__field {
  justify-content: space-between;
  margin-bottom: 1rem;
  display: flex;
}

.BillingCollectionDrawer__field label {
  font-weight: 500;
  display: block;
}

.BillingCollectionDrawer__right {
  border-left: 1px solid #e5e7eb;
  flex: 1;
  padding-left: 1rem;
  overflow-y: auto;
}

.BillingCollectionDrawer__note {
  border-bottom: 1px solid #e5e7eb;
  padding: 1rem;
}

.BillingCollectionDrawer__note__header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.BillingCollectionDrawer__note__header :last-child {
  color: #6b7280;
}

.BillingCollectionDrawer__note__body p {
  margin: .5rem 0;
}

.ProductSummary {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.ProductSummary p {
  color: #374151;
  margin: 0;
  line-height: 1.5;
}

.ProductSummary__container {
  background-color: #f3f4f6;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.ProductSummary__body {
  background-color: #fff;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
  display: flex;
}

.ProductSummary__header {
  border-bottom: 1px solid #e5e7eb;
}

.ProductSummary__header > p {
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 500;
}

.ProductSummary__products {
  flex-grow: 1;
}

.ProductSummary__products__row {
  width: 100%;
  border-bottom: 1px solid #e5e7eb;
  gap: 48px;
  padding: 24px 0;
  display: flex;
  position: relative;
}

.ProductSummary__products__row__title {
  font-size: 18px;
  margin-bottom: 12px !important;
}

.ProductSummary__products__row__column {
  flex: 25%;
}

.ProductSummary__products__row__column:has(img) {
  align-self: center;
  align-items: center;
  padding-left: 24px;
  display: flex;
}

.ProductSummary__products__row__column:has(img) > img {
  max-height: 83px;
  left: 8px;
}

.ProductSummary__keyValueSpan {
  display: flex;
}

.ProductSummary__keyValueSpan p:first-child {
  color: #6b7280;
  margin-right: 16px;
}

.ProductSummary__keyValueSpan p:nth-child(2) {
  color: #111827;
}

.ProductSummary__keyValueSpan:not(:last-child) {
  margin-bottom: 4px;
}

.HistorySnapshot {
  height: 100%;
  flex-flow: column;
  display: flex;
}

.HistorySnapshot p {
  color: #374151;
  margin: 0;
  line-height: 1.5;
}

.HistorySnapshot__container {
  background-color: #f3f4f6;
  flex-grow: 1;
  display: flex;
}

.HistorySnapshot__body {
  background-color: #fff;
  flex-grow: 1;
  padding: 16px;
}

.HistorySnapshot__header {
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.HistorySnapshot__header > p {
  margin: 0 32px 14px 0;
  font-size: 18px;
  font-weight: 500;
}

.HistorySnapshot__header__tabs {
  border-bottom: 1px solid #e5e7eb;
  flex-grow: 1;
  display: flex;
}

.HistorySnapshot__header__tabs > .TabItem {
  height: 47px;
  width: 136px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 16px !important;
}

.HistorySnapshot__header__tabs > .TabItem > p {
  margin-right: 8px;
  font-weight: 400;
}

.HistorySnapshot__header__tabs > .TabItem--active > p {
  color: #306ce0;
}

.HistorySnapshot__header__tabs > .TabItem--active > .Badge {
  color: #2647a7;
  background-color: #f0f6fe;
}

.HistorySnapshot__header__tabs__wideTab {
  width: 200px !important;
}

.HistorySnapshot__header__tabSpacer {
  flex-grow: 1;
}

.HistorySnapshot__header__tabsButton {
  margin: 0 0 12px 24px;
}

.HistorySnapshot__data {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.HistorySnapshot__data__card {
  width: 284px;
  background-color: #f3f4f6;
  border-radius: 6px;
  padding: 16px;
}

.HistorySnapshot__data__card > span {
  justify-content: space-between;
  display: flex;
}

.HistorySnapshot__data__card > span:not(:last-child) {
  margin-bottom: 8px;
}

.HistorySnapshot__data__card > span > p:nth-child(2) {
  color: #111827;
}

.HistorySnapshot__data__table > table thead {
  border-top: none;
}

.HistorySnapshot__data__table > table thead th {
  width: 120px;
}

.HistorySnapshot__data__table > table thead th:first-child {
  background-color: #fff;
}

.HistorySnapshot__data__table > table tr:last-child {
  border-bottom: none;
}

.HistorySnapshot__data__table > table tr td:nth-child(n+3) {
  text-align: right;
}

.HistorySnapshot__tables {
  display: flex;
}

.HistorySnapshot__tables__table {
  flex-grow: 1;
}

.HistorySnapshot__tables__table:not(:last-child) {
  margin-right: 64px;
}

.HistorySnapshot__tables__table > p {
  padding: 8px 16px;
  font-weight: 500;
}

.HistorySnapshot__tables__table th:not(:first-child), .HistorySnapshot__tables__table td:not(:first-child) {
  text-align: right;
}

.HistorySnapshot__tables__table td:first-child {
  font-weight: 500;
}

.HistorySnapshot__orderTable:not(:last-child) {
  margin-bottom: 32px;
}

.HistorySnapshot__orderTable:last-child {
  margin-bottom: 20px;
}

.HistorySnapshot__orderTable__header {
  justify-content: space-between;
  padding: 8px 16px;
  display: flex;
}

.HistorySnapshot__orderTable__header span {
  display: flex;
}

.HistorySnapshot__orderTable__header span:not(:last-child) {
  margin-right: 64px;
}

.HistorySnapshot__orderTable__header span > p:first-child {
  color: #6b7280;
  margin-right: 4px;
}

.HistorySnapshot__orderTable__header span > p:nth-child(2) {
  color: #111827;
}

.HistorySnapshot__orderTable__tableContainer {
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 2px #0000000f, 0 1px 3px #0000001a;
}

.HistorySnapshot__orderTable__tableContainer > table thead {
  border-top: none;
}

.HistorySnapshot__orderTable__tableContainer > table tbody tr:last-child {
  border-bottom: none;
}

.HistorySnapshot__orderTable__tableContainer > table tbody tr:nth-child(2n) {
  background-color: #fff;
}

.AccountList__filters {
  justify-content: space-between;
  align-items: end;
  margin-bottom: 1.5rem;
  display: flex;
}

.AccountList__filters__status {
  flex-direction: column;
  display: flex;
}

.AccountList__filters__status select {
  height: 39px;
}

.AccountDetail {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.AccountDetail__container {
  background-color: #f3f4f6;
  flex-grow: 1;
  padding: 24px !important;
}

.accountOverview {
  height: 100%;
  background-color: #fff;
  padding: 24px;
}

.accountOverview .text-primary {
  color: #3981ea;
}

.accountOverview .titleAndEditRow .editButton {
  cursor: pointer;
  color: #3981ea;
}

.accountOverview__nav {
  border-bottom: 1px solid #e5e7eb;
  place-content: center space-between;
  margin-top: 40px;
  padding: .5rem 0;
  display: flex;
}

.accountOverview__nav .editButton {
  height: 40px;
  display: flex;
}

.accountOverview__accountTopRow {
  justify-content: space-between;
  margin-bottom: 64px;
  display: flex;
}

.accountOverview__accountTopRow .accountInfo {
  width: 100%;
  max-width: 203px;
  margin: 8px;
  padding: 8px;
}

.accountOverview__accountTopRow .accountInfo__accountStatus {
  padding: 0 8px;
}

.accountOverview__accountTopRow .accountInfo__accessCode {
  background-color: #f3f4f6;
  border-radius: 5px;
  padding: 8px;
}

.accountOverview__accountTopRow .accountInfo__accessCode__codeContainer {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.accountOverview__accountTopRow .accountInfo__accessCode__codeContainer * {
  flex-grow: 1;
}

.accountOverview__accountTopRow .accountInfo__accessCode__codeContainer p {
  width: 100%;
  text-align: right;
  flex-grow: 1;
  margin: 0;
}

.accountOverview__accountTopRow .accountInfo__editButton {
  margin-top: 12px;
}

.accountOverview__accountTopRow .accountAddress {
  width: 100%;
  max-width: 280px;
  padding: 16px;
}

.accountOverview__accountTopRow .accountContacts {
  width: 100%;
  max-width: 296px;
}

.accountOverview__accountTopRow .accountContacts__list {
  height: 172px;
  background-color: #f3f4f6;
  border-radius: 5px;
  overflow-y: scroll;
}

.accountOverview__accountTopRow .accountContacts__list::-webkit-scrollbar-thumb {
  display: none;
}

.accountOverview__accountTopRow .accountContacts__list::-webkit-scrollbar {
  width: 0;
}

.accountOverview__accountTopRow .accountContacts__list .contact {
  padding: 8px 16px;
}

.accountOverview__accountTopRow .accountContacts__list .contact .contactName {
  flex-direction: column;
  display: flex;
}

.accountOverview__accountTopRow .accountContacts__list .contact .contactName .name {
  color: #1f2937;
}

.accountOverview__accountTopRow .accountContacts__list .contact .contactName .position {
  color: #4b5563;
}

.accountOverview__accountTopRow .accountBilling {
  min-width: 280px;
  padding: 16px;
}

.accountOverview__accountBottomRow {
  justify-content: space-between;
  margin-bottom: 64px;
  display: flex;
}

.accountOverview__accountBottomRow .accountNotes {
  width: 407px;
}

.accountOverview__accountBottomRow .accountNotes .tabNav {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 4px;
  display: flex;
}

.accountOverview__accountBottomRow .accountNotes .tabContent {
  width: 100%;
  background-color: #f3f4f6;
  justify-content: center;
  align-items: center;
  padding: 24px;
  animation: 1s fadeEffect;
  display: flex;
}

.accountOverview__accountBottomRow .accountNotes .tabContent__notes {
  flex-grow: 1;
}

.accountOverview__accountBottomRow .creditAndTerms {
  width: 100%;
  max-width: 298px;
  max-height: 168px;
  padding: 0 8px 8px 16px;
}

.accountOverview__accountBottomRow .creditAndTerms .howApprovedText {
  text-align: right;
  max-width: 150px;
}

.accountOverview__accountBottomRow .orderApproval, .accountOverview__accountBottomRow .shippingPreference {
  width: 100%;
  max-width: 296px;
  max-height: 168px;
  padding: 0 8px 8px 16px;
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.accountOverview-table {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
}

.accountOverview-table .headerRow {
  color: #6b7280;
  height: 50px;
  font-size: 12px;
}

.accountOverview-table .tableRow {
  height: 50px;
  font-size: 14px;
}

.accountOverview-table td {
  border-bottom: 1px solid #e5e7eb;
}

.accountOverview-table tr:last-child td {
  border: none;
}

.AccountNav {
  width: 100%;
  display: flex;
}

.AccountNav .Nav {
  flex-grow: 1;
}

.AccountNav .Nav__spacer {
  display: none;
}

.AccountNav__content {
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.AccountNav__content__links {
  display: flex;
}

.AccountNav__slash {
  width: 24px;
  height: 36px;
  background: linear-gradient(-80deg, #fff, #fff 50%, #d1d5db 50% calc(50% + 2px), #fff calc(50% + 2px));
  margin: 0 8px;
}

.AccountNav__overviewLink {
  margin-right: 8px;
  padding: 0 8px;
  color: #6b7280 !important;
}

.AccountNav__overviewLink:hover {
  text-decoration: none;
}

.AccountNav__popover {
  width: max-content;
  flex-direction: column;
  display: flex;
  top: 40px;
  padding: 24px !important;
}

.AccountNav__popover *, .AccountNav__popover :hover {
  text-decoration: none;
}

.AccountNav__popover :last-child {
  margin-top: 8px;
}

.AccountNav__searchPopover {
  position: relative;
}

.AccountNav__searchPopover .Popover {
  width: 320px;
  left: 0;
}

.AccountNav__searchPopover .Popover > .Menu {
  max-height: 500px;
  overflow: auto;
}

.AccountNav__searchPopover .Popover > .Menu > button {
  min-height: 37px;
}

.AddContactModal .Modal__content {
  max-width: 580px;
  padding: 0;
}

.AddContactModal__header {
  text-align: center;
  background-color: #f3f4f6;
  padding: 1.5rem;
}

.AddContactModal__body {
  padding: 2rem;
}

.AddContactModal__warning {
  background-color: #fefce8;
  gap: 1rem;
  margin: 1rem 0;
  padding: 1rem;
  display: flex;
}

.AddContactModal__warning__icon svg {
  color: #facc15;
  width: 20px;
}

.AddContactModal__warning__message {
  color: #a16207;
}

.AddContactModal__warning__message__title {
  color: #854d0e;
  font-weight: 500;
}

.AddContactModal__field {
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  display: flex;
}

.AddContactModal__field__label {
  color: #6b7280;
}

.AddContactModal__footer {
  border-top: 1px solid #e9ecef;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem 1.5rem;
  display: flex;
}

.EditAccountModal .Modal__content {
  width: 512px;
  padding: 0;
  overflow: visible !important;
}

.EditAccountModal__header {
  text-align: center;
  background-color: #f3f4f6;
  padding: 1.5rem;
}

.EditAccountModal__body {
  padding: 1.5rem;
}

.EditAccountModal__field {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.EditAccountModal__field__label {
  color: #374151;
}

.EditAccountModal__footer {
  border-top: 1px solid #e9ecef;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1.25rem 1.5rem;
  display: flex;
}

.AccessCodeModal {
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
}

.AccessCodeModal__header {
  width: 100%;
  height: 64px;
  background-color: #f9fafb;
  border-radius: 8px 8px 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.AccessCodeModal__header > p {
  color: #111827;
  margin: 0;
  font-size: 18px;
}

.AccessCodeModal__body {
  max-height: calc(100% - 64px);
  flex-grow: 1;
  display: flex;
}

.AccessCodeModal__body .Popover {
  max-height: 300px;
  overflow: auto;
}

.AccessCodeModal__codes {
  height: 100%;
  width: 526px;
  border-right: 2px solid #e5e7eb;
  flex-direction: column;
  display: flex;
}

.AccessCodeModal__codes p {
  color: #374151;
  margin: 0;
}

.AccessCodeModal__codes__container {
  flex-direction: column;
  padding: 24px 12px 12px;
  display: flex;
  overflow: auto;
}

.AccessCodeModal__codes__header, .AccessCodeModal__codes__row {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
  display: flex;
}

.AccessCodeModal__codes__header .Input__container .Input, .AccessCodeModal__codes__row .Input__container .Input {
  width: 100%;
}

.AccessCodeModal__codes__header > :nth-child(1), .AccessCodeModal__codes__row > :nth-child(1) {
  width: 120px;
  margin-right: 16px;
}

.AccessCodeModal__codes__header > :nth-child(2), .AccessCodeModal__codes__row > :nth-child(2) {
  flex-grow: 1;
  margin-right: 16px;
}

.AccessCodeModal__codes__row--selected {
  background-color: #f0f6fe;
}

.AccessCodeModal__codes__addCode {
  width: 100%;
  justify-content: flex-end;
  padding: 12px;
  display: flex;
}

.AccessCodeModal__products {
  flex-direction: column;
  flex-grow: 1;
  padding: 28px;
  display: flex;
  overflow: auto;
}

.AccessCodeModal__products > :not(:last-child) {
  margin-bottom: 16px;
}

.AccessCodeModal__products__row {
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.AccessCodeModal__products__row p {
  color: #374151;
  margin: 0;
}

.AccessCodeModal__products__row__productId:not(:last-child) {
  flex-grow: 1;
}

.AccessCodeModal__products__row__productId:not(:last-child) > :first-child {
  margin-bottom: 4px;
}

.AccessCodeModal__products__row__active {
  flex-direction: column;
  align-items: center;
  margin: 0 32px;
  display: flex;
}

.AccessCodeModal__products__row__active > :first-child {
  margin-bottom: 4px;
}

.AccessCodeModal__products__row__photos {
  align-items: center;
  display: flex;
}

.AccessCodeModal__products__row__photos__photo {
  width: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.AccessCodeModal__products__row__photos__photo > img {
  max-height: 76px;
  max-width: 150px;
}

.AccessCodeModal__products__row__photos > :not(:last-child) {
  margin-right: 8px;
}

.AccessCodeModal__products__row__photos > .IconButton {
  position: relative !important;
}

.Displays__listPage__displayModuleInput {
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  display: flex;
}

.Displays__listPage__displayModuleInput__limitToGroups {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.Displays__listPage__displayModuleInput__limitToGroups__group {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Displays__listPage__displayModuleInput__limitToGroups__group > p {
  margin: 0;
}

.Displays__listPage__displayModuleInput__limitToGroups__group svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.Displays__listPage__displayModuleInput__groupVariations {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.Displays__listPage__displayModuleInput__groupVariations__variation {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Displays__listPage__displayModuleInput__groupVariations__variation > p {
  margin: 0;
}

.Displays__listPage__displayModuleInput__groupVariations__variation svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.DisplayModuleDetails {
  padding: 2rem;
}

.DisplayModuleDetails__header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.DisplayModuleDetails__header__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}

.DisplayModuleDetails__content__title {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.DisplayModuleDetails__content__title > p {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 600;
}

.DisplayModuleDetails__rows {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.DisplayModuleDetails__row {
  background-color: #f3f4f6;
  border-radius: 6px;
  padding: 1.5rem;
  box-shadow: 0 1px 2px #00000040;
}

.DisplayModuleDetails__row__info p {
  margin: 0;
}

.DisplayModuleDetails__row__info__title {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.DisplayModuleDetails__row__info__title > p {
  margin-bottom: .5rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.DisplayModuleDetails__row__info__links {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.DisplayModuleDetails__row__info__links > a {
  text-decoration: none;
}

.DisplayModuleDetails__row__info__links > a:last-child {
  color: #d9515e;
}

.DisplayModuleDetails__row__items {
  align-items: center;
  gap: .5rem;
  margin-top: 1rem;
  display: flex;
  overflow-x: auto;
}

.DisplayModuleDetails__row__item {
  background-color: #fff;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  margin-bottom: .5rem;
  padding: 1rem;
}

.DisplayModuleDetails__row__item p {
  margin: 0;
}

.DisplayModuleDetails__row__item__links {
  align-items: center;
  gap: .5rem;
  margin-top: 1rem;
  display: flex;
}

.DisplayModuleDetails__row__item__links > a {
  text-decoration: none;
}

.DisplayModuleDetails__row__item__links > a:not(:first-child) {
  color: #d9515e;
}

.DisplayInstance {
  padding: 2rem;
}

.DisplayInstance__header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.DisplayInstance__header__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}

.DisplayInstance__header__buttons {
  gap: 1rem;
  display: flex;
}

.DisplayInstance__tabs__container {
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.DisplayTemplate {
  padding: 2rem;
}

.DisplayTemplate__header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.DisplayTemplate__header__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}

.DisplayTemplate__tabs__container {
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.Displays {
  padding: 2rem;
}

.Displays__listPage {
  height: 100%;
  flex-direction: column;
  padding: 2rem;
  display: flex;
  overflow: auto;
}

.Displays__listPage__header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Displays__listPage__header__title {
  margin: 1rem 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}

.Displays__listPage .Pagination {
  bottom: -2rem !important;
}

.EditDisplayModal {
  width: 100%;
  color: #374151;
}

.EditDisplayModal button.Modal__close {
  color: #6b7280;
  position: absolute;
}

.EditDisplayModal .Modal__content {
  overflow-x: visible;
  overflow-y: auto;
}

.EditDisplayModal .Popover {
  right: 0;
}

.EditDisplayModal__header {
  text-align: center;
  background-color: #f9fafb;
}

.EditDisplayModal__header > h3 {
  color: #111827;
  margin: 0;
  padding: 18.5px 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
}

.EditDisplayModal__content {
  flex-direction: column;
  padding: 16px 24px;
  display: flex;
}

.EditDisplayModal__content label {
  color: #374151;
  margin: 0;
  line-height: 1.5;
}

.EditDisplayModal__content__field {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.EditDisplayModal__content__field--notes {
  align-items: flex-start;
  margin-top: 16px;
}

.EditDisplayModal__content__field--textboxNoResize {
  resize: none;
}

.EditDisplayModal__content__field .Input__container:has(.EditDisplayModal__content__field--textboxNoResize) {
  max-width: 50%;
}

.EditDisplayModal__content__field input, .EditDisplayModal__content__field .DatePicker__container .Input__container {
  width: 250px;
}

.EditDisplayModal__content__field .DatePicker__container .DatePicker {
  right: 0;
}

.EditDisplayModal__content__field__importFile {
  cursor: pointer;
  position: relative;
}

.EditDisplayModal__content__field__importFile > label {
  cursor: pointer;
  padding: 7px 28px;
}

.EditDisplayModal__content__field__importFile > input {
  opacity: 0;
  z-index: -100;
  position: absolute;
  right: 0;
}

.EditDisplayModal__content__field:not(:last-child) {
  margin-bottom: 16px;
}

.EditDisplayModal__content__divider {
  width: 100%;
  height: 1px;
  background-color: #e5e7eb;
}

.EditDisplayModal__buttons {
  justify-content: flex-end;
  padding: 20px 24px;
  display: flex;
}

.EditDisplayModal__buttons button {
  width: 130px;
}

.EditDisplayModal__buttons button:last-child {
  width: 260px;
}

.EditDisplayModal__buttons button:not(:last-child) {
  margin-right: 16px;
}

.DisplayReorderDrawerPanel__section {
  align-items: start;
  gap: .5rem;
  display: grid;
}

.DisplayReorderDrawerPanel__position {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.DisplayReorderDrawerPanel__position__input {
  width: 35px;
  height: 32px;
  text-align: center;
  font-weight: 500;
  color: #000 !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.DisplayReorderDrawerPanel__position__input--blue {
  background-color: #ddebfc !important;
}

.DisplayReorderDrawerPanel__position__input--yellow {
  background-color: #fef9ce !important;
}

.DisplayReorderDrawerPanel__position__input--red {
  background-color: #f7d1d4 !important;
}

.DisplayReorderDrawerPanel__position__label {
  color: #4b5563 !important;
}

.DisplayReorderDrawerPanel__position--first {
  grid-column-start: 1;
}

.DisplayReorderDrawer {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  flex-direction: column;
  display: flex;
  position: relative;
}

.DisplayReorderDrawer--edgeHit {
  animation: .8s forwards edgeHit;
}

@keyframes edgeHit {
  0%, 50%, 100% {
    background-color: #fff;
  }

  25%, 75% {
    background-color: #d9515e;
  }
}

.DisplayReorderDrawer p {
  color: #374151;
  margin: 0;
  line-height: 1.5;
}

.DisplayReorderDrawer > svg {
  color: #6b7280;
  width: 24px;
  position: absolute;
  top: 14px;
  right: 14px;
}

.DisplayReorderDrawer__header {
  flex: none;
  justify-content: space-between;
  align-items: center;
  padding: 12px 40px;
  display: flex;
}

.DisplayReorderDrawer__header__title > p:first-child {
  font-size: 18px;
}

.DisplayReorderDrawer__header__title > p:nth-child(2) {
  color: #6b7280;
}

.DisplayReorderDrawer__header__title > p:nth-child(2) > span {
  color: #111827;
}

.DisplayReorderDrawer__header__totals {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.DisplayReorderDrawer__header__totals__fractionTotal {
  min-width: 40px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.DisplayReorderDrawer__header__totals__fractionTotal > p:first-child {
  text-align: center;
  color: #6b7280;
  border-bottom: 1px solid #e5e7eb;
  align-self: stretch;
}

.DisplayReorderDrawer__header__totals__fractionTotal > p:last-child {
  padding: 0 6px;
}

.DisplayReorderDrawer__header__totals__fractionTotal:last-child > p {
  color: #111827;
  padding: 0 12px;
}

.DisplayReorderDrawer__header__totals__group {
  margin-right: 24px;
  display: flex;
}

.DisplayReorderDrawer__subheader {
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
}

.DisplayReorderDrawer__navigation {
  justify-content: flex-end;
  align-items: center;
  gap: 1rem 2rem;
  padding: 8px 40px;
  display: flex;
}

.DisplayReorderDrawer__navigation--sections, .DisplayReorderDrawer__navigation--panels {
  flex-direction: column;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.DisplayReorderDrawer__navigation--sections > p, .DisplayReorderDrawer__navigation--panels > p {
  color: #000;
  font-weight: 500;
}

.DisplayReorderDrawer__navigation--sections > ul, .DisplayReorderDrawer__navigation--panels > ul {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  overflow: hidden;
}

.DisplayReorderDrawer__navigation--sections > ul li, .DisplayReorderDrawer__navigation--panels > ul li {
  cursor: pointer;
  width: 40px;
  -webkit-user-select: none;
  user-select: none;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: .5rem 0;
  transition: all .3s;
  display: flex;
}

.DisplayReorderDrawer__navigation--sections > ul li:not(:last-child), .DisplayReorderDrawer__navigation--panels > ul li:not(:last-child) {
  border-right: 1px solid #d1d5db;
}

.DisplayReorderDrawer__navigation--sections > ul li.DisplayReorderDrawer__activeListItem, .DisplayReorderDrawer__navigation--panels > ul li.DisplayReorderDrawer__activeListItem {
  background-color: #f3f4f6;
  box-shadow: inset 0 1px 2px #0000001a;
}

.DisplayReorderDrawer__applyToAll {
  padding: 8px 40px;
  display: flex;
}

.DisplayReorderDrawer__applyToAll .Input__container {
  width: 50px;
  overflow: hidden;
}

.DisplayReorderDrawer__applyToAll .Input__container .Input {
  width: 50px;
  text-align: center;
  box-shadow: none;
  border-radius: 4px 0 0 4px;
}

.DisplayReorderDrawer__applyToAll > .Button--variant--outlined {
  width: max-content;
  border: 1px solid #d1d5db;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.DisplayReorderDrawer__body {
  flex-direction: column;
  flex: auto;
  padding: 8px 40px;
  display: flex;
  overflow: auto;
}

.DisplayReorderDrawer__buttons {
  border-top: 1px solid #e5e7eb;
  flex: none;
  justify-content: space-between;
  padding: 20px 24px;
  display: flex;
}

.DisplayReorderDrawer__buttons > span > :not(:last-child) {
  margin-right: 16px;
}

.DisplayPanel {
  padding: 2rem;
}

.DisplayPanel__content__header {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.DisplayPanel__content__header__panelInfo {
  flex-direction: column;
  display: flex;
}

.DisplayPanel__content__header__panelInfo > p {
  margin: 0;
}

.DisplayPanel__content__header__links {
  gap: .75rem;
  display: flex;
}

.DisplayPanel__content__header__links > a {
  color: #3981ea;
  text-decoration: none;
}

.DisplayPanel__content__header__links > a:last-child {
  color: #d9515e;
}

.DisplayPanel__sectionTitle {
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0;
  display: flex;
}

.DisplayPanel__sectionTitle__label {
  margin: 0;
  font-size: 1.3rem;
  font-weight: bold;
}

.DisplayPanel__sectionTitle__buttons {
  gap: .75rem;
  display: flex;
}

.DisplayPanel__sections {
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.DisplayPanel__sections__title {
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  display: flex;
}

.DisplayPanel__sections__title > p {
  margin: 0;
  font-size: 1.3rem;
  font-weight: bold;
}

.DisplayPanel__sections__title > span {
  gap: .75rem;
  display: flex;
}

.DisplayPanel__sections__title > span > a:not(:first-child) {
  color: #d9515e;
}

.DisplayPanel__section {
  background-color: #f3f4f6;
  border-radius: 6px;
  padding: 1rem;
  box-shadow: 0 1px 2px #00000040;
}

.DisplayPanel__section__info {
  margin: 0;
}

.DisplayPanel__section__info:last-of-type {
  margin-bottom: 1rem;
}

.DisplayPanel__modules {
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.DisplayPanel__modules__title {
  margin: 0;
  font-size: 1.3rem;
  font-weight: bold;
}

.DisplayPanel__modules__button {
  align-self: flex-start;
}

.DisplayModule {
  background-color: #d1d5db;
  border-radius: 6px;
  padding: 1rem;
  box-shadow: 0 1px 2px #00000040;
}

.DisplayModule__title {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.DisplayModule__title > p {
  margin: 0;
  font-size: 1.1rem;
  font-weight: bold;
}

.DisplayModule__title > a {
  color: #d9515e;
  text-decoration: none;
}

.DisplayModule__rows {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.DisplayModule__row {
  align-items: flex-start;
  gap: 1rem;
  margin: .5rem 0;
  display: flex;
  overflow-x: auto;
}

.DisplayModule__item {
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  gap: 1.5rem;
  padding: 1.5rem;
  display: flex;
  position: relative;
}

.DisplayModule__item > p {
  margin: 0;
}

.DisplayModule__item__type {
  text-transform: capitalize;
  font-weight: bold;
}

.DisplayModule__item__corner {
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(0 0, 40px 0, 0 35px);
  clip-path: polygon(0 0, 40px 0, 0 35px);
  background-color: #306ce0;
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
}

.DisplayModule__item__corner > p {
  color: #fff;
  margin: 0;
  position: absolute;
  top: 0;
  left: 4px;
}

.DisplayModule__item__product {
  margin-bottom: 1rem !important;
}

.DisplayModule__item__variables {
  width: 100%;
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.DisplayModule__item__variable {
  width: 100%;
  align-items: center;
  gap: .25rem;
  display: flex;
}

.DisplayModule__item__variable > p {
  text-transform: capitalize;
  margin: 0;
}

.DisplayModule__item__variable__key {
  font-weight: bold;
}

.DisplayModule__item__links {
  gap: .5rem;
  display: flex;
}

.DisplayModule__item__links > a:last-child {
  color: #d9515e;
}

.DisplayHistoryDrawer {
  height: 100vh;
  flex-direction: column;
  display: flex;
}

.DisplayHistoryDrawer__content table thead, .DisplayHistoryDrawer__content table tr {
  border: none;
}

.EditModuleModal .Modal__content {
  width: 500px;
  flex-direction: column;
  gap: 1rem;
  display: flex;
  overflow: visible;
}

.EditModuleModal .Modal__content > p {
  margin: 0;
}

.EditModuleModal .Modal__content > p:first-of-type {
  font-size: 1.3rem;
  font-weight: bold;
}

.EditModuleModal .Modal__content .EditModuleModal__input {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.EditModuleModal .Modal__content .EditModuleModal__buttons {
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.EditModuleModal .Modal__content .EditModuleModal__buttons:last-of-type {
  margin-top: 1rem;
}

.EditTemplateModal .Modal__content {
  width: 500px;
  flex-direction: column;
  gap: 1rem;
  display: flex;
  overflow: visible;
}

.EditTemplateModal .Modal__content > p {
  margin: 0;
}

.EditTemplateModal .Modal__content > p:first-of-type {
  font-size: 1.3rem;
  font-weight: bold;
}

.EditTemplateModal .Modal__content .EditTemplateModal__input {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.EditTemplateModal .Modal__content .EditTemplateModal__buttons {
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.EditTemplateModal .Modal__content .EditTemplateModal__buttons:last-of-type {
  margin-top: 1rem;
}

.EditInstanceModal .Modal__content {
  width: 500px;
  flex-direction: column;
  gap: 1rem;
  display: flex;
  overflow: visible;
}

.EditInstanceModal .Modal__content > p {
  margin: 0;
}

.EditInstanceModal .Modal__content > p:first-of-type {
  font-size: 1.3rem;
  font-weight: bold;
}

.EditInstanceModal .Modal__content .EditInstanceModal__input {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.EditInstanceModal .Modal__content .EditInstanceModal__buttons {
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.EditInstanceModal .Modal__content .EditInstanceModal__buttons:last-of-type {
  margin-top: 1rem;
}

.CreateTemplateModal .Modal__content {
  width: 500px;
  flex-direction: column;
  gap: 1rem;
  display: flex;
  overflow: visible;
}

.CreateTemplateModal .Modal__content > p {
  margin: 0;
}

.CreateTemplateModal .Modal__content > p:first-of-type {
  font-size: 1.3rem;
  font-weight: bold;
}

.CreateTemplateModal .Modal__content .CreateTemplateModal__input {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.CreateTemplateModal .Modal__content .CreateTemplateModal__buttons {
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.CreateTemplateModal .Modal__content .CreateTemplateModal__buttons:last-of-type {
  margin-top: 1rem;
}

.CreateModuleModal .Modal__content {
  width: 500px;
  flex-direction: column;
  gap: 2rem;
  display: flex;
  overflow: visible;
}

.CreateModuleModal .Modal__content > p {
  margin: 0;
}

.CreateModuleModal .Modal__content > p:first-of-type {
  font-size: 1.3rem;
  font-weight: bold;
}

.CreateModuleModal .Modal__content .CreateModuleModal__input {
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.CreateModuleModal .Modal__content .CreateModuleModal__input__limitToGroups {
  flex-direction: column;
  align-items: flex-end;
  gap: .5rem;
  display: flex;
}

.CreateModuleModal .Modal__content .CreateModuleModal__input__limitToGroups__group {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.CreateModuleModal .Modal__content .CreateModuleModal__input__limitToGroups__group > p {
  margin: 0;
}

.CreateModuleModal .Modal__content .CreateModuleModal__input__limitToGroups__group svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.CreateModuleModal .Modal__content .CreateModuleModal__input__groupVariations {
  flex-direction: column;
  align-items: flex-end;
  gap: .5rem;
  display: flex;
}

.CreateModuleModal .Modal__content .CreateModuleModal__input__groupVariations__variation {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.CreateModuleModal .Modal__content .CreateModuleModal__input__groupVariations__variation > p {
  margin: 0;
}

.CreateModuleModal .Modal__content .CreateModuleModal__input__groupVariations__variation svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.CreateModuleModal .Modal__content .CreateModuleModal__buttons {
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.CreateModuleModal .Modal__content .CreateModuleModal__buttons:last-of-type {
  margin-top: 1rem;
}

.CreateInstanceModal .Modal__content {
  width: 500px;
  flex-direction: column;
  gap: 1rem;
  display: flex;
  overflow: visible;
}

.CreateInstanceModal .Modal__content > p {
  margin: 0;
}

.CreateInstanceModal .Modal__content > p:first-of-type {
  font-size: 1.3rem;
  font-weight: bold;
}

.CreateInstanceModal .Modal__content .CreateInstanceModal__input {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.CreateInstanceModal .Modal__content .CreateInstanceModal__buttons {
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.CreateInstanceModal .Modal__content .CreateInstanceModal__buttons:last-of-type {
  margin-top: 1rem;
}

.DisplayModel__confirmationModal__title {
  margin: 0;
  font-size: 1.3rem;
  font-weight: bold;
}

.DisplayModel__confirmationModal .Modal__content {
  width: 500px;
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.DisplayModel__confirmationModal .Modal__content > span {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.DisplayInstance__applyNamelistModal .Modal__content {
  width: 500px;
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.DisplayInstance__applyNamelistModal .Modal__content > p {
  margin: 0;
  font-size: 1.3rem;
  font-weight: bold;
}

.DisplayInstance__applyNamelistModal .Modal__content .DisplayInstance__applyNamelistModal__form {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.DisplayInstance__applyNamelistModal .Modal__content .DisplayInstance__applyNamelistModal__buttons {
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  display: flex;
}

.DisplayModel__addSectionModal .Modal__content {
  width: 500px;
}

.DisplayModel__addSectionModal .Modal__content > p {
  margin: 0;
  font-size: 1.3rem;
  font-weight: bold;
}

.DisplayModel__addSectionModal .Modal__content > span {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.DisplayModel__addSectionModal .Modal__content > span:last-of-type {
  margin-top: 1rem;
}

.DisplayModule__addRowModal .Modal__content {
  width: 500px;
  flex-direction: column;
  gap: 1rem;
  display: flex;
  overflow: visible;
}

.DisplayModule__addRowModal .Modal__content > p {
  margin: 0;
}

.DisplayModule__addRowModal .Modal__content > p:first-of-type {
  font-size: 1.3rem;
  font-weight: bold;
}

.DisplayModule__addRowModal .Modal__content .DisplayModule__addRowModal__input {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.DisplayModule__addRowModal .Modal__content .DisplayModule__addRowModal__buttons {
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.DisplayModule__addRowModal .Modal__content .DisplayModule__addRowModal__buttons:last-of-type {
  margin-top: 1rem;
}

.DisplayInstance__addProductToAllModal .Modal__content {
  width: 500px;
  flex-direction: column;
  gap: 1rem;
  display: flex;
  overflow: visible;
}

.DisplayInstance__addProductToAllModal .Modal__content > p {
  margin: 0;
}

.DisplayInstance__addProductToAllModal .Modal__content > p:first-of-type {
  font-size: 1.3rem;
  font-weight: bold;
}

.DisplayInstance__addProductToAllModal__form {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.DisplayInstance__addProductToAllModal__form__buttons {
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  display: flex;
}

.DisplayInstance__addProductToAllModal__variables {
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0 1rem;
  display: flex;
}

.DisplayInstance__addProductToAllModal__variables > p {
  margin: 0;
  font-size: 1.2rem;
}

.DisplayInstance__addProductToAllModal__variable {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.DisplayInstance__addProductToAllModal__variable > p {
  margin: 0;
}

.DisplayInstance__addProductToAllModal__variable__value {
  flex: 1;
}

.DisplayInstance__addProductToAllModal__variable__radio {
  gap: .5rem;
  display: flex;
  overflow-x: auto;
}

.DisplayInstance__addProductToAllModal__variable__radio__option {
  flex-shrink: 0;
}

.DisplayInstance__addProductToAllModal__variable__radio__option > img {
  width: 8rem;
  border: 2px solid #0000;
  border-radius: 3px;
  box-shadow: 0 1px 2px #00000040;
}

.DisplayInstance__addProductToAllModal__variable__radio__option > input {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.DisplayInstance__addProductToAllModal__variable__radio__option > input:checked ~ img {
  border: 2px solid #3981ea;
}

.DisplayInstance__addProductModal .Modal__content {
  width: 500px;
  flex-direction: column;
  gap: 1rem;
  display: flex;
  overflow: visible;
}

.DisplayInstance__addProductModal .Modal__content > p {
  margin: 0;
}

.DisplayInstance__addProductModal .Modal__content > p:first-of-type {
  font-size: 1.3rem;
  font-weight: bold;
}

.DisplayInstance__addProductModal__form {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.DisplayInstance__addProductModal__form__buttons {
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  display: flex;
}

.DisplayInstance__addProductModal__variables {
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0 1rem;
  display: flex;
}

.DisplayInstance__addProductModal__variables > p {
  margin: 0;
  font-size: 1.2rem;
}

.DisplayInstance__addProductModal__variable {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.DisplayInstance__addProductModal__variable > p {
  margin: 0;
}

.DisplayInstance__addProductModal__variable__value {
  flex: 1;
}

.DisplayInstance__addProductModal__variable__radio {
  gap: .5rem;
  display: flex;
  overflow-x: auto;
}

.DisplayInstance__addProductModal__variable__radio__option {
  flex-shrink: 0;
}

.DisplayInstance__addProductModal__variable__radio__option > img {
  width: 8rem;
  border: 2px solid #0000;
  border-radius: 3px;
  box-shadow: 0 1px 2px #00000040;
}

.DisplayInstance__addProductModal__variable__radio__option > input {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.DisplayInstance__addProductModal__variable__radio__option > input:checked ~ img {
  border: 2px solid #3981ea;
}

.DisplayModel__addPanelModal .Modal__content {
  width: 500px;
}

.DisplayModel__addPanelModal .Modal__content > p {
  margin: 0;
  font-size: 1.3rem;
  font-weight: bold;
}

.DisplayModel__addPanelModal .Modal__content > span {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.DisplayModel__addPanelModal .Modal__content > span:last-of-type {
  margin-top: 1rem;
}

.DisplayModel__addModuleModal .Modal__content {
  width: 500px;
  flex-direction: column;
  gap: 1rem;
  display: flex;
  overflow: visible;
}

.DisplayModel__addModuleModal .Modal__content > p {
  margin: 0;
}

.DisplayModel__addModuleModal .Modal__content > p:first-of-type {
  font-size: 1.3rem;
  font-weight: bold;
}

.DisplayModel__addModuleModal .Modal__content > span {
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.DisplayModel__addModuleModal .Modal__content > span:last-of-type {
  margin-top: 1rem;
}

.DisplayModule__addItemModal .Modal__content {
  width: 500px;
  flex-direction: column;
  gap: 1rem;
  display: flex;
  overflow: visible;
}

.DisplayModule__addItemModal .Modal__content > p {
  margin: 0;
}

.DisplayModule__addItemModal .Modal__content > p:first-of-type {
  font-size: 1.3rem;
  font-weight: bold;
}

.DisplayModule__addItemModal .Modal__content .DisplayModule__addItemModal__input {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.DisplayModule__addItemModal .Modal__content .DisplayModule__addItemModal__buttons {
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.DisplayModule__addItemModal .Modal__content .DisplayModule__addItemModal__buttons:last-of-type {
  margin-top: 1rem;
}

.EditAccountNotesModal .Modal__content {
  width: 512px;
  padding: 0;
}

.EditAccountNotesModal__header {
  text-align: center;
  background-color: #f3f4f6;
  padding: 1.5rem;
}

.EditAccountNotesModal .modalTextArea {
  width: 100%;
  resize: none;
}

.EditAccountNotesModal__body {
  padding: 2rem;
}

.EditAccountNotesModal__footer {
  border-top: 1px solid #e9ecef;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1.5rem;
  display: flex;
}

.ShippingModal .Modal__content {
  width: 512px;
  padding: 0;
}

.ShippingModal__header {
  text-align: center;
  background-color: #f3f4f6;
  padding: 1.5rem;
}

.ShippingModal__body {
  padding: 2rem;
}

.ShippingModal__field {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.ShippingModal__field__label {
  color: #6b7280;
}

.ShippingModal__footer {
  border-top: 1px solid #e9ecef;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1.5rem;
  display: flex;
}

.OrderModal .Modal__content {
  width: 512px;
  padding: 0;
}

.OrderModal__header {
  text-align: center;
  background-color: #f3f4f6;
  padding: 1.5rem;
}

.OrderModal__body {
  padding: 2rem;
}

.OrderModal__field {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.OrderModal__field__label {
  color: #6b7280;
}

.OrderModal__footer {
  border-top: 1px solid #e9ecef;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1.5rem;
  display: flex;
}

.EditContact .Modal__content {
  max-width: 704px;
  padding: 0;
}

.EditContact__header {
  text-align: center;
  background-color: #f3f4f6;
  padding: 1.5rem;
}

.EditContact__body {
  padding: 1.5rem;
}

.EditContact__user {
  border: 1px solid #e9ecef;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  display: flex;
}

.EditContact__warning {
  background-color: #fefce8;
  gap: 1rem;
  margin: 1rem 0;
  padding: 1rem;
  display: flex;
}

.EditContact__warning__icon span {
  color: #facc15;
  width: 20px;
}

.EditContact__warning__message {
  color: #a16207;
}

.EditContact__warning__message__title {
  color: #854d0e;
  font-weight: 500;
}

.EditContact .contactInfoRow {
  border: 1px solid #e9ecef;
  margin-top: 1rem;
  padding: 2rem;
}

.EditContact .contactInfoRow__header {
  margin-bottom: 36px;
}

.EditContact .contactInfoRow .contactPropertyText {
  width: 100%;
  max-width: 200px;
  margin-right: 8px;
}

.EditContact .contactInfoRow .contactInfo {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  display: flex;
}

.EditContact .contactInfoRow .contactInfo .contact_text {
  flex-grow: 1;
}

.EditContact__footer {
  border-top: 1px solid #e9ecef;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem 1.5rem;
  display: flex;
}

.CreditModal .Modal__content {
  width: 512px;
  padding: 0;
}

.CreditModal__header {
  text-align: center;
  background-color: #f3f4f6;
  padding: 1.5rem;
}

.CreditModal__body {
  padding: 2rem;
}

.CreditModal__field {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.CreditModal__field__label {
  color: #6b7280;
}

.CreditModal__field textarea {
  resize: none;
}

.CreditModal__footer {
  border-top: 1px solid #e9ecef;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem 1.5rem;
  display: flex;
}

.Toaster {
  min-width: 280px;
  z-index: 100;
  position: fixed;
  bottom: 64px;
  left: 50%;
  transform: translateX(-50%);
}

.Toast {
  cursor: pointer;
  width: 100%;
  background-color: #fff;
  border-left: 12px solid #4b5563;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 1rem;
  display: flex;
  box-shadow: 0 2px 6px #00000040;
}

.Toast--info {
  border-left-color: #3981ea;
}

.Toast--success {
  border-left-color: #22c55e;
}

.Toast--warning {
  border-left-color: #bf602b;
}

.Toast--error {
  border-left-color: #d9515e;
}

.reset-password-hint {
  margin-bottom: 1.5rem;
}

.reset-password-hint li {
  font-size: 12px;
  font-weight: 600;
}

.SideNav {
  color: #fff;
  height: 100%;
  background-color: #2b2d42;
  flex-direction: column;
  display: flex;
}

.SideNav__content {
  width: 208px;
  flex-direction: column;
  flex-grow: 1;
  padding: .5rem;
  display: flex;
}

.SideNav__content > svg {
  width: 100%;
  height: 24px;
  color: #6b7280;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  margin: 0 auto 1rem;
}

.SideNav__user {
  background-color: #353857;
  align-items: center;
  gap: .75rem;
  margin: 0 -.5rem;
  padding: 1rem;
  display: flex;
}

.SideNav__user__avatar {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.SideNav__user__text {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.SideNav__user__text > p {
  margin: 0;
  font-weight: 500;
}

.SideNav__user__text > a {
  color: #9ca3af;
  margin: 0;
  font-size: 12px;
  line-height: 1;
  text-decoration: none;
}

.SideNav__userCollapsed {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 36px;
  display: flex;
}

.SideNav__userCollapsed svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.SideNav__userCollapsed__avatar {
  height: 40px;
  width: 40px;
  background-color: #3d4167;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.SideNav__logoContainer {
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  display: flex;
}

.SideNav__logoContainer img {
  max-height: 46px;
}

.SideNav__logo {
  max-width: 128px;
  -webkit-user-select: none;
  user-select: none;
}

.SideNav hr {
  opacity: .5;
  border: 1px solid #111827;
}

.SideNav__links {
  flex-grow: 1;
  margin-top: 1rem;
}

.SideNav__link {
  border-radius: 6px;
  align-items: center;
  gap: .75rem;
  padding: .5rem;
  display: flex;
  color: #fff !important;
  text-decoration: none !important;
}

.SideNav__link:not(:last-of-type) {
  margin-bottom: 4px;
}

.SideNav__link--active {
  background-color: #2f4674;
}

.SideNav__link svg, .SideNav__link img {
  width: 24px;
}

.SideNav__contentCollapsed {
  width: unset;
}

.SideNav__contentCollapsed .SideNav__link {
  flex-direction: column;
}

.SideNav__contentCollapsed .SideNav__link > svg {
  margin-right: 0;
}

.SideNav__contentCollapsed .SideNav__link > div {
  display: none;
}

.Pagination {
  background-color: #fff;
  align-items: center;
  gap: 1rem;
  padding: .75rem 1.5rem;
  display: flex;
  position: sticky;
  bottom: 0;
}

.Pagination__spacer {
  flex: 1;
}

.Pagination__buttons {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.Pagination__buttons input {
  width: 64px;
}

.Nav {
  background-color: #2b2d42;
}

.Nav--loggedIn {
  background-color: #fff;
}

.Nav__title {
  color: #374151;
  font-size: 22px;
}

.Nav__logo {
  max-width: 132px;
  -webkit-user-select: none;
  user-select: none;
}

.Nav__content {
  flex-direction: row;
  align-items: center;
  padding: 12px 0;
  display: flex;
  position: relative;
}

.Nav__spacer {
  flex: 1;
}

.Nav__item {
  margin-left: 1.5rem;
  position: relative;
}

.Nav__item__link {
  color: #6b7280;
  cursor: pointer;
  align-items: center;
  gap: .5rem;
  font-weight: 500;
  display: flex;
}

.Nav__item__link a {
  color: #6b7280;
  text-decoration: none;
}

.Nav__item__link svg {
  width: 16px;
}

.ListPageHeader {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.ListPageHeader__date {
  font-size: 16px;
}

.ConfirmationModal .Modal__content {
  padding: 0;
}

.ConfirmationModal__header {
  width: 100%;
  background-color: #f3f4f6;
  padding: 20px 0;
}

.ConfirmationModal__header p {
  text-align: center;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
}

.ConfirmationModal__body {
  flex-direction: column;
  gap: 2rem;
  padding: 1rem 2rem 2rem;
  display: flex;
}

.ConfirmationModal__body > p {
  color: #374151;
  font-size: 1.125rem;
}

.ConfirmationModal__buttons {
  align-self: flex-end;
  gap: 1rem;
  display: flex;
}

.Toggle__container {
  cursor: pointer;
  height: 24px;
  -webkit-user-select: none;
  user-select: none;
  gap: 12px;
  line-height: 24px;
  display: flex;
}

.Toggle__container input {
  display: none;
}

.Toggle__container:hover .Toggle:not(:checked):not(:disabled) ~ .Toggle__control {
  background-color: #9ca3af;
}

.Toggle__container:focus .Toggle:not(:checked):not(:disabled) ~ .Toggle__control, .Toggle__container:active .Toggle:not(:checked):not(:disabled) ~ .Toggle__control, .Toggle:checked ~ .Toggle__control {
  background-color: #3981ea;
}

.Toggle:checked ~ .Toggle__control:before {
  content: "";
  left: auto;
  right: 2px;
}

.Toggle:disabled ~ .Toggle__control {
  cursor: not-allowed;
  background-color: #6b7280;
}

.Toggle__control {
  height: 24px;
  width: 44px;
  background-color: #e9ecef;
  border-radius: 1rem;
  transition: all .1s ease-in-out;
  position: relative;
}

.Toggle__control:before {
  content: "";
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  top: 2px;
  left: 2px;
}

.Tabs {
  align-items: center;
  display: flex;
  overflow-x: auto;
}

.TabItem {
  color: #6b7280;
  cursor: pointer;
  border-bottom: 2px solid #0000;
  align-items: center;
  padding: .5rem 1rem;
  font-weight: 600;
  line-height: 1.5;
  transition: color .1s ease-in-out, border .1s ease-in-out;
  display: flex;
}

.TabItem:not(.TabItem--active):hover {
  color: #374151;
  border-bottom: 2px solid #d1d5db;
}

.TabItem--active {
  color: #3981ea;
  border-bottom: 2px solid #3981ea;
}

.Spinner:before {
  content: "";
  height: 36px;
  width: 36px;
  border: 6px solid #3981ea;
  border-color: #3981ea #e5e7eb;
  border-radius: 50%;
  animation: 2s linear infinite ss-spin;
  display: inline-block;
}

.Spinner--sm:before {
  height: 16px;
  width: 16px;
}

.Spinner__container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Spinner__message {
  color: #4b5563;
  margin-top: .5rem;
}

@keyframes ss-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}



.Radio__container {
  cursor: pointer;
  height: 14px;
  -webkit-user-select: none;
  user-select: none;
  margin-bottom: .5rem;
  padding-left: 1.5rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  display: block;
  position: relative;
}

.Radio__container input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  position: absolute;
}

.Radio__container:hover .Radio:not(:checked):not(:disabled) ~ .Radio__mark {
  background-color: #ddebfc;
  border-color: #6b7280;
}

.Radio__container:focus .Radio:not(:checked):not(:disabled) ~ .Radio__mark, .Radio__container:active .Radio:not(:checked):not(:disabled) ~ .Radio__mark {
  background-color: #c3dcfa;
  border-color: #6b7280;
}

.Radio:checked ~ .Radio__mark {
  background-color: #3981ea;
  border-color: #3981ea;
}

.Radio:checked ~ .Radio__mark:after {
  content: "";
  height: 6px;
  width: 6px;
  background-color: #fff;
  border-radius: 100%;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 3px;
  left: 3px;
}

.Radio:disabled ~ .Radio__mark {
  cursor: not-allowed;
  background-color: #d1d5db;
  border-color: #d1d5db;
}

.Radio__mark {
  height: 14px;
  width: 14px;
  border: 1px solid #e9ecef;
  border-radius: 100%;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.Popover {
  z-index: 101;
  background-color: #fff;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: .5rem 1rem;
  animation: .4s scaleIn;
  position: absolute;
  box-shadow: 0 1px 3px #00000080;
}

.Popover__overlay {
  z-index: 100;
  position: fixed;
  inset: 0;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.Modal {
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  height: 100%;
  width: 100%;
  z-index: 101;
  background-color: #2b2d4299;
  padding: 3rem;
  position: fixed;
  top: 0;
  left: 0;
}

.Modal__content {
  max-height: 100%;
  max-width: 1080px;
  min-height: 50px;
  min-width: 280px;
  z-index: 101;
  background-color: #fff;
  border-radius: 8px;
  margin: 0 auto;
  padding: 1.5rem;
  animation: .4s slideInTop;
  position: relative;
  overflow: auto;
  box-shadow: 0 10px 10px -5px #0000000a, 0 20px 25px -5px #0000001a;
}

.Modal__close {
  top: 1rem;
  right: 1rem;
  position: absolute !important;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideInTop {
  0% {
    transform: translateY(-100vh);
  }

  70% {
    transform: translateY(5vh);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideInBottom {
  0% {
    transform: translateY(100vh);
  }

  70% {
    transform: translateY(-5vh);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100vw);
  }

  70% {
    transform: translateX(5vw);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100vw);
  }

  70% {
    transform: translateX(-5vw);
  }

  100% {
    transform: translateX(0);
  }
}



.Menu {
  min-width: 140px;
  background-color: #fff;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  flex-direction: column;
  padding: 6px 0;
  display: flex;
}

.FormLabel {
  margin-bottom: .25rem;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  display: inline-block;
}

.FormGroup {
  max-width: 100%;
  flex-basis: 100%;
  margin-bottom: 1.5rem;
}

.Form {
  flex-flow: wrap;
  display: flex;
}

.Form .FormGroup:last-child {
  margin: 0;
}

.Drawer {
  height: 100vh;
  max-height: 100vh;
  width: 416px;
  z-index: 101;
  background-color: #fff;
  flex-direction: column;
  animation: .4s slideInRight;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: 0 8px 16px #00000026;
}

.Drawer__header {
  background-color: #f3f4f6;
  border-bottom: 1px solid #e5e7eb;
  align-items: center;
  padding: 1rem 1.5rem;
  display: flex;
}

.Drawer__header__spacer {
  flex: 1;
}

.Drawer__title {
  margin: 0;
  font-size: 18px;
}

.Drawer__content {
  flex-grow: 1;
  padding: 1.25rem 1.5rem;
  overflow: auto;
}

.Drawer__actions {
  border-top: 1px solid #e5e7eb;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.25rem 1.5rem;
  display: flex;
}

.Drawer__overlay {
  z-index: 100;
  position: fixed;
  inset: 0;
}

.Drawer__overlay--bg {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: #6b7280bf;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100vw);
  }

  100% {
    transform: translateX(0);
  }
}

.DatePicker {
  width: 280px;
  z-index: 101;
  background-color: #fff;
  border-radius: 4px;
  animation: .4s scaleIn;
  position: absolute;
  box-shadow: 0 8px 16px #00000026;
}

.DatePicker__container {
  position: relative;
}

.DatePicker__container .Input__container, .DatePicker__container .Input__container .Input {
  width: 100%;
}

.DatePicker__icon {
  cursor: pointer;
  z-index: 100;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.DatePicker__icon > svg {
  width: 20px;
  height: 20px;
  color: #4b5563;
  font-size: 20px;
}

.DatePicker__navigation {
  justify-content: space-between;
  align-items: center;
  padding: .25rem .5rem;
  display: flex;
  box-shadow: inset 0 -1px #00000021;
}

.DatePicker__month {
  margin: 0 1rem;
  font-size: 16px;
  line-height: 1.5;
}

.DatePicker__calendar {
  padding: .5rem;
}

.DatePicker__calendar table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.DatePicker__calendar table thead th {
  font-size: 14px;
  font-weight: 600;
  line-height: 2;
}

.DatePicker__calendar table tbody td {
  cursor: pointer;
  text-align: center;
  padding: .5rem 0;
  font-size: 14px;
  line-height: 2;
}

.DatePicker--selected {
  position: relative;
  color: #fff !important;
}

.DatePicker--selected:before {
  content: "";
  height: 32px;
  width: 32px;
  z-index: -1;
  background-color: #3981ea;
  border-radius: 100%;
  display: block;
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
}

.DatePicker--disabled {
  color: #9ca3af;
  background-color: #f3f4f6;
  cursor: not-allowed !important;
}

.DatePicker__overlay {
  z-index: 100;
  position: fixed;
  inset: 0;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.Input__container {
  display: inline-block;
  position: relative;
}

.Input__container.Input__container--fluid {
  width: 100%;
}

.Input__container .iconLeading {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(50%, -50%);
}

.Input__container .iconLeading svg {
  width: 18px;
  display: block;
}

.Input__container .iconTrailing {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.Input__container .iconTrailing svg {
  width: 18px;
  display: block;
}

.Input__container .iconButtonTrailing {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  width: 2rem;
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  transition: background-color .2s ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.Input__container .iconButtonTrailing svg {
  width: 16px;
  display: block;
}

.Input__container .iconButtonTrailing:hover {
  background-color: #e5e7eb;
}

.Input {
  background: #fff;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  outline: none;
  padding: 8px 12px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  transition: all .1s ease-in-out;
  box-shadow: 0 1px 2px #0000000d;
}

.Input::-webkit-input-placeholder {
  color: #6b7280;
}

.Input:-moz-placeholder {
  color: #6b7280;
}

.Input::-moz-placeholder {
  color: #6b7280;
}

.Input:-ms-input-placeholder {
  color: #6b7280;
}

.Input:disabled {
  cursor: not-allowed;
}

.Input.Input--fluid {
  width: 100%;
}

.Input.Input--iconLeading {
  padding-left: 2.5rem !important;
}

.Input.Input--iconTrailing {
  padding-right: 2.5rem !important;
}

.Input--textarea {
  max-width: 100%;
  min-height: 8rem;
  padding: 1rem;
}

.Input.Input--size--sm {
  padding: .25rem .75rem;
  font-size: .875rem;
}

.Input.Input--size--lg {
  padding: .75rem 1.25rem;
  font-size: 1.125rem;
}

.Input:hover {
  border: 1px solid #4b5563;
}

.Input:focus, .Input:active {
  border: 1px solid #3981ea;
}

.Input:invalid {
  border: 1px solid #d9515e;
}

.Input--filled {
  background: #f3f4f6;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  outline: none;
  padding: 8px 12px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  transition: all .1s ease-in-out;
  box-shadow: 0 1px 2px #0000000d;
}

.Input--filled::-webkit-input-placeholder {
  color: #6b7280;
}

.Input--filled:-moz-placeholder {
  color: #6b7280;
}

.Input--filled::-moz-placeholder {
  color: #6b7280;
}

.Input--filled:-ms-input-placeholder {
  color: #6b7280;
}

.Input--filled:disabled {
  cursor: not-allowed;
}

.Input--filled.Input--fluid {
  width: 100%;
}

.Input--filled.Input--iconLeading {
  padding-left: 2.5rem !important;
}

.Input--filled.Input--iconTrailing {
  padding-right: 2.5rem !important;
}

.Input--filled--textarea {
  max-width: 100%;
  min-height: 8rem;
  padding: 1rem;
}

.Input--filled.Input--size--sm {
  padding: .25rem .75rem;
  font-size: .875rem;
}

.Input--filled.Input--size--lg {
  padding: .75rem 1.25rem;
  font-size: 1.125rem;
}

.Input--filled:hover {
  background: #d1d5db;
  border: 1px solid #4b5563;
}

.Input--filled:focus, .Input--filled:active {
  background: #d1d5db;
  border: 1px solid #3981ea;
}

.Input--filled:invalid {
  border: 1px solid #d9515e;
}

.IconButton {
  -webkit-appearance: none;
  appearance: none;
  color: #111827;
  cursor: pointer;
  height: 40px;
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
  width: 40px;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 700;
  transition: all .1s ease-in-out;
  display: flex;
  position: relative;
  text-decoration: none !important;
}

.IconButton:disabled {
  cursor: not-allowed;
}

.IconButton svg {
  width: 24px;
}

.IconButton:hover {
  background-color: #f9fafb;
}

.IconButton:active {
  background-color: #f3f4f6;
}

.IconButton:disabled {
  color: #6b7280 !important;
}

.IconButton:disabled:hover, .IconButton:disabled:active {
  background-color: #0000 !important;
}

.IconButton.IconButton--color--primary {
  color: #306ce0;
}

.IconButton.IconButton--color--primary:hover {
  background-color: #f0f6fe;
}

.IconButton.IconButton--color--primary:active {
  background-color: #ddebfc;
}

.IconButton.IconButton--color--accent {
  color: #16a34a;
}

.IconButton.IconButton--color--accent:hover {
  background-color: #f0fdf4;
}

.IconButton.IconButton--color--accent:active {
  background-color: #dcfce7;
}

.IconButton.IconButton--color--warn {
  color: #c13741;
}

.IconButton.IconButton--color--warn:hover {
  background-color: #fdfcfa;
}

.IconButton.IconButton--color--warn:active {
  background-color: #fbf0ea;
}

.IconButton.IconButton--color--light {
  color: #f3f4f6;
}

.IconButton.IconButton--color--light:hover {
  background-color: #f9fafb;
}

.IconButton.IconButton--color--light:active {
  background-color: #f3f4f6;
}

.IconButton.IconButton--color--dark {
  color: #111827;
}

.IconButton.IconButton--color--dark:hover {
  background-color: #f9fafb;
}

.IconButton.IconButton--color--dark:active {
  background-color: #f3f4f6;
}

.DataTable {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  position: relative;
}

.DataTable thead {
  -webkit-user-select: none;
  user-select: none;
}

.DataTable thead th {
  color: #374151;
  text-align: left;
  text-transform: uppercase;
  background-color: #f3f4f6;
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
  padding: .75rem 1rem;
  font-size: 12px;
  font-weight: 500;
  position: sticky;
  top: 0;
}

.DataTable tbody tr:nth-child(2n) {
  background-color: #f9fafb;
}

.DataTable tbody tr td {
  border-bottom: 1px solid #e5e7eb;
  padding: .75rem 1rem;
  font-size: 14px;
}

.DataTable a {
  color: #306ce0;
}

.DataTable__header--sortable {
  cursor: pointer;
}

.DataTable__header--sm {
  width: 120px;
}

.DataTable__header--md {
  width: 240px;
}

.DataTable__header--lg {
  width: 320px;
}

.DataTable__header__container {
  align-items: center;
  gap: 4px;
  display: flex;
}

.DataTable__header__container svg {
  width: 12px;
}

.Container {
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
}

@media screen and (max-width: 1024px) {
  .Container {
    padding: 0 1rem;
  }
}

.Checkbox__container {
  cursor: pointer;
  height: 16px;
  -webkit-user-select: none;
  user-select: none;
  margin-bottom: .5rem;
  padding-left: 1.5rem;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  display: block;
  position: relative;
}

.Checkbox__container input {
  display: none;
}

.Checkbox__container:hover .Checkbox:not(:checked):not(:disabled) ~ .Checkbox__mark {
  background-color: #ddebfc;
  border-color: #6b7280;
}

.Checkbox__container:focus .Checkbox:not(:checked):not(:disabled) ~ .Checkbox__mark, .Checkbox__container:active .Checkbox:not(:checked):not(:disabled) ~ .Checkbox__mark {
  background-color: #c3dcfa;
  border-color: #6b7280;
}

.Checkbox:checked ~ .Checkbox__mark {
  background-color: #3981ea;
  border-color: #3981ea;
}

.Checkbox:checked ~ .Checkbox__mark:after {
  content: "";
  height: 3px;
  width: 9px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  position: absolute;
  top: 4px;
  left: 2px;
  transform: rotate(-45deg);
}

.Checkbox:disabled ~ .Checkbox__mark {
  cursor: not-allowed;
  background-color: #d1d5db;
  border-color: #d1d5db;
}

.Checkbox__mark {
  height: 16px;
  width: 16px;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.Card {
  min-width: 240px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  border: 1px solid #e9ecef;
  margin: 1rem 0;
  padding: 1rem;
}

.Card.Card--fluid {
  width: 100%;
}

.Button--variant--default {
  -webkit-appearance: none;
  appearance: none;
  color: #111827;
  cursor: pointer;
  text-align: center;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 4px;
  padding: .5rem 2rem;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  transition: all .1s ease-in-out;
  display: inline-block;
  position: relative;
  text-decoration: none !important;
}

.Button--variant--default:disabled {
  cursor: not-allowed;
}

.Button--variant--default svg {
  width: 24px;
  display: block;
}

.Button--variant--default.Button--fluid {
  width: 100%;
}

.Button--variant--default.Button--size--sm {
  padding: .25rem .75rem;
  font-size: .75rem;
}

.Button--variant--default.Button--size--lg {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
}

.Button--variant--default.Button--iconLeading {
  padding-left: 2.5rem !important;
}

.Button--variant--default.Button--iconLeading .iconLeading {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(50%, -50%);
}

.Button--variant--default.Button--iconLeading .iconLeading svg {
  width: 18px;
}

.Button--variant--default.Button--iconTrailing {
  padding-right: 2.5rem !important;
}

.Button--variant--default.Button--iconTrailing .iconTrailing {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.Button--variant--default.Button--iconTrailing .iconTrailing svg {
  width: 18px;
}

.Button--variant--default.Button--rounded {
  border-radius: 2rem !important;
}

.Button--variant--default:hover {
  background-color: #f9fafb;
}

.Button--variant--default:active {
  background-color: #f3f4f6;
}

.Button--variant--default:disabled {
  color: #6b7280 !important;
}

.Button--variant--default:disabled:hover, .Button--variant--default:disabled:active {
  background-color: #0000 !important;
}

.Button--variant--default.Button--color--primary {
  color: #306ce0;
}

.Button--variant--default.Button--color--primary:hover {
  background-color: #f0f6fe;
}

.Button--variant--default.Button--color--primary:active {
  background-color: #ddebfc;
}

.Button--variant--default.Button--color--accent {
  color: #16a34a;
}

.Button--variant--default.Button--color--accent:hover {
  background-color: #f0fdf4;
}

.Button--variant--default.Button--color--accent:active {
  background-color: #dcfce7;
}

.Button--variant--default.Button--color--warn {
  color: #c13741;
}

.Button--variant--default.Button--color--warn:hover {
  background-color: #fdfcfa;
}

.Button--variant--default.Button--color--warn:active {
  background-color: #fbf0ea;
}

.Button--variant--default.Button--color--light {
  color: #f3f4f6;
}

.Button--variant--default.Button--color--light:hover {
  background-color: #f9fafb;
}

.Button--variant--default.Button--color--light:active {
  background-color: #f3f4f6;
}

.Button--variant--default.Button--color--dark {
  color: #374151;
}

.Button--variant--default.Button--color--dark:hover {
  background-color: #f9fafb;
}

.Button--variant--default.Button--color--dark:active {
  background-color: #f3f4f6;
}

.Button--variant--outlined {
  -webkit-appearance: none;
  appearance: none;
  color: #111827;
  cursor: pointer;
  text-align: center;
  color: #111827;
  background-color: #0000;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: .5rem 2rem;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  transition: all .1s ease-in-out;
  display: inline-block;
  position: relative;
  text-decoration: none !important;
}

.Button--variant--outlined:disabled {
  cursor: not-allowed;
}

.Button--variant--outlined svg {
  width: 24px;
  display: block;
}

.Button--variant--outlined.Button--fluid {
  width: 100%;
}

.Button--variant--outlined.Button--size--sm {
  padding: .25rem .75rem;
  font-size: .75rem;
}

.Button--variant--outlined.Button--size--lg {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
}

.Button--variant--outlined.Button--iconLeading {
  padding-left: 2.5rem !important;
}

.Button--variant--outlined.Button--iconLeading .iconLeading {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(50%, -50%);
}

.Button--variant--outlined.Button--iconLeading .iconLeading svg {
  width: 18px;
}

.Button--variant--outlined.Button--iconTrailing {
  padding-right: 2.5rem !important;
}

.Button--variant--outlined.Button--iconTrailing .iconTrailing {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.Button--variant--outlined.Button--iconTrailing .iconTrailing svg {
  width: 18px;
}

.Button--variant--outlined.Button--rounded {
  border-radius: 2rem !important;
}

.Button--variant--outlined:hover {
  background-color: #f9fafb;
}

.Button--variant--outlined:active {
  background-color: #f3f4f6;
}

.Button--variant--outlined:disabled {
  color: #6b7280 !important;
}

.Button--variant--outlined:disabled:hover, .Button--variant--outlined:disabled:active {
  background-color: #0000 !important;
}

.Button--variant--outlined.Button--color--primary {
  color: #306ce0;
  border-color: #306ce0;
}

.Button--variant--outlined.Button--color--primary:hover {
  background-color: #f0f6fe;
}

.Button--variant--outlined.Button--color--primary:active {
  background-color: #ddebfc;
}

.Button--variant--outlined.Button--color--accent {
  color: #16a34a;
  border-color: #16a34a;
}

.Button--variant--outlined.Button--color--accent:hover {
  background-color: #f0fdf4;
}

.Button--variant--outlined.Button--color--accent:active {
  background-color: #dcfce7;
}

.Button--variant--outlined.Button--color--warn {
  color: #c13741;
  border-color: #c13741;
}

.Button--variant--outlined.Button--color--warn:hover {
  background-color: #fdfcfa;
}

.Button--variant--outlined.Button--color--warn:active {
  background-color: #fbf0ea;
}

.Button--variant--outlined.Button--color--light {
  color: #f3f4f6;
  border-color: #f3f4f6;
}

.Button--variant--outlined.Button--color--light:hover {
  background-color: #f9fafb;
}

.Button--variant--outlined.Button--color--light:active {
  background-color: #f3f4f6;
}

.Button--variant--outlined.Button--color--dark {
  color: #374151;
  border-color: #374151;
}

.Button--variant--outlined.Button--color--dark:hover {
  background-color: #f9fafb;
}

.Button--variant--outlined.Button--color--dark:active {
  background-color: #f3f4f6;
}

.Button--variant--raised {
  -webkit-appearance: none;
  appearance: none;
  color: #111827;
  cursor: pointer;
  text-align: center;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 4px;
  padding: .5rem 2rem;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  transition: all .1s ease-in-out;
  display: inline-block;
  position: relative;
  box-shadow: 0 1px 2px #00000040;
  text-decoration: none !important;
}

.Button--variant--raised:disabled {
  cursor: not-allowed;
}

.Button--variant--raised svg {
  width: 24px;
  display: block;
}

.Button--variant--raised.Button--fluid {
  width: 100%;
}

.Button--variant--raised.Button--size--sm {
  padding: .25rem .75rem;
  font-size: .75rem;
}

.Button--variant--raised.Button--size--lg {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
}

.Button--variant--raised.Button--iconLeading {
  padding-left: 2.5rem !important;
}

.Button--variant--raised.Button--iconLeading .iconLeading {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(50%, -50%);
}

.Button--variant--raised.Button--iconLeading .iconLeading svg {
  width: 18px;
}

.Button--variant--raised.Button--iconTrailing {
  padding-right: 2.5rem !important;
}

.Button--variant--raised.Button--iconTrailing .iconTrailing {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.Button--variant--raised.Button--iconTrailing .iconTrailing svg {
  width: 18px;
}

.Button--variant--raised.Button--rounded {
  border-radius: 2rem !important;
}

.Button--variant--raised:active {
  box-shadow: 0 2px 4px #00000040;
}

.Button--variant--raised:disabled {
  border-color: #9ca3af;
  box-shadow: 0 1px 2px #0000000d;
  color: #9ca3af !important;
  background-color: #e5e7eb !important;
}

.Button--variant--raised.Button--color--primary {
  color: #fff;
  background-color: #306ce0;
}

.Button--variant--raised.Button--color--accent {
  color: #fff;
  background-color: #16a34a;
}

.Button--variant--raised.Button--color--warn {
  color: #fff;
  background-color: #c13741;
}

.Button--variant--raised.Button--color--light {
  color: #4b5563;
  background-color: #f3f4f6;
}

.Button--variant--raised.Button--color--dark {
  color: #fff;
  background-color: #374151;
}

.Badge {
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #6b7280;
  border-radius: 10px;
  align-items: center;
  margin: 0;
  padding: 2px 10px;
  font-size: 10px;
  font-weight: 600;
  display: flex;
}

.Badge--light {
  color: #374151;
  background-color: #f3f4f6;
}

.Badge--primary {
  color: #2647a7;
  background-color: #ddebfc;
}

.Badge--invertedPrimary {
  color: #ddebfc;
  background-color: #306ce0;
}

.Badge--purple {
  color: #442267;
  background-color: #f1effa;
}

.Badge--invertedPurple {
  color: #f1effa;
  background-color: #8441b9;
}

.Badge--success {
  color: #166534;
  background-color: #dcfce7;
}

.Badge--invertedSuccess {
  color: #dcfce7;
  background-color: #16a34a;
}

.Badge--danger {
  color: #6d1d20;
  background-color: #fbf0ea;
}

.Badge--invertedDanger {
  color: #fbf0ea;
  background-color: #c13741;
}

.Badge--warning {
  color: #a16207;
  background-color: #fef9ce;
}

/*# sourceMappingURL=index.6678c022.css.map */
