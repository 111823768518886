.CreateModuleModal {
  .Modal__content {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow: visible;

    & > p {
      margin: 0;

      &:first-of-type {
        font-size: 1.3rem;
        font-weight: bold;
      }
    }
    .CreateModuleModal__input {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &__limitToGroups {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-end;

        &__group {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          & > p {
            margin: 0;
          }
          & svg {
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
        }
      }
      &__groupVariations {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-end;

        &__variation {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          & > p {
            margin: 0;
          }
          & svg {
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
        }
      }
    }
    .CreateModuleModal__buttons {
      width: 100%;
      display: flex;
      justify-content: end;
      gap: 1rem;
      align-items: center;

      &:last-of-type {
        margin-top: 1rem;
      }
    }
  }
}
