@import 'src/scss/variables.scss';

.VariableFields {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  position: relative;

  &__keyValueSpan {
    display: flex;
    justify-content: space-between;

    & > p:nth-child(2) {
      text-align: right;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &--bold {
      & > p:first-child {
        font-weight: 500;
      }
    }

    &--bold,
    &--dark {
      & > p:nth-child(2) {
        color: $gray-900;
      }
    }

    align-items: center;
  }

  &__closeButton {
    position: absolute !important;
    top: 10;
    right: 10;
    cursor: pointer;
  }

  &__content {
    flex: 1 1 0;
    display: flex;
    overflow: hidden;
  }

  &__fields {
    width: 416px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $gray-200;

    &__header {
      padding: 12px 8px;

      & > .VariableFields__keyValueSpan {
        margin: 4px 16px;
      }

      &__tabs {
        position: relative;
        margin: 16px 8px;

        & > .Tabs {
          box-shadow: inset 0 -1px 0 0 $gray-200;
          align-items: flex-end;
          user-select: none;

          &::-webkit-scrollbar {
            display: none;
          }

          & .TabItem {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            min-width: max-content;
          }
        }

        &__buttonOverlay {
          pointer-events: none;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          width: 100%;
          z-index: 99;
          user-select: none;

          display: flex;
          justify-content: space-between;
          align-items: center;

          & > div {
            position: absolute;
            display: flex;
            align-items: center;
            pointer-events: all;
            cursor: pointer;
            width: 40px;
            height: 100%;

            & > svg {
              height: 18px;
            }
          }

          &__prev {
            left: 0;
            justify-content: flex-start;
            background: linear-gradient(to right, #fff 0%, #fff 50%, rgba(255, 255, 255, 0));
          }

          &__next {
            right: 0;
            justify-content: flex-end;
            background: linear-gradient(to left, #fff 0%, #fff 50%, rgba(255, 255, 255, 0));
          }
        }
      }
    }
  }

  &__image {
    padding: 8px;
    display: flex;
    flex-direction: column;
    min-width: 416px;

    &__header {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-top: 24px;
      margin-bottom: 8px;

      & > p {
        cursor: pointer;
      }

      &__activeTab {
        color: $primary-500 !important;
      }
    }

    &__container {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        max-height: 500px;
        max-width: 700px;
      }
    }
  }

  &__buttons {
    padding: 20px 24px;
    border-top: 1px solid $gray-200;
    display: flex;
    justify-content: space-between;

    button:not(:last-child) {
      margin-right: 16px;
    }
  }
}
