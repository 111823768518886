@import '~/src/scss/variables.scss';

.orderInfoModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__modal {
    .Modal__close {
      display: none;
    }
  }

  .modalGreyHeader {
    border-radius: 8px 8px 0 0;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-100;

    p {
      margin: 0;
      font-size: 18px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin: 16px 24px 24px 24px;
    gap: 16px;

    select {
     width: 180px;
    }
    input {
     width: 180px;
    }

    label {
      align-self: center;
      font-size: 14px;
      color: $gray-700;
    }
  }

  &__input {
    text-align: right;
  }
}
.modalButtonEnd {
  display: flex;
  justify-content: end;
  border-top: 1px solid $gray-200;

  button {
    margin: 20px 20px 20px 0;
    padding: 8px 24px !important;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}
