@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.Card {
  background-color: white;
  border: 1px solid $border;
  // border-radius: 2px;
  // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  min-width: 240px;
  margin: 1rem 0;
  padding: 1rem;
  width: fit-content;

  &.Card--fluid {
    width: 100%;
  }
}
