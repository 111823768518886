@import 'src/scss/variables.scss';
@import 'src/scss/mixins.scss';

.EverynameDetails {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  p {
    margin: 0;
    color: $gray-700;
    line-height: 1.5;
  }
  strong {
    font-weight: 500;
  }

  &__cancelledBanner {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    background-color: $warning-50;
    align-items: center;

    &__left {
      display: flex;
      align-items: center;
    }

    &__icon {
      width: 20px;
      height: 20px;
      margin-right: 12px;
      color: $warning-500;
    }

    &__title {
      font-weight: 500;
    }

    &__title,
    &__subtitle {
      color: $warning-800 !important;
    }

    &__button {
      font-size: 12px !important;
    }
  }

  &__nav {
    display: flex;
    align-items: center;

    h4 {
      margin: 0 30px 0 0;
      font-size: 22px;
      font-weight: 400;
    }

    a {
      align-items: center;
      color: $gray-500;
      display: flex;
      gap: 4px;

      &:hover {
        text-decoration: none;
      }

      svg {
        width: 16px;
      }
    }
  }

  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 24px !important;
    background-color: $gray-100;

    &__content {
      background-color: #fff;
      flex-grow: 1;
      padding: 16px 24px;
      display: flex;
    }

    &__cancelButtons {
      width: 100%;
      background-color: #fff;
      padding: 12px 24px;
      display: flex;
      justify-content: space-between;
    }
  }

  &__sideInfo {
    display: flex;
    flex-direction: column;
    width: 256px;
    padding: 0px 8px;

    &__grayBackground {
      background-color: $gray-100;
      padding: 12px 16px;
      border-radius: 8px;
      margin: 0 -8px;

      &--indent {
        padding: 16px 8px;
      }
    }

    &__editOrderNotes {
      margin: 0 -8px;

      &__buttons {
        display: flex;
        padding: 8px 0;

        :first-child {
          padding: 5px 0 !important;
          margin-right: 8px;
        }
        :last-child {
          padding: 5px 0 !important;
          color: $primary-700;
          background-color: $primary-50;
          margin-left: 8px;
        }
      }
    }

    &__valueSpan {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      a:nth-child(2),
      p:nth-child(2) {
        word-break: break-all;
        text-align: right;
        flex-grow: 1;
      }

      p:nth-child(2) {
        color: $gray-900;
      }

      span:nth-child(2) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        flex-wrap: wrap;
        p {
          text-align: right;
          color: $gray-900;
        }
      }

      &--smallMargin {
        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }

    &__card {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      &__header {
        margin: 4px 0 !important;
      }

      &--paddingVertical {
        padding: 8px 0;
      }

      &--padding {
        padding: 8px;
      }
    }

    &__total {
      p {
        font-size: 16px;
      }
    }
  }

  &__tabs {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0px 16px;
    margin-left: 32px;

    &__tabContainer {
      width: 100%;
      border-bottom: 1px solid $gray-200;
      margin-bottom: 16px;
    }

    &__tab {
      width: 200px;
      display: flex;
      justify-content: center;
      padding-top: 4px !important;
      padding-bottom: 14px !important;
      font-weight: 400 !important;

      .Badge {
        margin-left: 0.5rem;
      }
    }

    &__tableContainer {
      &:not(:first-child) {
        margin-top: 24px;
      }
    }

    &__tableHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px;
      margin-bottom: 4px;

      span {
        display: flex;

        p {
          font-size: 14px;
          color: $gray-500;

          &:not(:last-child) {
            margin-right: 60px;
          }
          strong {
            color: $gray-900;
            font-weight: 400;
          }
        }
      }
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;

    .Modal__content {
      padding: 0 !important;
      width: 500px !important;
    }

    &__header {
      height: 64px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $gray-50;

      p {
        font-size: 18px;
        color: $gray-900;
      }

      .IconButton {
        color: $gray-500;
      }
    }

    &__content {
      &__row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 24px;
        margin-bottom: 16px;

        &:first-child {
          margin-top: 16px;
        }

        &__inputs {
          width: 275px;
          display: flex;

          .Input__container {
            flex-grow: 1;

            &:not(:first-child) {
              margin-left: 8px;
            }
            input {
              width: 100%;
            }
          }
        }
      }

      &__divider {
        height: 1px;
        width: 100%;
        margin-bottom: 16px;
        background-color: $gray-200;
      }
    }

    &__buttons {
      padding: 20px 24px;
      border-top: 1px solid $gray-200;
      display: flex;
      justify-content: flex-end;

      button:last-child {
        margin-left: 16px;
      }
    }
  }

  &__cancelOrderModal {
    &__content {
      padding: 16px 24px;
    }
  }

  &__deleteWarning {
    display: flex;
    gap: 1.5rem;

    &__iconContainer {
      border-radius: 100%;
      background-color: $danger-150;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;

      & > svg {
        width: 24px;
        height: 24px;
        color: $danger-600;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      h1,
      p {
        margin: 0;
      }

      h1 {
        font-size: 18px;
        line-height: 1.5;
        font-weight: 500;
      }

      &__buttons {
        display: flex;
        gap: 1rem;
      }
    }
  }
}
