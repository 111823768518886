@import '~/src/scss/mixins';
@import '~/src/scss/variables';

// whole component, including Navbar
.RepDetailsComponent {
  height: 100%;
  display: flex;
  flex-direction: column;
}

// border
.RepDetailsContainer {
  background-color: $gray-100;
  flex-grow: 1;
  padding: 24px;
}

// component main
.RepDetails {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: $white;
  color: $gray-700;
  display: flex;

  // Information section, left side of screen
  &__info {
    width: 300px;

    p {
      margin: 0;
    }

    &__profile {
      display: flex;
      align-items: center;
      padding: 16px 8px;

      &__img {
        width: 104px;
        height: 104px;
        border-radius: 8px;
        object-fit: cover;
      }

      &__titles {
        padding: 0 16px;

        h3 {
          font-size: 20px;
          margin: 0;
          line-height: 1.5;
          color: $gray-900;
        }
        p {
          color: $gray-500;
          margin: 0px;
        }
      }
    }
    &__contact {
      background-color: $gray-100;
      padding: 8px;
      border-radius: 8px;
      margin-top: 16px;

      & > div {
        margin: 8px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        p:nth-child(2) {
          color: $gray-900;
        }
      }
      &__address {
        span {
          text-align: right;
          color: $gray-900;
        }
      }
    }
    &__repArea {
      margin-top: 28px;
      white-space: pre-wrap;

      &__header {
        display: flex;
        justify-content: space-between;
        margin: 4px 8px;

        p {
          @include medium;
        }
      }
      &__text {
        background-color: $gray-100;
        border-radius: 6px;
        padding: 12px 16px;
      }
      &__editing {
        &--buttons {
          display: flex;
          gap: 0.5rem;
          width: 100%;
        }
      }
    }

    &__reports {
      margin-top: 28px;

      &__header {
        display: flex;
        align-items: center;
        margin: 4px 8px;

        p {
          @include medium;
          margin-left: 4px;
        }

        svg {
          width: 20px;
        }
      }

      &__divider {
        background-color: $gray-200;
        width: 100%;
        height: 1px;
        margin: 4px 8px;
      }

      &__dates {
        width: 100%;
        margin: 16px 0 20px 0;
        display: flex;
        gap: 0.5rem;

        & > * {
          flex-grow: 1;
        }

        & .DatePicker__container .Input__container {
          width: 100%;
        }
      }

      &__list {
        & > div {
          display: flex;
          justify-content: space-between;

          &:not(:last-child) {
            margin-bottom: 8px;
          }

          p {
            color: $gray-500;
          }
        }
      }
    }
  }

  &__tabs {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;

    .RepDetails__tab {
      padding: 0px 76px 14px 76px;
    }

    &__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $gray-200;
    }
  }
}
