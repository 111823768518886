@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.FormLabel {
  @include semi-bold;
  display: inline-block;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0.25rem;
}
