@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.PayrollSummary {
  &__filters {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;

    &__spacer {
      flex: 1;
    }
  }

  &__filter {
    label {
      @include semi-bold;
      display: block;
      margin-bottom: 4px;
    }
  }
}
