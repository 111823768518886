.Pagination {
  align-items: center;
  background-color: white;
  display: flex;
  gap: 1rem;
  padding: 0.75rem 1.5rem;
  position: sticky;
  bottom: 0;

  &__spacer {
    flex: 1;
  }

  &__buttons {
    align-items: center;
    display: flex;
    gap: 1rem;

    input {
      width: 64px;
    }
  }
}
