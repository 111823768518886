@import '~/src/scss/variables';

.PWAvailability {
  .availability {
    border-top: 1px solid $border;
    padding: 1rem;
    margin-bottom: 1rem;

    &:last-of-type {
      border-bottom: 1px solid $border;
    }

    &__date {
      display: flex;
      justify-content: space-between;
    }
  }
}
