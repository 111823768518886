@import 'src/scss/variables';
@import 'src/scss/mixins';

.DisplayReorderDrawerPanel {
  &__section {
    display: grid;
    // template columns defined in DisplayReorderDrawerPanel.tsx
    align-items: start;
    column-gap: 1rem;
    gap: 0.5rem;
  }

  &__position {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &__input {
      width: 35px;
      height: 32px;
      padding-left: 2px !important;
      padding-right: 2px !important;
      text-align: center;
      color: black !important;
      font-weight: 500;

      &--blue {
        background-color: $primary-100 !important;
      }

      &--yellow {
        background-color: $warning-100 !important;
      }

      &--red {
        background-color: $danger-200 !important;
      }
    }

    &__label {
      color: $gray-600 !important;
    }

    &--first {
      grid-column-start: 1;
    }
  }
}
