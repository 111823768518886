@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.Toaster {
  bottom: 64px;
  left: 50%;
  min-width: 280px;
  position: fixed;
  transform: translateX(-50%);
  z-index: 100;
}

.Toast {
  align-items: center;
  background-color: white;
  border-left: 12px solid $gray-600;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 1rem;
  width: 100%;

  &--info {
    border-left-color: $info;
  }

  &--success {
    border-left-color: $success;
  }

  &--warning {
    border-left-color: $warn;
  }

  &--error {
    border-left-color: $error;
  }
}
