@import './scss/functions';
@import './scss/breakpoints';
@import './scss/variables';
@import './scss/mixins';
@import './scss/defaults';
@import './scss/typography';
@import './scss/utils';

.w-full {
  width: 100%;
}

a {
  color: $primary;
  text-decoration: none;
  transition: color 0.25s ease-in-out;

  &:link {
    color: $primary;
  }

  &:visited {
    color: $primary;
  }

  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: underline;
  }
}

.divider {
  border: none;
  border-bottom: 1px solid $border;
  margin: 1rem 0;
}

.icon-wrapper {
  align-items: center;
  background-color: $azure-50;
  border-radius: 8px;
  color: $azure-600;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 0.75rem;
  width: 48px;

  &--green {
    background-color: $success-500;
    color: white;
  }

  svg {
    width: 24px;
  }
}

// To get a consistent space at the top of each page, which requires adding this class to a div, h1, or other element on every page, but which allows the flexibity to have a fixed nav
.headspace {
  margin-top: 8rem;
}

// used for login and similar forms
.center-page {
  max-width: 360px;
  margin: 6.5rem auto 1rem;

  &-img {
    display: block;
    margin: 0 auto 5rem;
  }

  &-title {
    @include semi-bold;
    text-align: center;
    margin-bottom: 2rem;
  }

  &-sub {
    color: $gray-800;
    font-size: 16px;
    margin-bottom: 2rem;
    text-align: center;
  }

  &-link {
    margin-top: 1.5rem;
  }
}

// Table
.data-table {
  border-collapse: separate;
  border-spacing: 0;
  position: relative;
  width: 100%;

  thead {
    user-select: none;

    th {
      @include medium;
      background-color: $gray-100;
      border-top: 1px solid $gray-200;
      border-bottom: 1px solid $gray-200;
      color: $gray-700;
      font-size: 12px;
      padding: 0.75rem 1rem;
      text-align: left;
      text-transform: uppercase;

      position: sticky;
      top: 0;
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: $gray-50;
      }
    }
    tr td {
      border-bottom: 1px solid $gray-200;
      font-size: 14px;
      padding: 0.75rem 1rem;
    }
  }

  a {
    color: $primary-600;
  }
}
