@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.PaymentApproval {
  &__subHeader {
    align-items: center;
    display: flex;
    margin-bottom: 2rem;

    button {
      margin-right: 1rem;
    }

    &__text {
      @include semi-bold;
      font-size: 14px;
    }
  }

  &__jobsList {
    border-top: 1px solid $border;
  }

  &__job {
    border-bottom: 1px solid $border;
    padding: 1rem;

    &__header {
      align-items: center;
      color: $primary;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }

    &__row {
      align-items: center;
      display: flex;
      font-size: 14px;
      justify-content: space-between;
      margin-bottom: 4px;

      label {
        color: $gray-500;
      }
    }
  }

  &__jobDetail {
    h1 {
      @include semi-bold;
      font-size: 32px;
    }

    &__total {
      align-items: center;
      background-color: $gray-100;
      border-radius: 6px;
      display: flex;
      padding: 1rem;
      margin-bottom: 1rem;

      &__icon {
        margin-right: 1rem;
      }

      h4 {
        margin: 0;
      }
    }

    &__card {
      border-radius: 8px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
      padding: 0.75rem 1rem;
      margin-bottom: 1rem;
    }

    &__task {
      label {
        color: $gray-500;
        font-size: 14px;
        margin-right: 4px;
      }

      &__row {
        display: flex;
        justify-content: space-between;
      }

      &__field {
        display: flex;
      }

      &__badges {
        display: flex;
        margin-top: 4px;
        gap: 8px;
      }
    }
  }
}
