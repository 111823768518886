@import 'src/scss/variables';
@import 'src/scss/mixins';

.DisplayModule {
  background-color: $gray-300;
  padding: 1rem;
  border-radius: 6px;
  box-shadow: 0 1px 2px #00000040;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > p {
      font-size: 1.1rem;
      font-weight: bold;
      margin: 0;
    }

    & > a {
      color: $danger-500;
      text-decoration: none;
    }
  }

  &__rows {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__row {
    gap: 1rem;
    margin: 0.5rem 0;
    display: flex;
    overflow-x: auto;
    align-items: flex-start;
  }

  &__item {
    width: fit-content;
    background-color: #fff;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    flex-shrink: 0;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    gap: 1.5rem;

    & > p {
      margin: 0;
    }

    &__type {
      font-weight: bold;
      text-transform: capitalize;
    }

    &__corner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $primary-600;
      border-radius: 6px;
      clip-path: polygon(0 0, 40px 0, 0 35px);

      & > p {
        position: absolute;
        top: 0;
        left: 4px;
        margin: 0;
        color: white;
      }
    }

    &__product {
      margin-bottom: 1rem !important;
    }

    &__variables {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &__variable {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0.25rem;

      & > p {
        margin: 0;
        text-transform: capitalize;
      }

      &__key {
        font-weight: bold;
      }
    }

    &__links {
      display: flex;
      gap: 0.5rem;

      & > a {
        &:last-child {
          color: $danger-500;
        }
      }
    }
  }
}
