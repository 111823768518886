@import 'src/scss/variables.scss';

.ProductsReports {
  display: flex;
  flex-direction: column;
  height: 100%;

  p {
    line-height: 1.5;
    color: $gray-700;
    margin: 0;
  }

  .nav {
    margin-bottom: 16px;
  }

  &__body {
    padding: 24px;
    flex-grow: 1;

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      &__title {
        h3 {
          margin: 0;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.5;
        }

        p {
          color: $gray-500;
          margin-top: 4px;
        }
      }

      &__filters {
        display: flex;
        align-items: center;

        & > :not(:last-child) {
          margin-right: 16px;
        }

        & > div {
          & > p {
            margin-bottom: 4px;
          }

          .Input {
            height: 38px;
          }
        }
      }
    }

    &__table {
      display: flex;
      flex-direction: column;

      .DataTable {
        th {
          border-top: none;
        }

        tr:last-child {
          td {
            border-bottom: none;
          }
        }

        td {
          color: $gray-900;
          padding: 8px 16px;
        }
      }

      .ProductsReports__body__infoBanner {
        background-color: $primary-50;
        align-self: stretch;
        padding: 16px;
        display: flex;
        align-items: center;
        border-radius: 6px;

        svg {
          width: 20px;
          height: 20px;
          margin-right: 12px;
          color: $primary-400;
        }

        p {
          color: $primary-700;
        }
      }
    }
  }
}
