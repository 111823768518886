@import '~/src/scss/variables';
@import '~/src/scss/mixins';

.AddContactModal {
  .Modal__content {
    max-width: 580px;
    padding: 0;
  }

  &__header {
    background-color: $gray-100;
    padding: 1.5rem;
    text-align: center;
  }

  &__body {
    padding: 2rem;
  }

  &__warning {
    background-color: $warning-50;
    display: flex;
    gap: 1rem;
    padding: 1rem;
    margin: 1rem 0;

    &__icon {
      svg {
        color: $warning-400;
        width: 20px;
      }
    }

    &__message {
      color: $warning-700;

      &__title {
        @include medium;
        color: $warning-800;
      }
    }
  }

  &__field {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;

    &__label {
      color: $gray-500;
    }
  }

  &__footer {
    border-top: 1px solid $border;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    padding: 1rem 1.5rem;
  }
}
