.relative {
  position: relative;
}

.flex {
  display: flex;

  &-row {
    flex-direction: row;
  }

  &-column {
    flex-direction: column;
  }
}

// Align

.align {
  &-center {
    align-items: center;
  }
  &-start {
    align-items: flex-start;
  }
  &-end {
    align-items: flex-end;
  }
}

// Justify

.justify {
  &-center {
    justify-content: center;
  }
  &-start {
    justify-content: flex-start;
  }
  &-end {
    justify-content: flex-end;
  }
  &-between {
    justify-content: space-between;
  }
  &-around {
    justify-content: space-around;
  }
  &-evenly {
    justify-content: space-evenly;
  }
}

// flex 1-12

@for $i from 1 through 12 {
  .flex-#{$i} {
    flex: $i;
  }
}

// gap 0-12

@for $i from 0 through 12 {
  .gap-#{$i} {
    gap: $i * 0.25rem;
  }
}

// padding 0-24

@for $i from 0 through 24 {
  .pt-#{$i} {
    padding-top: $i * 0.25rem;
  }
  .pb-#{$i} {
    padding-bottom: $i * 0.25rem;
  }
  .pl-#{$i} {
    padding-left: $i * 0.25rem;
  }
  .pr-#{$i} {
    padding-right: $i * 0.25rem;
  }
  .px-#{$i} {
    padding-left: $i * 0.25rem;
    padding-right: $i * 0.25rem;
  }
  .py-#{$i} {
    padding-top: $i * 0.25rem;
    padding-bottom: $i * 0.25rem;
  }
  .p-#{$i} {
    padding: $i * 0.25rem;
  }
}

// margin 0-24

@for $i from 0 through 24 {
  .mt-#{$i} {
    margin-top: $i * 0.25rem;
  }
  .mb-#{$i} {
    margin-bottom: $i * 0.25rem;
  }
  .ml-#{$i} {
    margin-left: $i * 0.25rem;
  }
  .mr-#{$i} {
    margin-right: $i * 0.25rem;
  }
  .mx-#{$i} {
    margin-left: $i * 0.25rem;
    margin-right: $i * 0.25rem;
  }
  .my-#{$i} {
    margin-top: $i * 0.25rem;
    margin-bottom: $i * 0.25rem;
  }
  .m-#{$i} {
    margin: $i * 0.25rem;
  }
}

.color {
  &-gray-500 {
    color: $gray-500;
  }
  &-gray-600 {
    color: $gray-600;
  }
  &-gray-700 {
    color: $gray-700;
  }
  &-gray-900 {
    color: $gray-900;
  }
  &-success-700 {
    color: $success-700;
  }
  &-primary {
    color: $primary;
  }
}

.font {
  &-light {
    @include light;
  }
  &-normal {
    @include normal;
  }
  &-medium {
    @include medium;
  }
  &-semi-bold {
    @include semi-bold;
  }
  &-bold {
    @include bold;
  }

  &-italic {
    font-style: italic;
  }
  // font-size 8-48
  @for $i from 8 through 48 {
    &-size-#{$i} {
      font-size: #{$i}px;
    }
  }
}

.text {
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
  &-center {
    text-align: center;
  }
  &-nowrap {
    white-space: nowrap;
  }
  &-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-uppercase {
    text-transform: uppercase;
  }
  &-lowercase {
    text-transform: lowercase;
  }
  &-capitalize {
    text-transform: capitalize;
  }
}
