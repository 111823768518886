@import 'src/scss/variables.scss';

.DisplayFollowupDrawer {
  width: 896px !important;
  display: flex;

  &__header {
    background-color: $gray-100;
    padding: 18px 24px;
    border-bottom: 1px solid $gray-200;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > p {
      margin: 0;
      color: $gray-900;
      font-size: 18px;
    }

    & > svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
      color: $gray-500;
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    overflow: hidden;

    &__left {
      width: 480px;
      height: 100%;
      border-right: 1px solid $gray-200;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px 24px;

      &__action {
        padding-bottom: 4px;
        border-bottom: 1px solid $gray-200;

        & > p {
          margin: 0;
          font-weight: 500;
        }
      }

      &__span {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > p {
          margin: 0;
        }

        p:has(+ .Input__container > textarea) {
          align-self: flex-start;
        }

        .Input__container {
          width: 248px !important;
        }
      }

      &__button {
        align-self: flex-end;
        margin-top: 8px;
      }
    }

    &__right {
      padding: 16px;
      flex-grow: 1;
      overflow: auto;

      &__note {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px 16px;
        border-bottom: 1px solid $gray-200;

        &__span {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 4px;

          &__title {
            margin: 0;
            font-weight: 500;
            color: $gray-900;
          }

          &__time {
            margin: 0;
            color: $gray-500;
          }
        }

        & > p {
          margin: 0 0 8px 0;
          color: $gray-600;
        }

        & > .Badge {
          align-self: flex-end;
        }
      }
      
      &__info {
        font-style: italic;
        color: $gray-500;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    padding: 20px 24px;
    border-top: 1px solid $gray-200;
  }
}
