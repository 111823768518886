@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.BillingDetails {
  background-color: $gray-100;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__nav {
    &__slash {
      width: 24px;
      height: 36px;
      background: linear-gradient(
        -80deg,
        #fff,
        #fff 50%,
        $gray-300 50%,
        $gray-300 calc(50% + 2px),
        #fff calc(50% + 2px)
      );
      margin: 0 10px 0 16px;
    }

    &__contactsLink {
      margin-right: -15px;
      color: $gray-500 !important;
      font-weight: 500;
    }

    &__contactsDropdown {
      padding: 24px 36px !important;
      display: flex;
      flex-direction: column;
      width: max-content;

      * {
        margin: 0;
      }
      & > p:first-child {
        color: $gray-900;
        font-weight: 500;
      }

      &__container {
        display: flex;
        width: max-content;

        & > :not(:last-child) {
          margin-right: 32px;
        }
      }

      &__column {
        display: flex;
        flex-direction: column;
        flex: 1 1 0px;
      }

      &__contact {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        width: max-content;

        * {
          width: 100%;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    &__ordersPopover {
      width: fit-content;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 12px 16px !important;;

      &> p {
        white-space: nowrap;
        margin: 0;
        font-weight: 500;
      }
    }
  }

  &__body {
    flex-grow: 1;
    background-color: $white;
    display: flex;
    gap: 1.5rem;
    padding: 1rem 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 24px;
  }

  &__openOrders {
    flex: 1;
    position: relative;

    &__header {
      margin-top: 20px;
    }

    &__title {
      @include medium;
      color: $gray-700;
      margin: 0;
    }
  }

  &__totals {
    display: flex;
    gap: 1rem;
    position: absolute;
    right: 0;

    &__label {
      color: $gray-500;
      display: block;
    }

    &__value {
      @include medium;
      color: $gray-700;
      display: block;
    }
  }
}
