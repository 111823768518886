@import 'src/scss/variables.scss';

.Products__tag {
  display: flex;
  align-items: center;
  background-color: $gray-100;
  width: fit-content;
  padding: 3px 9px;

  p {
    font-size: 14px;
    color: $gray-700;

    & > span:first-child {
      font-weight: 500;
    }
  }

  svg {
    color: $gray-500;
    cursor: pointer;
    margin-left: 8px;
    width: 14px;
  }
}
