@import 'src/scss/variables.scss';

.ProductDetails__tagCard {
  & > p {
    font-weight: 500;
    font-size: 16px;
  }

  &__card {
    padding: 24px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    &__search {
      display: flex;
      align-items: flex-end;
      margin-bottom: 16px;
      gap: 1rem;

      & > svg {
        margin-bottom: 0.5rem;
        width: 24px;
      }
    }

    &__inputGroup {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      & > span {
        display: flex;
        margin-bottom: 16px;

        & > p {
          color: $gray-900;
        }

        & > svg {
          margin-left: 8px;
          width: 20px;
        }
      }

      .Input:disabled {
        background-color: $gray-100;
        color: $gray-500;

        &::placeholder {
          color: $gray-400 !important;
        }
      }
    }

    &__content {
      padding: 5px 0 13px;
      display: flex;
      flex-wrap: wrap;

      & > .Products__tag {
        margin-top: 8px;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }

    &__buttons {
      padding-top: 16px;
      display: flex;
      justify-content: flex-end;

      & > button {
        &:disabled {
          border: 1px solid #00000000;
        }

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
}
