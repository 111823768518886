@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.JobTaskDrawer {
  label {
    display: block;
  }

  .divider {
    background-color: $gray-100;
    margin: 1.5rem 0;
  }

  &__inputField {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    label {
      color: $gray-700;
    }
  }

  &__toggleField {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;

    label {
      color: $gray-700;
      margin-right: 1rem;
    }

    .Checkbox__container {
      margin-bottom: 0;
    }
  }

  &__prices {
    border-top: 1px solid $gray-200;
    position: relative;
    padding: 0 1.5rem 0 3rem;

    &__expanded {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      padding: 0.5rem 0;
    }

    &__expand {
      position: absolute !important;
      top: 8px;
      left: 8px;
      visibility: hidden;

      width: 24px !important;
      max-width: 24px !important;
      min-width: 24px !important;

      height: 24px !important;
      max-height: 24px !important;
      min-height: 24px !important;

      svg {
        width: 16px !important;
      }
    }

    &:hover .JobTaskDrawer__prices__expand {
      visibility: visible;
    }

    &__row {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 4px 0;
      width: 224px;

      label {
        color: $gray-500;
        font-size: 12px;
      }

      .value {
        color: $gray-700;
        margin: 0;
      }
    }

    &__subtotal {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      label {
        @include medium;
        color: $gray-700;
      }

      .value {
        color: $gray-900;
        font-size: 16px;
        margin: 0.5rem 0;
      }
    }
  }
}
