@import 'src/scss/variables.scss';

.Displays__listPage__displayModuleInput {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  align-items: flex-start;

  &__limitToGroups {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__group {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      & > p {
        margin: 0;
      }

      & svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  &__groupVariations {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__variation {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      & > p {
        margin: 0;
      }

      & svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
}

.DisplayModuleDetails {
  padding: 2rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.2;
    }
  }
  
  &__content {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      & > p {
        font-size: 1.3rem;
        margin: 0;
        font-weight: 600;
      }
    }
  }

  &__rows {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__row {
    background-color: $gray-100;
    padding: 1.5rem;
    border-radius: 6px;
    box-shadow: 0 1px 2px #00000040;

    &__info {
      & p {
        margin: 0;
      }

      &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > p {
          font-weight: 600;
          font-size: 1.2rem;
          margin-bottom: 0.5rem;
        }
      }

      &__links {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        & > a {
          text-decoration: none;

          &:last-child {
            color: $danger-500;
          }
        }
      }
    }

    &__items {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      overflow-x: auto;
    }

    &__item {
      margin-bottom: 0.5rem;
      border: 1px solid $gray-300;
      border-radius: 6px;
      padding: 1rem;
      background-color: white;

      & p {
        margin: 0;
      }

      &__links {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        & > a {
          text-decoration: none;

          &:not(:first-child) {
            color: $danger-500;
          }
        }
      }
    }
  }
}
