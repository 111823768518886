@import './breakpoints';

// App-specific Typography
@mixin sans {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

@mixin light {
  font-weight: 300;
}
@mixin normal {
  font-weight: 400;
}
@mixin medium {
  font-weight: 500;
}
@mixin semi-bold {
  font-weight: 600;
}
@mixin bold {
  font-weight: 700;
}

// non-standardized styling of placeholder text
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }
  @include optional-at-root(':-moz-placeholder') {
    @content;
  }
  @include optional-at-root('::-moz-placeholder') {
    @content;
  }
  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

// Responsive Typography
@mixin font-range($min-size, $max-size, $min-width: 320, $max-width: 1440) {
  $min-size-rem: rem-calc($min-size);
  $max-size-rem: rem-calc($max-size);
  $min-width-rem: rem-calc($min-width);
  $max-width-rem: rem-calc($max-width);

  font-size: calc(
    #{$min-size-rem} + (#{$max-size} - #{$min-size}) * (100vw - #{$min-width-rem}) / (#{$max-width} - #{$min-width})
  );

  @include breakpoint($min-width down) {
    font-size: #{$min-size-rem};
  }
  @include breakpoint($max-width) {
    font-size: #{$max-size-rem};
  }
}

@mixin size-range($property, $min-size, $max-size, $min-width: 320, $max-width: 1440) {
  $min-size-rem: rem-calc($min-size);
  $max-size-rem: rem-calc($max-size);
  $min-width-rem: rem-calc($min-width);
  $max-width-rem: rem-calc($max-width);

  #{$property}: calc(
    #{$min-size-rem} + (#{$max-size} - #{$min-size}) * (100vw - #{$min-width-rem}) / (#{$max-width} - #{$min-width})
  );

  @include breakpoint($min-width down) {
    #{$property}: #{$min-size-rem};
  }
  @include breakpoint($max-width) {
    #{$property}: #{$max-size-rem};
  }
}
