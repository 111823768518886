@import 'src/scss/variables.scss';

.ProductDetails__detailsModal {
  .Button--variant--raised {
    transition: all 0s !important;
  }

  &__header {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-50;
    border-radius: 8px 8px 0 0;

    & > p {
      color: $gray-900;
      font-size: 18px;
    }
  }

  &__body {
    &__productInfo {
      display: flex;
      flex-direction: column;
      padding: 8px 24px;

      & > p {
        font-size: 18px;
        margin-bottom: 32px;
      }

      & > .ProductDetails__keyValueSpan {
        align-items: center;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 24px;
          border-bottom: 1px solid $gray-200;
        }
      }
    }

    &__productCheckboxes {
      display: flex;
      flex-direction: column;
      padding: 8px 24px;
      margin-bottom: -12px;

      & > p {
        color: $gray-500;
        margin: 24px 0;
      }

      & > div {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px 0 0;
      }

      &__checkboxContainer {
        width: calc(50% - 12px);
        margin-right: 12px;
        margin-bottom: 12px;

        .Checkbox__container {
          padding-left: 24px;
          margin: 0;
          font-weight: 400;
        }
        & > p {
          margin-left: 24px;
          color: $gray-500;
        }
      }
    }

    &__cancelProduct {
      padding: 8px 24px;

      & > p {
        font-size: 18px;
        margin-bottom: 20px;
      }

      & > .ProductDetails__warningBanner {
        margin-bottom: 12px;
      }
    }
  }

  &__buttons {
    padding: 16px 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $gray-200;

    & > span > :first-child {
      margin-right: 16px;
    }
  }
}
