@import 'src/scss/variables.scss';

.ProductHistory {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    color: $gray-700;
    line-height: 1.5;
  }

  &__header {
    width: 100%;
    border-bottom: 1px solid $gray-200;
    margin-bottom: 16px;

    & > p {
      padding-bottom: 13px;
      font-size: 18px;
      font-weight: 500;
    }
  }

  &__table {
    border-radius: 6px;
    border: 1px solid $gray-200;
    overflow: hidden;
    thead {
      border-top: none !important;
    }
    tbody {
      tr {
        background-color: #fff !important;
      }
      tr:last-child {
        border-bottom: none !important;
      }
    }
  }
}
