@import 'src/scss/variables.scss';

.ProductsList__productInfoModal {
  display: flex;
  align-items: center;

  img {
    width: 100px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    width: 316px;
    padding: 24px;
    margin-left: 16px;

    p {
      margin: 0;
      color: $gray-500;
      line-height: 1.5;
    }

    & > :nth-child(1) {
      color: $gray-800;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 4px;
    }

    & > :nth-child(2) {
      border-bottom: 1px solid $gray-200;
      padding-bottom: 4px;
      margin-bottom: 21px;
    }

    & > span {
      display: flex;
      justify-content: space-between;

      & > p:nth-child(2) {
        color: $gray-900;
      }

      margin-bottom: 8px;
    }

    & > a:last-child {
      margin-top: 16px;
      display: flex;
      align-items: center;
      text-decoration: none;

      & > svg {
        margin-right: 4px;
        width: 20px;
      }
    }
  }
}
