@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.DatePicker {
  animation: scaleIn 0.4s;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  width: 280px;
  position: absolute;
  z-index: 101;

  &__container {
    position: relative;

    .Input__container {
      width: 100%;
      
      .Input {
        width: 100%;
      }
    }
  }

  &__icon {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
      font-size: 20px;
      width: 20px;
      height: 20px;
      color: $gray-600;
    }
  }

  &__navigation {
    align-items: center;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.13);
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 0.5rem;
  }
  &__month {
    font-size: 16px;
    line-height: 1.5;
    margin: 0 1rem;
  }
  &__calendar {
    padding: 0.5rem;

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;

      thead {
        th {
          @include semi-bold;
          font-size: 14px;
          line-height: 2;
        }
      }

      tbody {
        td {
          cursor: pointer;
          font-size: 14px;
          line-height: 2;
          padding: 0.5rem 0;
          text-align: center;
        }
      }
    }
  }
  &--selected {
    color: white !important;
    position: relative;

    &:before {
      background-color: $primary;
      border-radius: 100%;
      content: '';
      display: block;
      height: 32px;
      width: 32px;
      position: absolute;
      top: calc(50% - 16px);
      left: calc(50% - 16px);
      z-index: -1;
    }
  }
  &--disabled {
    background-color: $gray-100;
    color: $gray-400;
    cursor: not-allowed !important;
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
