@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.Modal {
  background-color: $gray-900;
  backdrop-filter: blur(6px);
  background-color: rgba(43, 45, 66, 0.6);
  height: 100%;
  width: 100%;
  padding: 3rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;

  &__content {
    animation: slideInTop 0.4s;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.04), 0 20px 25px -5px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    max-height: 100%;
    max-width: 1080px;
    min-height: 50px;
    min-width: 280px;
    overflow: auto;
    padding: 1.5rem;
    position: relative;
    z-index: 101;
  }

  &__close {
    position: absolute !important;
    top: 1rem;
    right: 1rem;
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideInTop {
  0% {
    transform: translateY(-100vh);
  }

  70% {
    transform: translateY(5vh);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideInBottom {
  0% {
    transform: translateY(100vh);
  }

  70% {
    transform: translateY(-5vh);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100vw);
  }

  70% {
    transform: translateX(5vw);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100vw);
  }

  70% {
    transform: translateX(-5vw);
  }

  100% {
    transform: translateX(0);
  }
}
