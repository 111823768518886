@import '~/src/scss/variables';
@import '~/src/scss/mixins';

.EditContact {
  .Modal__content {
    max-width: 704px;
    padding: 0;
  }

  &__header {
    background-color: $gray-100;
    padding: 1.5rem;
    text-align: center;
  }

  &__body {
    padding: 1.5rem;
  }

  &__user {
    border: 1px solid $border;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
  }

  &__warning {
    background-color: $warning-50;
    display: flex;
    gap: 1rem;
    padding: 1rem;
    margin: 1rem 0;

    &__icon {
      span {
        color: $warning-400;
        width: 20px;
      }
    }

    &__message {
      color: $warning-700;

      &__title {
        @include medium;
        color: $warning-800;
      }
    }
  }

  .contactInfoRow {
    border: 1px solid $gray-150;
    margin-top: 1rem;
    padding: 2rem;

    &__header {
      margin-bottom: 36px;
    }

    .contactPropertyText {
      width: 100%;
      max-width: 200px;
      margin-right: 8px;
    }

    .contactInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 32px;

      .contact_text {
        flex-grow: 1;
      }
    }
  }

  &__footer {
    border-top: 1px solid $border;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    padding: 1rem 1.5rem;
  }
}
