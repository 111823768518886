@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.Nav {
  background-color: $primary-5;

  &--loggedIn {
    background-color: white;
  }

  &__title {
    color: $gray-700;
    font-size: 22px;
  }

  &__logo {
    max-width: 132px;
    user-select: none;
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 12px 0;
    position: relative;
  }

  &__spacer {
    flex: 1;
  }

  // .Input__container {
  //   margin-right: 1rem;

  //   input {
  //     width: 280px;
  //   }
  // }

  &__item {
    margin-left: 1.5rem;
    position: relative;

    &__link {
      @include medium;
      align-items: center;
      color: $gray-500;
      cursor: pointer;
      display: flex;
      gap: 0.5rem;

      a {
        color: $gray-500;
        text-decoration: none;
      }

      svg {
        width: 16px;
      }
    }
  }
}
