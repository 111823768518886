@import '~/src/scss/variables';
@import '~/src/scss/mixins';

.EditAccountNotesModal {
  .Modal__content {
    padding: 0;
    width: 512px;
  }

  &__header {
    background-color: $gray-100;
    padding: 1.5rem;
    text-align: center;
  }

  .modalTextArea {
    width: 100%;
    resize: none;
  }

  &__body {
    padding: 2rem;
  }

  &__footer {
    border-top: 1px solid $border;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    padding: 1.5rem 1.5rem;
  }
}
