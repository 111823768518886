@import 'src/scss/variables.scss';

.ApplyCredits {
  display: flex;
  flex-direction: column;
  height: 100%;

  p {
    margin: 0;
    color: $gray-700;
    line-height: 1.5;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__keyValueSpan {
    display: flex;
    & > p:first-child {
      color: $gray-500;
    }
    & > p:not(:last-child) {
      margin-right: 4px;
    }
    & > p:nth-child(2) {
      color: $gray-900;
    }
  }

  &__header {
    height: 64px;
    background-color: $gray-50;
    display: flex;
    align-items: center;
    justify-content: center;

    & > p {
      color: $gray-900;
      font-size: 18px;
    }
  }

  &__body {
    padding: 16px 20px 32px 20px;
    flex-grow: 1;

    & > :not(:last-child) {
      margin-bottom: 16px;
    }

    &__amountContainer {
      display: flex;
      align-items: center;

      &__image {
        width: 48px;
        height: 48px;
        background-color: $success-100;
        color: $success-800;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;

        svg {
          width: 18px;
        }
      }

      &__amount {
        & > p:first-child {
          font-size: 12px;
          color: $gray-500;
        }
        & > p:nth-child(2) {
          font-size: 18px;
          color: $gray-900;
          font-weight: 500;
        }
      }
    }

    &__table {
      width: 100%;
      align-self: stretch;
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-right: 40px;
      }

      & > :not(:last-child) {
        padding: 4px 8px;
      }

      & > p:first-child {
        font-weight: 500;
      }

      &__tableContainer {
        border: 1px solid $gray-200;
        border-radius: 4px;
        flex-grow: 1;
        min-height: 315px;
      }

      table thead {
        border-top: none;
        border-bottom: none;
        tr th {
          padding: 12px 16px !important;
        }
      }
      table tbody tr {
        border: none;
        td {
          padding: 16px !important;
        }
      }
    }
  }
}
