@import 'src/scss/variables.scss';

.ProductSummary {
  height: 100%;
  display: flex;
  flex-direction: column;

  p {
    color: $gray-700;
    line-height: 1.5;
    margin: 0;
  }

  &__container {
    background-color: $gray-100;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__body {
    background-color: #fff;
    padding: 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__header {
    border-bottom: 1px solid $gray-200;

    & > p {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 40px;
    }
  }

  &__products {
    flex-grow: 1;

    &__row {
      padding: 24px 0;
      display: flex;
      gap: 48px;
      border-bottom: 1px solid $gray-200;
      position: relative;
      width: 100%;

      &__title {
        font-size: 18px;
        margin-bottom: 12px !important;
      }

      &__column {
        flex: 1 1 25%;
      }

      &__column:has(img) {
        align-self: center;
        display: flex;
        align-items: center;
        padding-left: 24px;

        & > img {
          max-height: 83px;
          left: 8px;
        }
      }

      // &__column:nth-child(2) {
      //   margin-left: 130px;

      //   & > .ProductSummary__keyValueSpan {
      //     & > p:first-child {
      //       width: 75px;
      //       text-align: right;
      //     }
      //   }
      // }

      // &__column:nth-child(3) {
      //   & > .ProductSummary__keyValueSpan {
      //     & > p:first-child {
      //       width: 140px;
      //       text-align: right;
      //     }
      //   }
      // }

      // &__column:nth-child(4) {
      //   & > .ProductSummary__keyValueSpan {
      //     & > p:first-child {
      //       width: 115px;
      //       text-align: right;
      //     }
      //   }
      // }
    }
  }

  &__keyValueSpan {
    display: flex;

    p:first-child {
      color: $gray-500;
      margin-right: 16px;
    }

    p:nth-child(2) {
      color: $gray-900;
    }

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}
