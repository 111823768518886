@import '~/src/scss/variables.scss';
@import '~/src/scss/mixins.scss';

.ConfirmationModal {
  .Modal__content {
    padding: 0;
  }

  &__header {
    width: 100%;
    background-color: $gray-100;
    padding: 20px 0;

    & p {
      font-size: 1.5rem;
      @include medium;
      margin: 0;
      text-align: center;
    }
  }

  &__body {
    padding: 1rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    & > p {
        color: $gray-700;
      font-size: 1.125rem;
    }
  }

  &__buttons {
    align-self: flex-end;
    display: flex;
    gap: 1rem;
  }
}
