@import '~/src/scss/variables';
@import '~/src/scss/mixins';

.Orders {
  &__filters {
    align-items: flex-end;
    display: flex;
    gap: 16px;
    margin-bottom: 1.5rem;

    label {
      display: block;
      margin-bottom: 4px;
    }
  }

  &__filterOrViewPopover {
    position: relative;
    width: fit-content;

    &__options {
      position: absolute;
      z-index: 100;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: $white;
      border: 1px solid $gray-300;
      border-radius: 4px;
      padding: 12px 16px;
      display: none;
      align-items: center;
      gap: 1rem;

      a {
        display: block;
        cursor: pointer;
        font-size: 20px;
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      & > .Orders__filterOrViewPopover__options {
        display: flex;
      }
    }
  }
}
