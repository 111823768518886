@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.PieceworkerNav {
  display: flex;
  padding: 0.75rem 1rem;
  width: 100%;

  &__logo {
    cursor: pointer;
    width: 40px;
  }

  &__spacer {
    flex: 1;
  }
}
