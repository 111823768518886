@import '~/src/scss/variables';
@import '~/src/scss/mixins';

.AvailabilityEdit {
  .icon-wrapper {
    margin: 32px 0;
  }

  &__availability {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }

  &__actions {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 2rem 0;

    &__spacer {
      width: 100%;
      height: 1px;
      background-color: $gray-200;
      margin: 1rem 0;
    }

    &__warnButton {
      color: $danger-800 !important;
      background-color: $danger-100 !important;
    }
  }
}
