@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.BillingCollectionDrawer {
  &__content {
    display: flex;
    padding: 0 !important;
  }

  &__left {
    flex: 1;
    padding: 1rem;

    input,
    select,
    textarea {
      width: 248px;
    }
  }

  &__field {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    label {
      @include medium;
      display: block;
    }
  }

  &__right {
    border-left: 1px solid $gray-200;
    flex: 1;
    padding-left: 1rem;
    overflow-y: auto;
  }

  &__note {
    border-bottom: 1px solid $gray-200;
    padding: 1rem;

    &__header {
      align-items: center;
      display: flex;
      justify-content: space-between;

      :last-child {
        color: $gray-500;
      }
    }

    &__body {
      p {
        margin: 0.5rem 0;
      }
    }
  }
}
