@import '~/src/scss/variables.scss';

.OrderDetailComponentWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.OrderDetailGrayBackground {
  flex-grow: 1;
  background-color: $gray-100;
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.OrderDetail {
  flex-grow: 1;
  display: flex;
  padding: 0px !important;
  gap: 1rem;
  
  &__card {
    border-radius: 8px;
    padding: 16px !important;
    background-color: white;
    display: flex;
    align-self: flex-start;

    &--info {
      flex: 0 0 352px;;
    }
    
    &--main {
      flex: 1;
    }
  }

  &__info {
    width: 100%;
  }

  &__account {
    background-color: $gray-100;
    border-radius: 8px;
    padding: 1rem;

    &__header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.75rem;
    }

    &__field {
      display: flex;
      justify-content: space-between;

      &__label {
        color: $gray-700;
      }

      &__value {
        color: $gray-900;
        text-align: right;
      }

      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }
    }
  }

  &__viewNotes {
    cursor: pointer;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin: 0.5rem 0 1.5rem;
    padding: 0.5rem 0.5rem;
    width: fit-content;

    &__label {
      color: $primary-600;
      margin-right: 4px;
    }
  }

  &__additional {
    margin-bottom: 1.5rem;
    padding: 0.5rem;
    position: relative;

    &__field {
      display: flex;
      justify-content: space-between;

      &__label {
        color: $gray-500;
      }

      &__value {
        color: $gray-900;
      }

      &:not(:last-of-type) {
        margin-bottom: 4px;
      }
    }

    &__modalButton {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: $primary-50;
      color: $primary-400;
      transition: all 0.2s;
      opacity: 0;

      a {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          text-decoration: none;
        }
      }

      svg {
        width: 16px;
      }
    }

    &:hover {
      .OrderDetail__additional__modalButton {
        opacity: 1;
      }
    }
  }

  &__invoiceDetails {
    width: 100%;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 0.5rem;

    &__header {
      display: flex;
      justify-content: space-between;
    }

    label {
      color: $gray-500;
    }

    &__dates {
      display: flex;
      gap: 2rem;
      margin: 1.5rem 0;

      &__green {
        margin: 0;
        line-height: 1.5;
        color: $success-700;
      }
    }

    &__totals {
      display: flex;
      gap: 2rem;
      margin: 1.5rem 0;
    }
  }

  &__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &__content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      padding: 0.5rem 0;
      border-top: 1px solid $gray-200;
    }
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__search {
      position: relative;
    }

    &__spacer {
      flex: 1;
    }
  }
}
