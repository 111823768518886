@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.RepDetails__tabs {
  .RepDetails__reorders {
    padding: 16px 16px 0 16px;
    flex-grow: 1;

    &__lastOrder {
      padding: 16px 16px 12px 16px;
    }

    &__table:not(:last-child) {
      margin-bottom: 16px;
    }
    &__table {
      width: 100%;

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;

        p {
          margin: 4px 0;
          line-height: 1.5;
        }

        &--storeInfo {
          color: $gray-500;

          a {
            margin-left: 8px;
          }
          span {
            @include medium;
            color: $gray-700;
            margin-right: 8px;
          }
        }
        &--orderInfo {
          color: $gray-500;
          text-align: right;

          span {
            color: $gray-900;
            margin-left: 8px;
          }
        }
      }
      table {
        thead {
          border-top: none;
        }
        tr:not(:first-child) {
          background-color: $white;
        }
        tr:last-child {
          border-bottom: none;
        }
        tr {
          td:not(:first-child),
          th:not(:first-child) {
            text-align: right;
          }
        }
      }
    }
  }
}
