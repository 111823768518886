@import '~/src/scss/mixins.scss';
@import '~/src/scss/variables.scss';

.PWProfile {
  margin: -1rem;
  width: calc(100% + 2rem);

  &__imageHeader {
    width: 100%;
    height: 92px;
    background-color: $gray-100;

    & > img {
      height: 92px;
      position: relative;
      top: 20px;
      left: 1rem;
      border-radius: 6px;
      box-shadow: 10px 10px 80px rgba(0, 0, 0, 0.07);
    }
  }

  &__body {
    width: 100%;
    padding: 0 16px;

    p {
      margin: 0;
      color: $gray-700;
    }
  }

  &__info {
    margin-top: 2rem;

    width: 100%;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    gap: 8px;

    &__name {
      color: $gray-900 !important;
      font-size: 18px;
      font-weight: 500;
    }
  }

  &__picture {
    margin-top: 16px;

    padding: 18px 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span {
      display: flex;
      align-items: center;
      gap: 16px;

      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }

      p {
        font-weight: 500;
      }
    }

    &__fileInput {
      display: flex;
      align-items: center;
      position: relative;

      & > label {
        cursor: pointer;
        padding: 8px 24px;
        border: 1px solid $gray-300;
        border-radius: 4px;
        font-weight: 500;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      & > input {
        opacity: 0;
        width: 0;
      }
    }
  }

  &__paymentMethod {
    margin-top: 32px;

    & > p {
      font-size: 16px;
      color: $gray-900 !important;
    }

    &__banner {
      margin-top: 16px;

      width: 100%;
      background-color: $gray-100;
      padding: 8px 24px;
      border-radius: 6px;

      display: flex;
      align-items: center;
      gap: 12px;

      &__icon {
        width: 32px;
        height: 32px;
        background-color: $primary-700;
        padding: 8px;
        border-radius: 4px;

        display: flex;
        justify-content: center;
        align-items: center;

        & > svg {
          color: white;
        }
      }

      & > p {
        font-weight: 500;
      }
    }
  }
}
