@import 'src/scss/variables.scss';

.ReportsNav {
  display: flex;
  align-items: center;

  & > p {
    margin: 0 0 0 16px;
    font-size: 36px;
    font-weight: 100;
    color: $gray-300;
  }

  & > a,
  & > div > a {
    display: flex;
    align-items: center;
    color: $gray-500;
    font-weight: 500;

    &:hover {
      text-decoration: none;
    }

    svg {
      width: 12px;
      height: 12px;
      margin: 0 0 0 8px;
    }
  }

  &__popover {
    display: flex;
    gap: 1rem;
    padding: 24px !important;

    &__col {
      display: flex;
      flex-direction: column;
      width: 260px;
      &__title {
        color: $gray-900;
        font-weight: 500;
        font-size: 16px;
        margin: 0;
      }

      &__subtitle {
        color: $gray-500;
        font-size: 14px;
        margin: 0;

        // remove when adding report group subtitles
        display: none;
      }
      // & > :not(:last-child, .ReportsNav__popover__col__title) {
      //   margin: 0 0 8px 0;
      // }
      // uncomment, delete below when adding report group subtitles
      & > :not(:last-child) {
        margin: 0 0 8px 0;
      }
    }
  }

  & > :not(:first-child) {
    margin-left: 28px;
  }

  &__activeLink,
  &__activeLink > svg {
    color: $primary-900 !important;
  }
}
