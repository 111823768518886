@import '~/src/scss/variables.scss';

.BillingList {
  &__filters {
    align-items: end;
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;

    &__status {
      display: flex;
      flex-direction: column;

      select {
        height: 39px;
      }
    }
  }
}
