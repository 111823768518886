@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.Radio__container {
  @include medium;
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 14px;
  line-height: 14px;
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover {
    .Radio:not(:checked):not(:disabled) ~ .Radio__mark {
      background-color: $primary-100;
      border-color: $gray-500;
    }
  }

  &:focus,
  &:active {
    .Radio:not(:checked):not(:disabled) ~ .Radio__mark {
      background-color: $primary-200;
      border-color: $gray-500;
    }
  }
}

.Radio {
  &:checked ~ .Radio__mark {
    background-color: $primary;
    border-color: $primary;
  }

  &:checked ~ .Radio__mark:after {
    background-color: white;
    border-radius: 100%;
    content: '';
    height: 6px;
    width: 6px;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: all 0.1s ease-in-out;
  }

  &:disabled ~ .Radio__mark {
    background-color: $gray-300;
    border-color: $gray-300;
    cursor: not-allowed;
  }
}

.Radio__mark {
  border: 1px solid $border;
  border-radius: 100%;
  height: 14px;
  width: 14px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s ease-in-out;
}
