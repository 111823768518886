@import 'src/scss/variables.scss';

.ProductionReports {
  display: flex;
  flex-direction: column;
  height: 100%;

  p {
    line-height: 1.5;
    color: $gray-700;
    margin: 0;
  }

  .nav {
    margin-bottom: 16px;
  }

  &__body {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      &__title {
        h3 {
          margin: 0;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.5;
        }

        p {
          color: $gray-500;
          margin-top: 4px;
        }
      }

      &__filters {
        display: flex;
        align-items: center;

        & > :not(:last-child) {
          margin-right: 16px;
        }

        .Input {
          height: 38px;
        }
      }
    }

    &__table {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .DataTable {
        th {
          border-top: none;
        }

        tr:last-child {
          td {
            border-bottom: none;
          }
        }

        td {
          padding: 16px;
          color: $gray-900;
          font-size: 14px;
        }
      }

      &__light {
        color: $gray-700 !important;
      }

      &__compact > td {
        padding: 8px 16px !important;
      }
    }
  }

  &__productionSummaryTotals {
    padding: 8px 24px 24px;
    background-color: #fff;
    position: sticky;
    bottom: 0;

    &__content {
      display: flex;
      justify-content: space-between;
      background-color: $gray-50 !important;
      border-top: 1px solid $gray-200;

      & > span {
        width: 0;
        flex-grow: 1;
        padding: 16px;
        color: $gray-900;
        font-size: 16px;
      }
    }
  }

  &__columnEqualizer {
    .DataTable {
      th,
      td {
        width: 16.66666666666667% !important;
      }
    }
  }
}
