@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.CreateOrderDrawer {
  label {
    display: block;
  }

  &__searchAccounts {
    margin-bottom: 1.5rem;

    label {
      margin-bottom: 4px;
    }
  }

  &__address {
    display: flex;
    justify-content: space-between;

    label {
      @include medium;
      color: $gray-700;
    }

    p {
      color: $gray-900;
      margin-top: 4px;
    }
  }

  &__tableLabel {
    color: $gray-700;
    margin-bottom: 4px;
  }

  &__tableContent {
    max-height: 300px;
    overflow: auto;
  }

  &__inputs {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin: 1rem 0 2rem 0;

    label {
      color: $gray-700;
    }

    .Input__container {
      margin-bottom: 1rem;
    }
  }

  &__invoice {
    color: $gray-700;
    margin: 2rem 0 0 0;

    .orderLabels {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .Input__container {
        width: 296px;
      }
    }
  }

  &__callout {
    align-items: flex-start;
    background-color: $warning-50;
    border-radius: 6px;
    display: flex;
    margin-top: 1rem;
    padding: 1rem;
    width: 100%;

    &__icon {
      color: $warning-400;
      margin-right: 1rem;
      width: 24px;
    }

    &__title {
      color: $warning-800;
      font-weight: 500;
    }

    &__message {
      color: $warning-700;
    }
  }
}
