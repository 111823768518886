@import 'src/scss/variables';
@import 'src/scss/mixins';

.DisplayTemplate {
  padding: 2rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.2;
    }
  }
  &__tabs {
    &__container {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
    }
  }
}
