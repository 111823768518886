@import 'src/scss/variables.scss';

.ProductDetails__removeTagModal {
  &__header {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-50;

    & > p {
      color: $gray-900;
      font-size: 18px;
    }
  }

  &__body {
    padding: 8px 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    .ProductDetails__warningBanner {
      width: 575px;
    }
  }

  &__buttons {
    padding: 16px 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid $gray-200;

    & > :first-child {
      margin-right: 16px;
    }
  }
}
