@import 'src/scss/variables.scss';

.AccessCodeModal {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    width: 100%;
    height: 64px;
    background-color: $gray-50;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0 0;

    & > p {
      margin: 0;
      color: $gray-900;
      font-size: 18px;
    }
  }

  &__body {
    flex-grow: 1;
    display: flex;
    max-height: calc(100% - 64px);

    .Popover {
      max-height: 300px;
      overflow: auto;
    }
  }

  &__codes {
    height: 100%;
    width: 526px;
    border-right: 2px solid $gray-200;
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      color: $gray-700;
    }

    &__container {
      padding: 24px 12px 12px 12px;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }

    &__header,
    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 8px;

      .Input__container {
        .Input {
          width: 100%;
        }
      }
      > :nth-child(1) {
        width: 120px;
        margin-right: 16px;
      }
      > :nth-child(2) {
        flex-grow: 1;
        margin-right: 16px;
      }
    }

    &__row--selected {
      background-color: $primary-50;
    }

    &__addCode {
      width: 100%;
      padding: 12px;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__products {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 28px;
    overflow: auto;

    & > :not(:last-child) {
      margin-bottom: 16px;
    }

    &__row {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      p {
        margin: 0;
        color: $gray-700;
      }

      &__productId:not(:last-child) {
        flex-grow: 1;

        > :first-child {
          margin-bottom: 4px;
        }
      }

      &__active {
        margin: 0px 32px;
        display: flex;
        flex-direction: column;
        align-items: center;

        > :first-child {
          margin-bottom: 4px;
        }
      }

      &__photos {
        display: flex;
        align-items: center;

        &__photo {
          width: 150px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;

          & > img {
            max-height: 76px;
            max-width: 150px;
          }
        }

        & > :not(:last-child) {
          margin-right: 8px;
        }

        & > .IconButton {
          position: relative !important;
        }
      }
    }
  }
}
