// General typographic rules
html,
body {
  @include sans;
  @include normal;
  color: $gray-800;
  font-size: 14px; // 16px in most browsers. If you change this value, you'll want to change the $rem-base variable in functions.scss
  line-height: 1.5;
}

h1 {
  @include normal;
  font-size: 40px;
  line-height: 1.2;
}

.h1-mobile {
  @include semi-bold;
  color: $gray-700;
  font-size: 32px;
  line-height: 1.4;
}

h2 {
  @include normal;
  font-size: 32px;
  line-height: 1.2;
}

h3 {
  @include normal;
  font-size: 28px;
  line-height: 1.2;
}

h4 {
  @include semi-bold;
  font-size: 24px;
  line-height: 1.2;
}

h5 {
  @include normal;
  font-size: 20px;
  line-height: 1.2;
}

h6 {
  @include normal;
  font-size: 16px;
  line-height: 1.2;
}

.error {
  @include bold;
  color: $error;
  font-size: 14px;
}

.hint {
  @include normal;
  margin-top: 8px;
}

// Icon fonts
$font-path: '../assets/fonts';
