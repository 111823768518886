@import 'src/scss/variables.scss';

.AddCreditMemoModal {
  &__header {
    width: 100%;
    background-color: $gray-100;
    padding: 18px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & > p {
      color: $gray-900;
      font-size: 18px;
      margin: 0;
      line-height: 1.5;
    }
  }

  &__body {
    margin: 18px;
    border: 1px solid $gray-200;
    border-radius: 8px;
    overflow: hidden;

    .data-table {
      th {
        border-top: none;
      }

      tr:last-child {
        td {
          border-bottom: none;
        }
      }

      th:last-child,
      td:last-child {
        width: 0;

        .Button--variant--raised {
          min-width: 0;
          padding: 0.25rem 0.75rem;
        }
      }
    }
  }
}
