@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.OrderNotesDrawer {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;

  &__header {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding: 16px 24px;
    border-bottom: 1px solid $gray-200;
    background-color: $gray-50;

    &__title {
      p {
        font-size: 18px;
        margin: 0 0 4px 0;
        color: $gray-900;
        display: flex;
        flex-direction: column;
      }

      label {
        font-size: 14px;
        color: $gray-500;
        font-weight: normal;
      }
    }
    button {
      span {
        color: $gray-500;
        font-size: 20px;
      }
    }
  }

  &__body {
    flex: 1;
    overflow: hidden;
    display: flex;

    &__left {
      width: 50%;
      padding: 1rem;
    }
    &__right {
      border-left: 1px solid $gray-200;
      width: 50%;
      padding: 1rem;
      overflow-y: auto;
    }
    &__noteBox {
      &:not(:last-child) {
        border-bottom: 1px solid $gray-200;
      }
      padding: 1rem;

      &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
          padding: 4px !important;
        }
      }
      label {
        color: $purple-800;
        font-weight: 500;
        font-size: 12px;
        background-color: $purple-100;
        height: 22px;
        align-self: center;
        padding: 2px 8px;
        margin-right: 16px;
        border-radius: 2px;

        &:first-child {
          margin-bottom: 8px;
        }
      }
      p {
        font-size: 14px;
        margin: 0;
        color: $gray-900;
        line-height: 1.5;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      span {
        color: $gray-500;
        font-size: 14px;
      }
      h5 {
        font-size: 14px;
        font-weight: 500;
        margin: 0 0 8px 0;
      }

      &__inputButtons {
        display: flex;
        align-items: center;
        justify-content: right;

        :not(:last-child) {
          margin-right: 16px;
        }
      }
    }

    &__input {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .orderDrawerTextArea {
        height: 80px;
        width: 336px;
      }
      p {
        color: $gray-700;
        font-size: 14px;
        margin: 0 0 8px 0;
        line-height: 1.5;
      }
      button {
        align-self: flex-end;
      }
    }
  }

  &__buttons {
    border-top: 1px solid $border;
    display: flex;
    justify-content: flex-end;
    flex: 0 0 auto;

    padding: 1rem 1.5rem;

    button {
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }
}
