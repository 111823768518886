@import 'src/scss/variables.scss';

.HistorySnapshot {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  p {
    color: $gray-700;
    line-height: 1.5;
    margin: 0;
  }

  &__container {
    background-color: $gray-100;
    flex-grow: 1;
    display: flex;
  }

  &__body {
    flex-grow: 1;
    background-color: #fff;
    padding: 16px;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    & > p {
      font-size: 18px;
      font-weight: 500;
      margin: 0 32px 14px 0;
    }

    &__tabs {
      flex-grow: 1;
      display: flex;
      border-bottom: 1px solid $gray-200;

      & > .TabItem {
        height: 47px;
        padding-bottom: 16px !important;
        width: 136px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > p {
          margin-right: 8px;
          font-weight: 400;
        }

        &--active {
          & > p {
            color: $primary-600;
          }
          & > .Badge {
            color: $primary-800;
            background-color: $primary-50;
          }
        }
      }

      &__wideTab {
        width: 200px !important;
      }
    }

    &__tabSpacer {
      flex-grow: 1;
    }

    &__tabsButton {
      margin: 0 0 12px 24px;
    }
  }

  &__data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    &__card {
      padding: 16px;
      background-color: $gray-100;
      border-radius: 6px;
      width: 284px;

      & > span {
        display: flex;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        & > p:nth-child(2) {
          color: $gray-900;
        }
      }
    }

    &__table {
      & > table {
        thead {
          border-top: none;
          th {
            width: 120px;

            &:first-child {
              background-color: #fff;
            }
          }
        }

        tr {
          &:last-child {
            border-bottom: none;
          }

          td:nth-child(n + 3) {
            text-align: right;
          }
        }
      }
    }
  }

  &__tables {
    display: flex;

    &__table {
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 64px;
      }

      & > p {
        padding: 8px 16px;
        font-weight: 500;
      }

      th:not(:first-child),
      td:not(:first-child) {
        text-align: right;
      }

      td:first-child {
        font-weight: 500;
      }
    }
  }

  &__orderTable {
    &:not(:last-child) {
      margin-bottom: 32px;
    }

    &:last-child {
      margin-bottom: 20px;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      padding: 8px 16px;

      span {
        display: flex;
        &:not(:last-child) {
          margin-right: 64px;
        }
        & > p {
          &:first-child {
            color: $gray-500;
            margin-right: 4px;
          }
          &:nth-child(2) {
            color: $gray-900;
          }
        }
      }
    }

    &__tableContainer {
      border-radius: 4px;
      border: 1px solid $gray-200;
      overflow: hidden;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1);

      & > table {
        thead {
          border-top: none;
        }
        tbody {
          tr {
            &:last-child {
              border-bottom: none;
            }
            &:nth-child(2n) {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}
