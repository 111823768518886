@import 'src/scss/variables.scss';

.EverynameDetails__tabs__table {
  border: 1px solid $gray-200;
  border-radius: 4px;
  overflow: hidden;

  thead {
    border-top: none;
  }
  tr:last-child {
    border-bottom: none;
  }

  td,
  th {
    color: $gray-700;
    &:last-child {
      color: $gray-500;
    }
    img {
      height: 48px;
      min-width: max-content;
    }
  }
}
