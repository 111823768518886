.VariableFields__layer {
    overflow: auto;
    padding: 16px 24px;

    &__break {
        padding-bottom: 24px;
    }

    &__colorContainer {
        display: flex;
        width: 177px;
        align-items: center;

        &>:not(:last-child) {
            margin-right: 8px;
        }

        &>:not(:first-child) {
            align-self: stretch;
        }

        input[type="color"] {
            padding: 0 3px;
            height: 100%;
        }
    }

    &__required {
        border: 1px solid red !important;
    }
}