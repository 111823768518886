@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.TabItem {
  @include semi-bold;
  align-items: center;
  border-bottom: 2px solid transparent;
  color: $gray-500;
  cursor: pointer;
  display: flex;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  transition: color 0.1s ease-in-out, border 0.1s ease-in-out;

  &:not(&--active) {
    &:hover {
      border-bottom: 2px solid $gray-300;
      color: $gray-700;
    }
  }

  &--active {
    border-bottom: 2px solid $primary;
    color: $primary;
  }
}
