@import 'src/scss/variables.scss';

$edge-hit-animation-duration: 800ms;

.DisplayReorderDrawer {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  &--edgeHit {
    animation: edgeHit $edge-hit-animation-duration forwards;
  }

  @keyframes edgeHit {
    0%,
    50%,
    100% {
      background-color: white;
    }
    25%,
    75% {
      background-color: $danger-500;
    }
  }

  p {
    margin: 0;
    line-height: 1.5;
    color: $gray-700;
  }

  & > svg {
    color: $gray-500;
    position: absolute;
    top: 14px;
    right: 14px;
    width: 24px;
  }

  &__header {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 40px;
    flex-shrink: 0;

    &__title {
      & > p:first-child {
        font-size: 18px;
      }

      & > p:nth-child(2) {
        color: $gray-500;

        & > span {
          color: $gray-900;
        }
      }
    }

    &__totals {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__fractionTotal {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 40px;

        & > p:first-child {
          align-self: stretch;
          text-align: center;
          border-bottom: 1px solid $gray-200;
          color: $gray-500;
        }

        & > p:last-child {
          padding: 0 6px;
        }

        &:last-child {
          & > p {
            color: $gray-900;
            padding: 0 12px;
          }
        }
      }

      &__group {
        display: flex;
        margin-right: 24px;
      }
    }
  }

  &__subheader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap-reverse;
    margin-bottom: 1.5rem;
  }

  &__navigation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem 2rem;
    padding: 8px 40px;

    &--sections,
    &--panels {
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;

      & > p {
        color: black;
        font-weight: 500;
      }

      & > ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        border: 1px solid $gray-300;
        border-radius: 6px;
        overflow: hidden;

        li {
          flex-shrink: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s;
          padding: 0.5rem 0;
          width: 40px;
          user-select: none;

          &:not(:last-child) {
            border-right: 1px solid $gray-300;
          }

          &.DisplayReorderDrawer__activeListItem {
            background-color: $gray-100;
            box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  &__applyToAll {
    display: flex;
    padding: 8px 40px;

    .Input__container {
      overflow: hidden;
      width: 50px;

      .Input {
        width: 50px;
        text-align: center;
        border-radius: 4px 0px 0px 4px;
        box-shadow: none;
      }
    }

    & > .Button--variant--outlined {
      width: max-content;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border: 1px solid $gray-300;
      border-left: none;
    }
  }

  &__body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 8px 40px;
    overflow: auto;
  }

  &__buttons {
    padding: 20px 24px;
    border-top: 1px solid $gray-200;
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;

    & > span {
      & > :not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}
