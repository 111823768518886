@import 'src/scss/variables.scss';

.AccountNav {
  width: 100%;
  display: flex;

  & .Nav {
    flex-grow: 1;

    &__spacer {
      display: none;
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__links {
      display: flex;
    }
  }

  &__slash {
    width: 24px;
    height: 36px;
    background: linear-gradient(-80deg, #fff, #fff 50%, $gray-300 50%, $gray-300 calc(50% + 2px), #fff calc(50% + 2px));
    margin: 0 8px;
  }

  &__overviewLink {
    color: $gray-500 !important;
    padding: 0 8px;
    margin-right: 8px;

    &:hover {
      text-decoration: none;
    }
  }

  &__popover {
    display: flex;
    flex-direction: column;
    padding: 24px !important;
    width: max-content;
    top: 40px;

    *,
    *:hover {
      text-decoration: none;
    }

    :last-child {
      margin-top: 8px;
    }
  }

  &__searchPopover {
    position: relative;

    & .Popover {
      left: 0;
      width: 320px;

      & > .Menu {
        overflow: auto;
        max-height: 500px;

        & > button {
          // menu items are collapsing, set height to prevent
          min-height: 37px;
        }
      }
    }
  }
}
