@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.JobDetail {
  &__header {
    align-items: center;
    display: flex;
    margin: 3rem 0 2rem 0;

    &__logo {
      margin-right: 1.5rem;
    }
    &__title {
      margin: 0;
    }
    &__assignee {
      color: $gray-600;
      font-size: 18px;
      margin: 8px 0 0 0;
    }
  }

  &__status {
    display: flex;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;

    &__column {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 1.5rem;

      &:not(:last-of-type) {
        border-right: 1px solid $gray-100;
      }
    }

    &__field {
      margin-bottom: 4px;

      label {
        color: $gray-900;
        font-size: 16px;
      }

      span {
        color: $gray-600;
        font-size: 16px;
      }
    }
  }

  &__table {
    border-collapse: collapse;
    border-bottom: 1px solid $gray-200;
    margin-bottom: 1.5rem;
    width: 100%;

    &--sm {
      width: 80px;
    }

    &--md {
      width: 125px;
    }

    &--lg {
      width: 200px;
    }

    thead {
      th {
        @include medium;
        color: $gray-500;
        font-size: 12px;
        padding: 0 12px;
        text-align: left;
      }
    }

    tbody {
      .alignCenter {
        text-align: center;
      }

      input {
        width: 100px;
      }

      .Checkbox__container {
        display: initial;
        margin-bottom: 0;
        text-align: center;
      }

      tr td {
        color: $gray-900;
        font-size: 14px;
        padding: 8px 12px 16px 12px;
      }
    }

    &__badges {
      .Badge {
        display: inline-block;

        &:not(:last-of-type) {
          margin-right: 1rem;
        }
      }
    }
  }

  &__notificationSent {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &-buttonHolder {
    position: fixed;
    bottom: 0;
    padding: 2rem 4rem;
  }

  &-buttonBottom {
    margin-right: 2rem;
  }

  &__deleteModal {
    h3 {
      margin: 0;
    }

    p {
      margin: 2rem 0;
    }
  }
}
