@import 'src/scss/variables.scss';
@import 'src/scss/mixins.scss';

// Modal for "Display list" page
.EditDisplayModal {
  width: 100%;
  color: $gray-700;

  button.Modal__close {
    position: absolute;
    color: $gray-500;
  }

  .Modal__content {
    overflow-y: auto;
    overflow-x: visible;

  }

  .Popover {
    right: 0;
  }

  &__header {
    text-align: center;
    background-color: $gray-50;

    & > h3 {
      margin: 0;
      padding: 18.5px 0;
      line-height: 1.5;
      color: $gray-900;
      font-size: 18px;
      @include normal;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;

    label {
      color: $gray-700;
      margin: 0;
      line-height: 1.5;
    }

    &__field {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &--notes {
        align-items: flex-start;
        margin-top: 16px;
      }
      &--textboxNoResize {
        resize: none;
      }

      .Input__container:has(.EditDisplayModal__content__field--textboxNoResize) {
        max-width: 50%;
      }

      input {
        width: 250px;
      }
      .DatePicker__container {
        .Input__container {
          width: 250px;
        }
        .DatePicker {
          right: 0;
        }
      }

      &__importFile {
        cursor: pointer;
        position: relative;

        & > label {
          cursor: pointer;
          padding: 7px 28px;
        }

        & > input {
          opacity: 0;
          position: absolute;
          right: 0;
          z-index: -100;
        }
      }
    }

    &__field:not(:last-child) {
      margin-bottom: 16px;
    }

    &__divider {
      width: 100%;
      height: 1px;
      background-color: $gray-200;
    }
  }

  &__buttons {
    padding: 20px 24px;
    display: flex;
    justify-content: flex-end;

    button {
      width: 130px;
    }
    button:last-child {
      width: 260px;
    }
    button:not(:last-child) {
      margin-right: 16px;
    }
  }
}
