.PieceworkerLayout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  margin: 0 auto;

  &__routes {
    flex: 1;
    overflow: auto;
    padding: 1rem;
  }
}
