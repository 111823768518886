@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.Drawer {
  animation: slideInRight 0.4s;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  width: 416px;
  z-index: 101;

  &__header {
    align-items: center;
    background-color: $gray-100;
    border-bottom: 1px solid $gray-200;
    display: flex;
    padding: 1rem 1.5rem;

    &__spacer {
      flex: 1;
    }
  }

  &__title {
    font-size: 18px;
    margin: 0;
  }

  &__content {
    flex-grow: 1;
    overflow: auto;
    padding: 1.25rem 1.5rem;
  }

  &__actions {
    border-top: 1px solid $gray-200;
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 1.5rem;
    gap: 1rem;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    &--bg {
      backdrop-filter: blur(4px);
      background-color: rgba(107, 114, 128, 0.75);
    }
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100vw);
  }

  100% {
    transform: translateX(0);
  }
}
