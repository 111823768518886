@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.RepsList {
  &__filters {
    align-items: flex-end;
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;

    label {
      display: block;
      margin-bottom: 4px;
    }
    select {
      min-width: 140px;
    }
  }

  &__profile {
    display: flex;
    align-items: center;

    &__image {
      border-radius: 50%;
      margin: 2px;

      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }

    &__info {
      margin-left: 16px;

      &__name {
        @include medium;
        margin: 0;
        line-height: 1.5;
      }
    }
  }

  &__phoneList {
    &__table {
      max-width: 100%;

      table {
        width: 100%;
        max-width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        table-layout: fixed;

        th:nth-child(2),
        td:nth-child(2),
        th:nth-child(3),
        td:nth-child(3) {
          width: 140px; // Set the desired width
        }

        th:nth-child(5),
        td:nth-child(5) {
          width: 70px; // Set the desired width
        }

        th:last-child,
        td:last-child {
          width: max-content; // Allow the last column to grow
        }
      }

      .data-table thead,
      .data-table tbody tr {
        border: none;
      }

      &__name {
        min-width: 150px;
      }

      &__cell,
      &__companyNumber {
        max-width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &__company {
        max-width: 224px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &__area {
        max-width: 330px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  @media print {
    .Drawer {
      width: 100% !important;
      height: 100%;
      position: absolute;
      box-shadow: none;
    }

    .Drawer__overlay--bg {
      background-color: transparent;
    }

    .RepsList__phoneList {
      margin: 1rem 0;

      &__table {
        overflow: visible;

        tbody {
          tr {
            td {
              padding: 0.5rem 1rem;
            }
          }
        }
      }

      .Drawer__actions,
      .Drawer__header > .IconButton {
        display: none;
      }
    }
  }
}
