@import '~/src/scss/variables.scss';

.AddPaymentModal {
  .Modal {
    .Modal__content {
      padding: 0;
    }

    .modal-header {
      background-color: $gray-50;
      color: $gray-900;
      font-size: 18px;
      margin-bottom: 1rem;
      padding: 18px 0;
      border-radius: 0.5rem 0.5rem 0 0;
    }

    .modal-body {
      padding: 0.5rem 1.5rem;

      .Input {
        width: 256px;
      }

      .DatePicker__container {
        .Input__container {
          width: unset;
        }
      }

      .moneyInput {
        position: relative;

        .moneyIcon {
          position: absolute;
          left: 200px;
          color: $gray-400;
          font-size: 20px;
        }
      }
    }

    .modal-footer {
      align-items: center;
      border-top: 1px solid $border;
      display: flex;
      gap: 1rem;
      justify-content: flex-end;
      margin-top: 1rem;
      padding: 1.25rem 1.5rem;
    }
  }

  &__amount {
    &__input {
      text-align: right;
    }
  }

  .Modal__content {
    overflow: visible;
    max-width: 505px;
  }
}
