@import 'src/scss/variables.scss';

.ProductsList__filtersDrawer {
  &__content {
    &__viewAllToggle {
      padding: 8px;
      border-bottom: 1px solid $gray-200;

      .Toggle__container {
        margin: 0 !important;
      }
    }

    .Checkbox__container {
      margin: 16px 0;
      font-weight: 400;
    }

    .Toggle__container {
      margin: 16px 0;
      justify-content: flex-end;
    }
  }
}
