@import '~/src/scss/mixins';
@import '~/src/scss/variables';

.RepDetails__tabs {
  .RepDetails__list {
    padding: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__table {
      color: $gray-900;

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;

        p {
          margin: 0;
        }
        p:nth-child(1) {
          @include medium;
        }
        p:last-child {
          color: $gray-500;
        }
      }

      table {
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 1px 4px $gray-300;
      }
      button {
        color: $gray-700;
      }
    }
  }
}
